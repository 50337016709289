import { createTheme } from '@mui/material/styles';
import * as theme from './variables';

const styledTheme = createTheme({
    palette: {
        error: {
            main: theme.red // change the error color to fileinvite red color
        }
    },
    typography: {
        fontFamily: `${theme.font_family_poppins},sans-serif`
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    overflow: 'visible'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '40px 30px',
                    borderColor: theme.light_gray_2
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_2})`,
                    '& :not(:first-of-type)': {
                        marginLeft: '15px'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: theme.red
                },
                root: {
                    fontSize: theme.font_size_13,
                    color: theme.med_gray_2,
                    top: `-7px`
                },
                shrink: {
                    top: `0px`
                },
                sizeSmall: {
                    top: `0px`
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: theme.font_size_13,
                    color: theme.dark_gray_2
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.light_gray_2
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.med_gray_2
                        }
                    },
                    '&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.light_blue_1,
                            borderWidth: '1px'
                        }
                    },
                    '&.Mui-error': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.red,
                            borderWidth: '1px'
                        }
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: theme.med_gray_1,
                    '&.MuiSelect-iconOutlined': {
                        fontSize: '20px'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.light_gray_2}`,
                    boxShadow: `0px 3px 6px ${theme.box_shadow}`
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: theme.font_size_13,
                    color: theme.dark_gray_2,
                    '&:hover': {
                        backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
                    },
                    '&.Mui-selected': {
                        backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`,
                        '&:hover': {
                            backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
                        }
                    },
                    '&.Mui-selected.Mui-focusVisible': {
                        backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    // width: '18px'
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true // No more ripple, on the whole application
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0',
                    marginRight: '0',
                    fontSize: theme.font_size_12
                }
            }
        }
    }
});

export default styledTheme;
