import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import ImgRestore from '../../assets/images/img-restore.svg';

type TReactiveAccountStyle = {
    isShow: boolean;
};

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 25px;
    margin: 0 auto;
    padding-top: 100px;
    width: 476px;
    text-align: center;
    font-size: ${theme.font_size_15};
    font-weight: ${theme.font_weight_regular};
    color: ${theme.med_gray_1};
    @media ${theme.device.tablet} {
        width: 464px;
        font-size: ${theme.font_size_14};
    }
    @media ${theme.device.mobile} {
        width: 272px;
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_26};
    font-weight: ${theme.font_weight_light};
    @media ${theme.device.tablet} {
        font-size: ${theme.font_size_24};
    }
`;

export const Desc = styled.div`
    color: ${theme.dark_gray_2};
`;

export const BtnWrapper = styled.div<TReactiveAccountStyle>`
    width: 172px;
    display: ${(props) => (props.isShow ? 'block' : 'none')};
`;

export const Link = styled.a`
    color: ${theme.secondary};
`;

export const Img = styled.div`
    width: 100%;
    height: 305px;
    background-image: url(${ImgRestore});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @media ${theme.device.tablet} {
        width: 422px;
        height: 302px;
    }
    @media ${theme.device.mobile} {
        width: 287px;
        height: 205px;
    }
`;