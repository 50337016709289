import React, { useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Dialog } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import { Container, Title, Footer, TileContainer, Background, ProgressBarBox, Subtitle } from './GetStart.styled';
import { Tile, Loading, CustomDialogTitle, ProgressBar } from '../../components';
import { useGettingStartedItemsQuery, useGetGettingStartOptionMutation, useGetGettingStartOptionQuery } from '../../hooks/onboarding';
import { ITile } from '../../type';
import { parseUrl } from '../../assets/utils/utils';

const GetStart: React.FC = () => {
    const [checked, setChecked] = useState(false);

    const { data: tiles, isLoading, isError } = useGettingStartedItemsQuery();
    const { tilesTOP, tilesBottom, progress } = tiles || { tilesTOP: [], tilesBottom: [], progress: 0 };

    const { data: option, isLoading: optionisLoading, isError: optionisError } = useGetGettingStartOptionQuery();
    const { mutate } = useGetGettingStartOptionMutation();
    const { startTour } = useIntercom();

    useEffect(() => {
        setChecked(Boolean(option?.dont_show_getting_started_at));
    }, [option]);

    const handleClick = (tile: ITile) => {
        const tourId = parseUrl(tile.action_url).product_tour_id;
        startTour(Number(tourId));
        setTimeout(() => {
            window.location.href = tile.actual_url;
        }, 1500);
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        mutate({ dont_show_getting_started: event.target.checked });
    };

    const handleClose = () => {
        window.location.href = `//${window.location.host}`;
    };

    if (isLoading || optionisLoading) {
        return <Loading />;
    }

    if (isError || optionisError) {
        return <span>Error</span>;
    }

    return (
        <>
            <Background> </Background>
            <Dialog open={true}>
                <CustomDialogTitle onClose={handleClose}>
                    <Title>Getting Started </Title>
                </CustomDialogTitle>
                <ProgressBarBox>
                    <ProgressBar value={progress} />
                </ProgressBarBox>
                <Container>
                    <TileContainer>
                        {tilesTOP.map((item, idx) => {
                            return (
                                <Tile
                                    key={idx}
                                    title={item.title}
                                    description={item.description}
                                    slug={item.slug}
                                    action_url={item.action_url}
                                    completion={item.completion}
                                    onClick={() => handleClick(item)}
                                    data-cy={item.slug}
                                />
                            );
                        })}
                    </TileContainer>
                    <Subtitle>Now try some more advanced features</Subtitle>
                    <TileContainer>
                        {tilesBottom.map((item, idx) => {
                            return (
                                <Tile
                                    key={idx}
                                    title={item.title}
                                    description={item.description}
                                    slug={item.slug}
                                    action_url={item.action_url}
                                    completion={item.completion}
                                    onClick={() => handleClick(item)}
                                    data-cy={item.slug}
                                />
                            );
                        })}
                    </TileContainer>
                    <Footer>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        size={'small'}
                                        inputProps={
                                            {
                                                'data-cy': 'dont-show-again'
                                            } as object
                                        }
                                    />
                                }
                                label="Don’t show this again"
                            />
                        </FormGroup>
                        <a data-cy="skip" href="/">
                            Skip
                        </a>
                    </Footer>
                </Container>
            </Dialog>
        </>
    );
};
export default GetStart;