import React, { useEffect, useState } from 'react';
import { Divider, SelectChangeEvent, TextField, FormControl, FormHelperText, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import countries from '../../assets/data/country';
import { menuProps, StyledSelect, TooltipContainer, Mobile } from './FormPhone.styled';
import { phone } from '../../assets/data/setup_form';
import { Tooltip } from '../../components';
import DisplayValue from './DisplayValue';
import DisplayMenuItem from './DisplayMenuItem';

type TFormPhone = {
    code?: string; // set country code for the selector
    mob?: string; // set the mobile number
    onChange?: ({ code, mob, countryNumber }: { code?: string; mob: string; countryNumber: string }) => void;
    error?: boolean;
    showTooltip?: boolean;
    errorMessage?: string;
    cypressId?: string;
    required?: boolean;
};

const FormPhone: React.FC<TFormPhone> = ({
    code = 'AU',
    mob = '',
    onChange,
    error = false,
    showTooltip = true,
    errorMessage,
    cypressId,
    required = true
}: TFormPhone) => {
    const [codeVal, setCodeVal] = useState<string>(code);
    const [phoneVal, setPhoneVal] = useState<string>(mob);

    const suggestedCountries = countries?.filter((country) => country.suggested).sort((a, b) => a.order - b.order);
    const unSuggestCountry = countries?.filter((val) => !suggestedCountries.includes(val));

    useEffect(() => {
        if (code) {
            setCodeVal((prev) => {
                return prev !== code ? code : prev;
            });
        }
    }, [code]);

    useEffect(() => {
        if (mob) {
            setPhoneVal((prev) => {
                return prev !== mob ? mob : prev;
            });
        }
    }, [mob]);

    useEffect(() => {
        if (codeVal === code && phoneVal === mob) return;
        const countryNumber = countries?.find((country) => country.code === codeVal).phone;
        typeof onChange === 'function' && onChange({ code: codeVal, mob: phoneVal, countryNumber });
    }, [code, codeVal, mob, onChange, phoneVal]);

    const handleCodeChange = (event: SelectChangeEvent<string>) => {
        setCodeVal(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneVal(event.target.value);
    };

    return (
        <Box>
            <Mobile>
                <FormControl error={error}>
                    <StyledSelect
                        onChange={handleCodeChange}
                        value={codeVal}
                        id="country-code-selector"
                        renderValue={DisplayValue}
                        size="small"
                        inputProps={{
                            'data-cy': `FP-select-${cypressId}`
                        }}
                        MenuProps={menuProps}>
                        {suggestedCountries.map((country) => DisplayMenuItem(country))}
                        <Divider />
                        {unSuggestCountry.map((country) => DisplayMenuItem(country))}
                    </StyledSelect>
                </FormControl>
                <TextField
                    error={error}
                    required={required}
                    size="small"
                    label={phone.label}
                    value={phoneVal}
                    inputProps={{
                        'data-cy': `FP-input-${cypressId}`
                    }}
                    fullWidth
                    onChange={handlePhoneChange}
                />
                {showTooltip && (
                    <TooltipContainer>
                        <Tooltip
                            message={'For use with sms <br/>notifications'}
                            component={<FontAwesomeIcon icon={solid('question-circle')} />}
                        />
                    </TooltipContainer>
                )}
            </Mobile>
            {error && (
                <FormHelperText data-cy={`FP-error-${cypressId}`} error>
                    {errorMessage}
                </FormHelperText>
            )}
        </Box>
    );
};

export default FormPhone;
