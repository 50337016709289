import React, { useCallback } from 'react';
import { Wrapper, Img, ErrorID, Title, Desc } from './Error500.styled';
import { useLocation, useSearchParams } from 'react-router-dom';

interface CustomizedState {
    errorId?: string;
}

const Error500: React.FC = () => {
    const location = useLocation();
    const state = location?.state as CustomizedState;
    const errorId = state?.errorId || '';
    const [searchParams] = useSearchParams();
    const handleBack = useCallback(() => {
        window.location.href = `//${window.location.host}${searchParams.get('back')}`;
    }, [searchParams]);

    return (
        <Wrapper>
            <Title>Internal server error</Title>
            <Desc>Oops, something went wrong. Please contact support if the problem persists.</Desc>
            <span onClick={handleBack}>Back</span>
            <ErrorID isShowErrorId={!!errorId}>
                Error ID: <span> {errorId} </span>
            </ErrorID>
            <Img />
        </Wrapper>
    );
};
export default Error500;