import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TTabProps = {
  active: boolean;
}

export const StyledTabs = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const StyledTab = styled.button<TTabProps>`
  display: inline-flex;
  justfiy-content: center;
  align-items: center;
  height: ${props => props.active ? '43px' : '39px'};
  color: ${theme.dark_gray_2};
  border: 1px solid ${props => props.active ? theme.primary : theme.light_gray_2};
  background-color: ${props => props.active ? 'transparent' : theme.light_gray_3};
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: ${theme.font_size_13};
  font-weight: ${theme.font_weight_medium};
  text-decoration: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;

  &:hover {
    height: 43px;
  }
`;
