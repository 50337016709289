import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TMenuOpenProps = {
    isOpen: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    row-gap: 2px;
    position: relative;
`;

export const ClickWrapper = styled.div`
    display: none;
    @media ${theme.device.tabletL} {
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: transparent;
    }
`;
export const IconContainer = styled.button`
    display: none;
    @media ${theme.device.tabletL} {
        width: 30px;
        height: 50px;
        background-color: ${theme.light_gray_3};
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    & > svg {
        height: 15px;
    }
`;

export const Wrapper = styled.div<TMenuOpenProps>`
    display: flex;
    flex-direction: row;
    font-size: ${theme.font_size_13};
    color: ${theme.med_gray_1};
    row-gap: 10px;
    @media ${theme.device.tabletL} {
        display: ${(props) => (props.isOpen ? 'block' : 'none')};
        border: 1px solid ${theme.med_gray_1};
        border-radius: 4px;
        padding: 8px 40px 8px 8px;
        margin-right: 2px;
        box-shadow: 0px 3px 6px ${theme.box_shadow};
        position: absolute;
        right: 30px;
        z-index: 1001; /* Sit on top */
    }
`;

export const Title = styled.div`
    display: none;
    @media ${theme.device.tabletL} {
        display: block;
    }
`;

export const MenuItem = styled.div`
    @media ${theme.device.tabletL} {
        display: flex;
        flex-direction: row;
        row-gap: 9px;
        margin: 3px 0;
        cursor: pointer;
    }
`;
