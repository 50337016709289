import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Container = styled.div`
    max-width: 465px;
    font-weight: ${theme.font_weight_regular};
    overflow-y: auto;
    @media ${theme.device.mobile} {
        width: 350px;
    }
`;
export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
    p {
        font-size: ${theme.font_size_12};
        color: ${theme.red};
    }
`;

export const Description = styled.span`
    display: block;
    margin: 12px 0;
    font-size: ${theme.font_size_14};
    color: ${theme.dark_gray_2};
`;

export const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
    p {
        font-size: ${theme.font_size_12};
        color: ${theme.red};
    }
`;

export const Name = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > div {
        flex-grow: 1;
        &:first-child {
            margin-right: 30px;
        }
    }
    @media ${theme.device.mobile} {
        flex-direction: column;
        & > div {
            width: 100%;
        }
    }
`;

export const Mobile = styled.div`
    margin: 12px 0;
`;

export const Others = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
        width: 100%;
    }
`;
