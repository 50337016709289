import { useQuery, useMutation } from 'react-query';
import { getAccounts, getCountries, getTimezones, saveAccount } from '../services/accountServices';

export const useAccountsQuery = () => useQuery('getAccounts', getAccounts);

export const useCountriesQuery = () => useQuery('getCountries', getCountries);

export const useTimezonesQuery = () => useQuery('getTimezones', getTimezones);

export const useAccountMutation = () => useMutation('saveAccount', saveAccount);
