import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Container = styled.div`
    max-width: 510px;
    margin: 0 auto;
    padding: 35px 0;

    > form {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    @media ${theme.device.tabletL} {
        width: 80%;
    }

    @media ${theme.device.mobile} {
        width: 90%;
    }
`;

export const Title = styled.h1`
    font-size: ${theme.font_size_24};
    color: ${theme.med_gray_1};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    margin-bottom: 25px;
    margin-top: 0;

    @media ${theme.device.tabletL} {
        font-size: ${theme.font_size_22};
    }
`;

export const TitleExt = styled.span`
  font-weight: ${theme.font_weight_light};

  &::before {
    content: '|';
    margin-left: 14px;
    margin-right: 14px;
  }

  @media ${theme.device.mobile} {
    display: none;
  }
`;

export const Form = styled.form`
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const FieldSet = styled.fieldset`
  display: grid;
  grid-row-gap: 25px;
  border: none;
  padding: 0;
  margin: 0;
`;

export const Legend = styled.legend`
  font-size: ${theme.font_size_13};
  font-weight: ${theme.font_weight_medium};
  margin-bottom: 28px;
  color: ${theme.dark_gray_2};
`;

export const StyledTwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-column-gap: 30px;
`

export const StyledFormFooter = styled.div`
  display: flex;
  padding-top: 30px;
  border-top: 1px solid ${theme.light_gray_3};

  button {
    width: 100px;

    @media ${theme.device.mobile} {
      width: 100%;
    }
  }
`;
