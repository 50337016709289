import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import { OutlinedInput } from '@mui/material';

type TSearchBarStyleProps = {
    open?: boolean;
    isMobileFixed?: boolean;
};

export const Wrapper = styled.div<TSearchBarStyleProps>`
    width: inherit;
    @media ${theme.device.mobile} {
        width: ${(props) => (props.isMobileFixed ? 'inherit' : props.open ? 'inherit' : '40px')};
        cursor: ${(props) => (props.isMobileFixed ? 'text' : props.open ? 'text' : 'pointer')};
    }
`;

export const StyledInput = styled(OutlinedInput)`
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    & > svg {
        font-size: 16px;
    }
    & > .fa-spinner-third {
        animation: loading 0.75s linear infinite;
    }
    @media ${theme.device.mobile} {
        &.MuiInputBase-root {
            cursor: pointer;
        }
        & > svg {
            transform: translateX(-1px);
        }
    }
`;
