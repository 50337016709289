import styled from 'styled-components';
import { ArrowPositionType, TButtonStyle } from '../../type';
import { StyledButton } from '../../assets/styledComponent';

type TArrowButtonProps = {
    direction?: 'left' | 'right';
};

export const MainButton = styled(StyledButton)<TButtonStyle>`
    position: relative;
`;

export const Container = styled.div<TArrowButtonProps>`
    display: flex;
    flex-direction: ${(props) => (props.direction === ArrowPositionType.RIGHT ? 'row' : 'row-reverse')};
    align-items: center;
    justify-content: center;
    svg {
        width: 10px;
        display: none;
    }
    label {
        line-height: 1;
        padding: 6px 12px;
    }

    &:hover {
        transition: ease-in-out all 0.3s;

        label {
            padding: 6px 4px;
        }
        svg {
            padding-left: ${(props) => (props.direction === ArrowPositionType.RIGHT ? '6px' : 0)};
            padding-right: ${(props) => (props.direction === ArrowPositionType.LEFT ? '6px' : 0)};
            animation: zoom-in 0.75s linear infinite;
            display: initial;
        }
    }
`;
