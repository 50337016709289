import React from 'react';
import { TextField } from '@mui/material';
import { StyledAutocomplete } from './AutoCompleteSearch.styled';
import { TAutocompleteProps } from '../../type';

const AutocompleteSearch: React.FC<TAutocompleteProps> = ({
    id,
    options,
    onChange,
    placeholder,
    label = '',
    inputValue,
    value,
    onInputChange,
    getOptionLabel,
    inputProps,
    ...rest
}: TAutocompleteProps) => {
    return (
        <StyledAutocomplete
            {...rest}
            id={id}
            value={value}
            options={options}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            inputValue={inputValue}
            onInputChange={onInputChange}
            renderInput={(params) => (
                <TextField size="small" inputProps={inputProps ?? {}} placeholder={placeholder} label={label} {...params} />
            )}></StyledAutocomplete>
    );
};

export default AutocompleteSearch;
