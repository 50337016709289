/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, Select, MenuItem, InputLabel, FormControl, TextField, FormHelperText } from '@mui/material';
import { Container, Description, SelectWrapper, InputWrapper, Name, Mobile, Others } from './SetupQuestions.styled';
import { CustomDialogTitle, Button, FormPhone, Toast } from '../../components';
import { setupSchema } from '../../assets/utils/setup_schema';
import {
    firstName,
    lastName,
    phone,
    companyName,
    industry,
    otherIndustry,
    role,
    otherRole,
    companySize,
    howToKnowUs
} from '../../assets/data/setup_form';
import { useSetupQuestionMutation } from '../../hooks/onboarding';
import { ISetupForm, TToast } from '../../type';
import { getCookie } from '../../assets/utils/getCookies';

const SetupQuestions: React.FC = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
        trigger
    } = useForm<ISetupForm>({
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: {
                country_iso2: localStorage.getItem('origin') || 'AU',
                mobile_number: '',
                national_number: ''
            },
            industry: '',
            other_industry: '',
            role: '',
            other_role: '',
            company_name: '',
            company_size: '',
            how_did_you_hear_about_us: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            country: localStorage.getItem('origin') || 'AU',
            hubspotutk: getCookie('hubspotutk')
        },
        resolver: yupResolver(setupSchema)
    });

    const showOtherRole = watch('role');
    const showOtherIndustry = watch('industry');
    const showPhone = watch('phone');

    const [isLoading, setIsLoading] = useState(false);
    const { mutate } = useSetupQuestionMutation();

    const [toastState, setToast] = useState<TToast>({
        title: '',
        description: '',
        open: false,
        variant: 'error'
    });

    const onSubmit = (form) => {
        setIsLoading(true);
        mutate(form, {
            onError: () => {
                setIsLoading(false);
                setToast({ title: 'Error', variant: 'error', description: 'Pre-quil went wrong', open: true });
            },
            onSuccess: (response: any) => {
                const { data } = response.data;
                // for showing send your first invite product tour
                window.location.href = `//${window.location.host}${data.redirect_url}`;
            }
        });
    };

    const GenerateForm = (item) => {
        switch (item.type) {
            case 'input':
                return (
                    <InputWrapper>
                        <Controller
                            name={item.id}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    data-cy={`${item.id}-wrapper`}
                                    inputProps={{
                                        'data-cy': `${item.id}-input`
                                    }}
                                    required={item.required}
                                    id={item.id}
                                    label={item.label}
                                    size="small"
                                    fullWidth
                                    error={Boolean(errors[field.name])}
                                    helperText={errors[field.name]?.message}
                                />
                            )}
                        />
                    </InputWrapper>
                );
            case 'select':
                return (
                    <SelectWrapper>
                        <Controller
                            name={item.id}
                            control={control}
                            render={({ field }) => (
                                <FormControl required={item.required} sx={{ m: 0, minWidth: 120 }} error={Boolean(errors[field.name])}>
                                    {item.label && <InputLabel id={`${item.id}-label`}>{item.label}</InputLabel>}
                                    <Select
                                        {...field}
                                        inputProps={{
                                            'data-cy': `${item.id}-select`
                                        }}
                                        labelId={`${item.id}-label`}
                                        size="small"
                                        label={item.label ? `${item.label} *` : ''}
                                        displayEmpty
                                        fullWidth>
                                        {item.options?.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errors[field.name]?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </SelectWrapper>
                );
            case 'component':
                return (
                    <FormPhone
                        error={Boolean(errors.phone?.mobile_number)}
                        onChange={({ code, mob, countryNumber }) => {
                            setValue('phone', {
                                country_iso2: code,
                                mobile_number: mob,
                                national_number: countryNumber
                            });
                            trigger('phone');
                        }}
                        code={showPhone.country_iso2}
                        mob={showPhone.mobile_number}
                        errorMessage={errors.phone?.mobile_number?.message}
                        showTooltip
                        cypressId="prequil-form"
                    />
                );
            default:
            // code block
        }
    };

    return (
        <>
            <Dialog open={true} aria-describedby="dialog-slide-description">
                <Container>
                    <CustomDialogTitle>Let’s get you set up</CustomDialogTitle>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent dividers>
                            <Description>Tell us a bit about yourself so we can provide a tailor made service for you.</Description>

                            <Name>
                                {GenerateForm(firstName)}
                                {GenerateForm(lastName)}
                            </Name>

                            <Mobile>{GenerateForm(phone)}</Mobile>

                            <Others>
                                {GenerateForm(industry)}
                                {showOtherIndustry === 'Other' && GenerateForm(otherIndustry)}
                                {GenerateForm(role)}
                                {showOtherRole === 'Other' && GenerateForm(otherRole)}
                                {GenerateForm(companyName)}
                                {GenerateForm(companySize)}
                                {GenerateForm(howToKnowUs)}
                            </Others>
                        </DialogContent>
                        <DialogActions>
                            <Button data-cy="get-started" mode={'Primary'} loading={isLoading}>
                                Get started
                            </Button>
                        </DialogActions>
                    </form>
                </Container>
            </Dialog>
            <Toast {...toastState} onClose={() => setToast((prev) => ({ ...prev, open: false }))} />
        </>
    );
};
export default SetupQuestions;
