import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 20px;
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    color: ${theme.med_gray_1};
`;

export const Subtitle = styled.div`
    color: ${theme.dark_gray_2};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 25px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    > a {
        text-decoration: none;
        color: ${theme.secondary};
        @media ${theme.device.mobile} {
            display: block;
            text-align: center;
            margin: 0 auto;
        }
    }
`;
