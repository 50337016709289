import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import BlurDeskTopBg from '../../assets/images/bg-blur-desktop.png';

export const Background = styled.div`
    width: 100%;
    min-height: calc(100vh - 120px);
    background-image: url(${BlurDeskTopBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @media ${theme.device.mobile} {
        background-image: unset;
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    color: ${theme.med_gray_1};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
`;

export const ProgressBarBox = styled.div`
    padding: 0 30px;
`;

export const Container = styled.div`
    box-sizing: border-box;
    width: 530px;
    max-width: 530px;
    padding: 40px 30px;
    font-size: ${theme.font_size_13};
    font-weight: ${theme.font_weight_regular};
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    overflow: auto;
    @media ${theme.device.tablet} {
        width: 416px;
    }
    @media ${theme.device.mobile} {
        width: 335px;
    }
`;

export const Subtitle = styled.div`
    font-size: ${theme.font_size_16};
    font-weight: ${theme.font_weight_medium};
    color: ${theme.dark_gray_2};
`;

export const TileContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    a {
        text-decoration: none;
        color: ${theme.secondary};
    }
    .MuiFormControlLabel-label {
        font-size: ${theme.font_size_13};
        color: ${theme.dark_gray_2};
    }
    .MuiSvgIcon-root {
        width: 18px;
        color: ${theme.secondary};
    }
`;