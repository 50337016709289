import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const CommonCellStyle = `
  padding: 10px 20px;
`;

export const CommonBorderStyle = (dir = 'bottom') => `
  border-${dir}: 1px solid ${theme.light_gray_2};
`;

export const StyledTable = styled.table`
    border-collapse: collapse;
    color: ${theme.dark_gray_2};
    font-size: ${theme.font_size_13};
    font-weight: ${theme.font_weight_medium};
    line-height: 1.538em;
`;

export const TR = styled.tr`
    ${CommonBorderStyle('bottom')}
`;

export const TD = styled.td`
    ${CommonCellStyle}
`;
