import * as Yup from 'yup';
import {
  companyName,
  website,
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressZipCode,
  addressCountry,
  timezone
} from '../data/account';

const accountSchema = Yup.object().shape({
  name: Yup.string().required(companyName.requiredError),
  website: Yup.string().matches(website.pattern, website.patternError)
    .max(100, website.lengthError),
  timezone_id: Yup.string().required(timezone.requiredError),
  address: Yup.object().shape({
    city: Yup.string().max(50, addressCity.lengthError),
    firstline: Yup.string().max(50, addressLine1.lengthError),
    secondline: Yup.string().max(50, addressLine2.lengthError),
    state: Yup.string().max(50, addressState.lengthError),
    zipcode: Yup.string()
      .matches(addressZipCode.pattern, addressZipCode.patternError)
      .max(10, addressZipCode.lengthError),
    country_id: Yup.string().required(addressCountry.requiredError)
  })
})

export {
  accountSchema
};
