import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Wrapper = styled.div`
    width: 250px;
    height: 250px;
    border: 1px solid ${theme.light_gray_3};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${theme.white};
    border-radius: 5px;
    :hover {
        border: 1px solid ${theme.primary};
        background-color: rgba(${theme.primary_rgb}, ${theme.opacity_1});
    }
`;

export const StyledImg = styled.div`
    width: 52px;
    height: 70px;
    margin: 0 auto 20px auto;
    display: block;
`;

export const TextWrapper = styled.div`
    text-align: center;
    flex-direction: column;
`;

export const Text = styled.h3`
    margin-bottom: 1px;
    margin-top: 0;
    font-weight: ${theme.font_weight_regular};
    color: ${theme.med_gray_1};
    font-size: ${theme.font_size_15};
`;

export const BoldText = styled.strong`
    font-weight: ${theme.font_weight_semiBold};
    font-size: ${theme.font_size_15};
    color: ${theme.med_gray_1};
    text-align: center;
`;
