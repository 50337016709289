import React from 'react';
import { Container, LeftWrapper, ImgWrapper, LogoImg, Content, OnboardingImg, Main } from './LoginLayout.styled';
import Logo from '../../assets/images/logo.svg';
import OnboardingImage from '../../assets/images/onboarding-image.svg';

const LoginLayout: React.FC = ({ children }) => {
    return (
        <Container>
            <LeftWrapper>
                <ImgWrapper>
                    <LogoImg src={Logo} alt="logo" />
                    <OnboardingImg src={OnboardingImage} alt="onboarding-image" />
                </ImgWrapper>
            </LeftWrapper>
            <Content>
                <Main>{children}</Main>
            </Content>
        </Container>
    );
};

export default LoginLayout;
