import React from 'react';
import { TAlertBoxStyle } from '../../type';
import { Wrapper } from './AlertBox.styled';

type TAlertBoxProps = TAlertBoxStyle & {
    messages?: string[];
    fullWidth?: boolean;
};

const AlertBox: React.FC<TAlertBoxProps> = ({ mode = 'Error', fullWidth = false, messages, ...rest }: TAlertBoxProps) => {
    return (
        <Wrapper fullWidth={fullWidth} mode={mode} {...rest}>
            {messages && messages.map((message, index) => <div key={index} dangerouslySetInnerHTML={{ __html: message }} />)}
        </Wrapper>
    );
};

export default AlertBox;
