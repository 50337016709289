import React from 'react';
import { Title, StyledLinearProgress, Wrapper } from './ProgressBar.styled';

type TProgressBarProps = {
    value: number;
    isDefault?: boolean;
    size?: 'medium' | 'small';
};

const ProgressBar: React.FC<TProgressBarProps> = ({
  value,
  isDefault = true,
  size = 'medium'
}: TProgressBarProps) => {
    return (
        <Wrapper>
            <Title isDefault={isDefault} value={value}>
              {`${value}% Complete`}
            </Title>
            <StyledLinearProgress variant="determinate" value={value} size={size} />
        </Wrapper>
    );
};

export default ProgressBar;
