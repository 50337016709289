import styled from 'styled-components';
import { CommonCellStyle, CommonBorderStyle } from './Table.styled';
import * as theme from '../../assets/styles/variables';

export const TableHead = styled.thead`
    border-top: 1px solid black;
`;

export const TH = styled.th`
    ${CommonCellStyle}
    ${CommonBorderStyle('top')}
    ${CommonBorderStyle('bottom')}

    font-weight: ${theme.font_weight_medium};
`;
