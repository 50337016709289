import styled from 'styled-components';

type TMenuProps = {
    collapsed: boolean;
};

export const Container = styled.div`
    width: 100%;
    display: flex;
    min-height: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f6f9;
`;

export const Header = styled.div`
    min-height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;
export const Footer = styled.div`
    height: 60px;
    border-top: 1px solid #d2d6de;
    background-color: #fff;
    margin-top: auto;
    padding: 10px 15px;
    box-sizing: border-box;
`;
export const BurgerToggle = styled.button`
    border: none;
    background: transparent;
`;
export const SideBar = styled.div<TMenuProps>`
    flex: ${(props) => (props.collapsed ? '0 0 60px' : '0 0 230px')};
    min-height: 100vh;
    flex-direction: column;
    z-index: 101;
    background-color: #242a33;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
`;
export const SideBarHeader = styled.div`
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid rgba(179, 184, 212, 0.2);
`;
export const SideBarContent = styled.div`
    width: 230px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
    background-color: #242a33;
`;
export const Content = styled.div`
    /* padding: 10px 15px; */
`;
