import _ from 'lodash';
import { TParseUrl, TErrorMessage } from "../../type";

const parseUrl = (url: string) => {
    const pattern = /(\w+)=(\w+)/ig;
    const parames = {};
    url.replace(pattern, (a: string, b: string, c: string) => { parames[b] = c; return a });
    return parames as TParseUrl;
}

const combineErrorMessage = (errors: TErrorMessage) => {
    let messageArray: string[] = [];
    Object.values(errors).forEach(item => {
        messageArray = [...messageArray, ...[`${item.details.toString()}`]];
    });
    return messageArray.join('\n');
}

const pollyfillNullWithEmptyStrings = (
  obj: { [key: string]: any }, // eslint-disable-line
  paths: string[]
) => {
  if (!obj || !paths) {
    throw new Error("The following arguments are missing: res or paths");
  }

  const newObject = _.cloneDeep(obj);

  paths.forEach((path) => {
    if (!_.isNull(_.get(newObject, path))) return; // do nothing

    return _.set(newObject, path, "");
  });

  return newObject;
};

export { combineErrorMessage, parseUrl, pollyfillNullWithEmptyStrings };
