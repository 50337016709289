import React, { useState } from 'react';
import { TablePagination } from '../../components';
import { TInviteMeta, IInviteParams } from '../../type';
import { useInvitesQuery } from '../../hooks/invite';

const Invite: React.FC = () => {
    const [page, setPage] = useState<IInviteParams>({
        pageNumber: 1,
        pageSize: 10,
        search: '',
        user: '',
        sort: 'created_at'
    });

    const { isLoading, isError, data } = useInvitesQuery(page);

    const inviteMeta: TInviteMeta = data?.meta;
    // const inviteData: TInviteData[] = data?.data;

    const handleChange = ({ newPageSize, currPage }) => {
        setPage({ ...page, ...{ pageSize: newPageSize, pageNumber: currPage } });
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error</span>;
    }
    return (
        <>
            {JSON.stringify(data)}
            <TablePagination
                total={inviteMeta.total}
                currentPage={inviteMeta.current_page}
                onPageChange={({ newPageSize, currPage }) => handleChange({ newPageSize, currPage })}
            />
        </>
    );
};
export default Invite;
