import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _, { isEmpty, isNil, omit, result } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import {
    StyleAvatar,
    Aside,
    ChangeMfa,
    ChangePassword,
    Container,
    Content,
    Country,
    Footer,
    MainForm,
    Name,
    Title,
    IconBox,
    ActionGroup,
    AvatarBox
} from './Profile.styled';
import { ArrowButton, Badge, Button, CustomBreadcrumbs, FormPhone, Loading, Toast, Tooltip } from '../../components';
import { profileBreadcrumbs } from '../../assets/data/breadcrumbs';
import {
    addressLine1,
    addressLine2,
    city,
    country,
    email,
    firstName,
    lastName,
    phone,
    state,
    zipcode
} from '../../assets/data/profile_journey';
import { profileSchema } from '../../assets/utils/profile_schema';
import { regular, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAvatarDeleteMatation, useAvatarMatation, useCountriesQuery, useProfileMatation } from '../../hooks/profile';
import { useAuthQuery } from '../../hooks/auth';
import { ICountry, IProfile, TAuthConfigModalProps, TConfirmPasswordModalProps, TFormField, TProfileFieldValues, TToast } from '../../type';
import { ConfirmPasswordModal, ChangePasswordModal, AuthConfigurationModal } from './components';
import { formatProfileData } from '../../assets/utils/formDataFormat';
import { combineErrorMessage } from '../../assets/utils/utils';

const initialFormValues: IProfile = {
    email: { address: '' },
    phone: {
        country_iso2: 'AU',
        number: '',
        national_number: ''
    },
    address: {
        city: '',
        country_id: 1,
        firstline: '',
        secondline: '',
        state: '',
        zipcode: ''
    },
    password: '',
    sms_verification_code: '',
    firstname: '',
    lastname: ''
};

const Profile: React.FC = () => {
    const { data: countries, isLoading, isError } = useCountriesQuery();
    const { data: authData, isLoading: isAuthDataLoading, isError: isAuthDataError, refetch } = useAuthQuery();
    const formatUser = useMemo(() => formatProfileData(authData), [authData]);
    const authUser = formatUser || initialFormValues;
    const [modalConfirmPwdState, setModalConfirmPwdState] = useState<TConfirmPasswordModalProps>({
        open: false,
        phoneNumber: '',
        title: '',
        isShowSms: false
    });
    const [open, setOpen] = useState<boolean>(false);
    const [authModal, setAuthModal] = useState<TAuthConfigModalProps>({
        title: '',
        bthText: '',
        isMfa: authData?.is_mfa_enabled,
        open: false
    });

    const [toastState, setToast] = useState<TToast>({
        title: '',
        description: '',
        open: false,
        variant: 'success'
    });

    const methods = useForm<IProfile>({
        mode: 'onChange',
        resolver: yupResolver(profileSchema),
        defaultValues: initialFormValues,
        context: { isShowSms: modalConfirmPwdState.isShowSms }
    });
    const {
        control,
        setValue,
        reset,
        watch,
        trigger,
        getValues,
        formState: { errors, dirtyFields },
        clearErrors
    } = methods;
    const [selectedFile, setSelectedFile] = useState<string>('');
    const [isMfa, setIsMfa] = useState(false);

    useEffect(() => {
        if (!isNil(authUser) && !isEmpty(authUser)) {
            reset({
                firstname: authUser.firstname,
                lastname: authUser.lastname,
                email: authUser.email,
                password: authUser.password,
                sms_verification_code: authUser.sms_verification_code,
                address: authUser.address,
                phone: authUser.phone
            });
            setSelectedFile(authUser.avatar);
            setIsMfa(authUser.is_mfa_enabled);
        }
    }, [authUser, reset]);

    const fileRef = useRef<HTMLInputElement>(null);

    const showPhone = watch('phone');

    const { mutate, isLoading: isSubmitting } = useProfileMatation();
    const { mutate: deleteAvatar } = useAvatarDeleteMatation();
    const { mutate: uploadAvatar } = useAvatarMatation();

    const handleClose = useCallback(() => {
        setModalConfirmPwdState((prev) => ({ ...prev, open: false }));
        clearErrors();
        setValue('password', '');
        setValue('sms_verification_code', '');
    }, [clearErrors, setValue]);

    const isPhoneModified = authUser.phone.country_iso2 !== showPhone.country_iso2 || authUser.phone.number !== showPhone.number;
    const isPhoneEmpty = isEmpty(showPhone.number);

    const handleUpdateProfile = useCallback(
        async (form): Promise<boolean> => {
            return new Promise((resolve) => {
                const temPhone = _.cloneDeep(form.phone);
                form.phone = {
                    ...form.phone,
                    national_number: temPhone.number,
                    number: `+${temPhone.national_number}${temPhone.number}`
                };
                mutate(form, {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onError: (error: any) => {
                        const { status, data } = error.response;
                        if (status === 422) {
                            const message = combineErrorMessage(data);
                            setToast({ title: 'Error', variant: 'error', description: message, open: true });
                        } else {
                            setToast({ title: 'Error', variant: 'error', description: 'Update profile settings went wrong', open: true });
                        }
                        resolve(false);
                    },
                    onSuccess: () => {
                        setToast({
                            title: 'Success',
                            variant: 'success',
                            description: 'Your profile settings have been updated.',
                            open: true
                        });
                        if (authUser.email.address != getValues('email.address')) {
                            window.location.href = window.location.origin + '/email/verify';
                        }
                        setTimeout(() => {
                            refetch();
                        }, 1000);
                        resolve(true);
                    }
                });
            });
        },
        [mutate, refetch]
    );

    const handleConfirm = useCallback(async () => {
        const values = getValues();
        const isValid = await trigger(
            isPhoneModified ? Object.keys(initialFormValues) : Object.keys(omit(initialFormValues, ['sms_verification_code']))
        );
        if (isValid) {
            return await handleUpdateProfile(values);
        }
    }, [getValues, handleUpdateProfile, isPhoneModified, trigger]);

    const onSubmit = async () => {
        const values = getValues();
        // validation all exclude sms and password
        const isValid = await trigger(Object.keys(omit(initialFormValues, ['sms_verification_code', 'password'])));
        if (isValid) {
            // if mobile number changes, it will show verify popup
            if (isPhoneModified && !isPhoneEmpty) {
                //validation sms and password
                setModalConfirmPwdState({
                    open: true,
                    title: 'Verify Mobile Number',
                    isShowSms: true,
                    phoneNumber: `${showPhone.national_number}${showPhone.number}`
                });
                return;
            }
            // if email changes, it will show confirm password popup
            if (dirtyFields?.email?.address) {
                //validation password
                setModalConfirmPwdState({
                    open: true,
                    title: 'Confirm Password',
                    isShowSms: false,
                    phoneNumber: `${showPhone.national_number}${showPhone.number}`
                });
                return;
            }
            handleUpdateProfile(values);
        }
    };

    const handleDeleteImage = () => {
        setSelectedFile(null);
        fileRef.current.value = null;
        deleteAvatar(null, {
            onSuccess: () => {
                setToast({ title: 'Success', variant: 'success', description: 'Your avatar has been removed.', open: true });
            },
            onError: () => {
                setToast({ title: 'Error', variant: 'error', description: 'Delete avatar went wrong.', open: true });
            }
        });
    };

    const handleUploadClick = () => {
        fileRef?.current?.click();
    };

    const handleUploadAvatar = (file: Blob) => {
        const formData = new FormData();
        if (file) {
            formData.append('avatar', file);
        } else {
            formData.append('avatar', '');
        }
        uploadAvatar(formData, {
            onSuccess: () => {
                setToast({ title: 'Success', variant: 'success', description: 'Your avatar has been updated.', open: true });
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 422) {
                    const message = combineErrorMessage(data);
                    setToast({ title: 'Error', variant: 'error', description: message, open: true });
                } else {
                    setToast({ title: 'Error', variant: 'error', description: 'Upload avatar went wrong.', open: true });
                }
            }
        });
    };

    const fileReader = (file: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setSelectedFile(reader.result as string);
        };
        reader.onloadend = () => {
            setTimeout(() => {
                handleUploadAvatar(file);
            }, 1000);
        };
        reader.onerror = () => {
            setToast({ title: 'Error', variant: 'error', description: 'Failed to read file!', open: true });
        };
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        !!file && fileReader(file);
    };

    const handleAuthModal = (isMfa: boolean) => {
        if (!isMfa) {
            setAuthModal({
                open: true,
                title: 'Configure Authenticator',
                isMfa: false,
                bthText: 'Submit'
            });
        } else {
            setAuthModal({
                open: true,
                title: 'Disabled Authenticator',
                isMfa: true,
                bthText: 'Confirm'
            });
        }
    };

    const GenerateForm = (item: TFormField) => {
        switch (item.type) {
            case 'input':
                return (
                    <Controller
                        name={item.name as keyof TProfileFieldValues}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                data-cy={`${item.id}-wrapper`}
                                inputProps={{
                                    'data-cy': `${item.id}-input`
                                }}
                                required={item.required}
                                id={item.id}
                                label={item.label}
                                size="small"
                                fullWidth
                                error={!!result(errors, field.name)}
                                helperText={result(errors, `${field.name}.message`)}
                                onChange={field.onChange}
                            />
                        )}
                    />
                );
            case 'component':
                return (
                    <FormPhone
                        error={Boolean(errors.phone?.number)}
                        onChange={({ code, mob, countryNumber }) => {
                            setValue('phone', {
                                country_iso2: code,
                                number: mob,
                                national_number: countryNumber
                            });
                            trigger('phone');
                        }}
                        code={showPhone.country_iso2}
                        mob={showPhone.number}
                        errorMessage={errors.phone?.number?.message}
                        showTooltip={false}
                        required={item.required}
                        cypressId="prequil-form"
                    />
                );
            case 'select':
                return (
                    <Controller
                        name={item.name as keyof TProfileFieldValues}
                        control={control}
                        render={({ field }) => (
                            <FormControl required={item.required} error={Boolean(errors[field.name])}>
                                {item.label && <InputLabel id={`${item.id}-label`}>{item.label}</InputLabel>}
                                <Select
                                    {...field}
                                    inputProps={{
                                        'data-cy': `${item.id}-select`
                                    }}
                                    labelId={`${item.id}-label`}
                                    size="small"
                                    label={item.label ? `${item.label} *` : ''}
                                    displayEmpty
                                    fullWidth>
                                    {countries?.map((opt: ICountry) => (
                                        <MenuItem key={opt.id} value={opt.id}>
                                            {opt.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{result(errors, `${field.name}.message`)}</FormHelperText>
                            </FormControl>
                        )}
                    />
                );
        }
    };

    if (isLoading || isAuthDataLoading) {
        return <Loading />;
    }
    if (isError || isAuthDataError) {
        return <span>Error</span>;
    }

    return (
        <FormProvider {...methods}>
            <div>
                <Container>
                    <CustomBreadcrumbs breadcrumbs={profileBreadcrumbs}></CustomBreadcrumbs>

                    <Title data-cy="title">
                        <span>Profile</span>
                        <span>
                            <label>|</label>Update your profile settings
                        </span>
                    </Title>

                    <form noValidate>
                        <Content>
                            <MainForm>
                                <section>
                                    <span>Personal Details</span>
                                    <Name>
                                        {GenerateForm(firstName)}
                                        {GenerateForm(lastName)}
                                    </Name>
                                    {GenerateForm(email)}
                                    {GenerateForm(phone)}
                                </section>
                                <section>
                                    <span>Address </span>
                                    {GenerateForm(addressLine1)}
                                    {GenerateForm(addressLine2)}
                                    {GenerateForm(city)}
                                    {GenerateForm(state)}
                                    <Country>
                                        {GenerateForm(country)}
                                        {GenerateForm(zipcode)}
                                    </Country>
                                </section>
                            </MainForm>

                            <Aside>
                                <section>
                                    <span>Profile Image</span>
                                    <AvatarBox dashBorder={!!selectedFile}>
                                        <input
                                            ref={fileRef}
                                            id="uploadImage"
                                            data-cy="uploadImage"
                                            type="file"
                                            accept=".png,.jpg,.jpeg,.bmp,.gif"
                                            onChange={handleUpload}
                                        />
                                        <label htmlFor="uploadImage">
                                            <StyleAvatar src={selectedFile}>
                                                <FontAwesomeIcon icon={light('user')} />
                                            </StyleAvatar>
                                        </label>
                                        <ActionGroup>
                                            <Tooltip
                                                message={'Edit'}
                                                placement="top"
                                                component={
                                                    <IconBox onClick={handleUploadClick}>
                                                        <FontAwesomeIcon icon={regular('pencil')} />
                                                    </IconBox>
                                                }
                                            />
                                            {selectedFile && (
                                                <Tooltip
                                                    message={'Delete'}
                                                    component={
                                                        <IconBox onClick={handleDeleteImage}>
                                                            <FontAwesomeIcon icon={regular('times')} />
                                                        </IconBox>
                                                    }
                                                />
                                            )}
                                        </ActionGroup>
                                    </AvatarBox>
                                </section>
                                <section>
                                    <span>Security Settings</span>
                                    <ChangePassword>
                                        <Button
                                            type="button"
                                            data-cy="change-password"
                                            mode={'Toutlined'}
                                            startIcon={<FontAwesomeIcon icon={regular('lock-alt')} />}
                                            onClick={() => setOpen(true)}>
                                            Change Password
                                        </Button>
                                    </ChangePassword>

                                    <p>
                                        <span> Two-Factor Authentication:</span>
                                        <Badge mode={isMfa ? 'Green' : 'Warning'} type="contained">
                                            {isMfa ? 'ENABLED' : 'DISABLED'}
                                        </Badge>
                                    </p>

                                    <p>Configure additional security for your account using your mobile device</p>

                                    <ChangeMfa>
                                        <Button
                                            type="button"
                                            data-cy={isMfa ? 'enable' : 'disable'}
                                            mode={'Toutlined'}
                                            onClick={() => handleAuthModal(isMfa)}>
                                            {isMfa ? 'Disable' : 'Enable'}
                                        </Button>
                                    </ChangeMfa>
                                </section>
                            </Aside>
                        </Content>

                        <Footer>
                            <ArrowButton
                                onClick={() => {
                                    onSubmit();
                                }}
                                type="button"
                                data-cy="update-profile"
                                mode={'Primary'}
                                text={'Update Profile'}
                                loading={isSubmitting}
                                direction={'right'}></ArrowButton>
                        </Footer>
                    </form>
                </Container>
                <ConfirmPasswordModal {...modalConfirmPwdState} onClose={handleClose} onConfirm={handleConfirm} />
                <ChangePasswordModal open={open} onClose={() => setOpen(false)}></ChangePasswordModal>
                <AuthConfigurationModal
                    {...authModal}
                    onClose={() => setAuthModal((prev) => ({ ...prev, open: false }))}></AuthConfigurationModal>
                <Toast {...toastState} onClose={() => setToast((prev) => ({ ...prev, open: false }))} />
            </div>
        </FormProvider>
    );
};
export default Profile;