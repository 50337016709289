import * as Yup from 'yup';
import {
    firstName,
    lastName,
    phone,
    industry,
    role,
    companyName,
    howToKnowUs,
    otherIndustry,
    otherRole
} from '../../assets/data/setup_form';

// yup validation
const setupSchema = Yup.object().shape({
    first_name: Yup.string().required(firstName.requiredError),
    last_name: Yup.string().required(lastName.requiredError),
    phone: Yup.object().shape({
        mobile_number: Yup.string()
            .required(phone.requiredError)
            .test('mobile-validation', phone.patternError, function (this, value) {
                const val = `+${this.parent.national_number}${value}`;
                return val.match(phone.pattern) && val.length >= 10;
            })
    }),
    industry: Yup.string().required(industry.requiredError),
    other_industry: Yup.string().when('industry', {
        is: 'Other',
        then: Yup.string().required(otherIndustry.requiredError)
    }),
    role: Yup.string().required(role.requiredError),
    other_role: Yup.string().when('role', {
        is: 'Other',
        then: Yup.string().required(otherRole.requiredError)
    }),
    company_name: Yup.string().required(companyName.requiredError),
    how_did_you_hear_about_us: Yup.string().required(howToKnowUs.requiredError)
});

export { setupSchema };
