import axios from 'axios';
import history from './history';
import { isEmpty } from 'lodash';

export enum EBaseUrl {
    dev = 'http://localhost:8000/internal-api',
    pro = '/internal-api'
}

const apiClient = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? EBaseUrl.pro : EBaseUrl.dev,
    headers: {
        'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0]?.getAttribute('content')
        // 'X-CSRF-TOKEN': 'd1z3pz5K1z5pbBLaFogBvv7eiQDt7c0vhoWXnZ38'
    },
    withCredentials: process.env.NODE_ENV === 'production' // carry cookies expect local dev env
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status, data } = error.response;
        if (status === 500) {
            const { error_id } = data?.error || '';
            // error_id could be {} but type requires string
            history.push(`/error?back=${globalThis.location.pathname}`, { errorId: !isEmpty(error_id) ? error_id : '' });
        }
        return Promise.reject(error);
    }
);
export default apiClient;
