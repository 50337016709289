import { TFormField, TAccountTab } from "../../type";

const companyName: TFormField = {
  name: 'name',
  label: 'Company Name',
  required: true,
  requiredError: 'Company name is required'
};

const website: TFormField = {
  name: 'website',
  label: 'Website',
  pattern: /(^$)|(^(https|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$)/,
  patternError: 'Please follow the format. e.g. http or https://',
  lengthError: 'The length may not be greater than 50.'
};

const addressLine1: TFormField = {
  name: 'address.firstline',
  label: 'Address line 1',
  lengthError: 'The length may not be greater than 50.'
}

const addressLine2: TFormField = {
  name: 'address.secondline',
  label: 'Address line 2',
  lengthError: 'The length may not be greater than 50.'
}

const addressCity: TFormField = {
  name: 'address.city',
  label: 'City',
  lengthError: 'The length may not be greater than 50.'
}

const addressState: TFormField = {
  name: 'address.state',
  label: 'State',
  lengthError: 'The length may not be greater than 50.'
}

const addressCountry: TFormField = {
  name: 'address.country_id',
  label: 'Country',
  lengthError: 'The length may not be greater than 50.'
}

const addressZipCode: TFormField = {
  name: 'address.zipcode',
  label: 'Zip Code',
  lengthError: 'The length may not be greater than 10.',
  pattern: /(^$)|(^[A-Za-z0-9]+$)/,
  patternError: 'Please use alphanumeric characters only.'
}

const timezone: TFormField = {
  name: 'timezone_id',
  label: 'Timezone',
  required: true,
  requiredError: 'Company name is required'
}

const tabs: TAccountTab[] = [
  {
    title: 'Account',
    id: '/settings/accounts',
    accessKey: 'settingAccount'
  },
  {
    title: 'Templates',
    id: '/settings/templates',
    accessKey: 'settingTemplates'
  },
  {
    title: 'Cloud',
    id: '/settings/cloud',
    accessKey: 'settingCloud'
  },
  {
    title: 'SMTP',
    id: '/settings/smtp',
    accessKey: 'settingSMTP'
  },
  {
    title: 'Auth Controls',
    id: '/settings/auth-controls',
    accessKey: 'settingSMTP'
  }
];

export {
  companyName,
  website,
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressCountry,
  addressZipCode,
  timezone,
  tabs
};
