import {
    ISmsGenerateRequest,
    IGeneratedQRCode,
    IEnableAuthConfignModal,
    ICountry,
    IProfile,
    IDeleteAvatarResponse,
    IChangePassword
} from '../type';
import apiClient from './config';

export const smsGenerate = async (phone: ISmsGenerateRequest) => {
    const response = await apiClient.post(`/profile/sms/generate`, phone);
    return response
};

export const getQRCode = async (): Promise<IGeneratedQRCode> => {
    const { data } = await apiClient.get(`/profile/mfa/generate`);

    if ('qr_code_image' in data.data) {
        return data.data as IGeneratedQRCode;
    }

    return null;
};

export const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await apiClient.get(`/countries`);
    if (data.data instanceof Array) {
        return data.data as ICountry[]
    }
    return [];
};

export const updateProfile = async (profile: IProfile) => await apiClient.post(`/profile`, profile);

export const uploadAvatar = async (avatar: FormData) => await apiClient.post(`/profile/avatar`, avatar, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const deleteAvatar = async (): Promise<IDeleteAvatarResponse> => await apiClient.delete(`/profile/avatar`);

export const changePassword = async (form: IChangePassword) => await apiClient.post(`/profile/change-password`, form);

export const enableMfa = async (code: IEnableAuthConfignModal) => await apiClient.post(`/profile/mfa/enable`, code);

export const disableMfa = async () => await apiClient.post(`/profile/mfa/disable`);
