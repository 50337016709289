import React from 'react';
import { Divider, TextField, FormControl, FormHelperText, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { suggestedCountries, unSuggestCountry } from '../../assets/data/country';
import { menuProps, StyledSelect, TooltipContainer, Mobile } from './FormPhone.styled';
import { phone } from '../../assets/data/setup_form';
import { Tooltip } from '../../components';
import DisplayValue from './DisplayValue';
import DisplayMenuItem from './DisplayMenuItem';

export type TStatelessFormPhone = {
    onCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPhoneChange: React.ChangeEventHandler<HTMLInputElement>;
    code: string;
    mob: string;
    error?: boolean;
    showTooltip?: boolean;
    errorMessage?: string;
    cypressId?: string;
    required?: boolean;
};

const StatelessFormPhone: React.FC<TStatelessFormPhone> = ({
    code,
    mob,
    onCodeChange,
    onPhoneChange,
    error = false,
    showTooltip = true,
    errorMessage,
    cypressId,
    required = true
}: TStatelessFormPhone) => {
    return (
        <Box>
            <Mobile>
                <FormControl error={error}>
                    <StyledSelect
                        onChange={onCodeChange}
                        value={code}
                        id="country-code-selector"
                        renderValue={DisplayValue}
                        size="small"
                        inputProps={{
                            'data-cy': `FP-select-${cypressId}`
                        }}
                        MenuProps={menuProps}>
                        {suggestedCountries.map((country) => DisplayMenuItem(country))}
                        <Divider />
                        {unSuggestCountry.map((country) => DisplayMenuItem(country))}
                    </StyledSelect>
                </FormControl>
                <TextField
                    error={error}
                    required={required}
                    size="small"
                    label={phone.label}
                    value={mob}
                    inputProps={{
                        'data-cy': `FP-input-${cypressId}`
                    }}
                    fullWidth
                    onChange={onPhoneChange}
                />
                {showTooltip && (
                    <TooltipContainer>
                        <Tooltip
                            message={'For use with sms <br/>notifications'}
                            component={<FontAwesomeIcon icon={solid('question-circle')} />}
                        />
                    </TooltipContainer>
                )}
            </Mobile>
            {error && (
                <FormHelperText data-cy={`FP-error-${cypressId}`} error>
                    {errorMessage}
                </FormHelperText>
            )}
        </Box>
    );
};
export default StatelessFormPhone;
