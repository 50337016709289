import React from 'react';
import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    fontSize: theme.font_size_12,
    fontWeight: theme.font_weight_regular,
    textAlign: 'center',

    [`& .${tooltipClasses.arrow}`]: {
        color: theme.dark_gray_2
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.dark_gray_2,
        opacity: '1'
    }
}));

export const Wrapper = styled.button`
    width: fit-content;
    border: none;
    background: transparent;
    color: ${theme.med_gray_1};
    font-size: ${theme.font_size_16};
    transition: transform 0.3s linear;
    & > svg {
        width: 15px;
    }
    &:hover {
        color: ${theme.secondary};
        > svg {
            transform: scale(1.2);
        }
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }
`;
