import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import Avatar from '@mui/material/Avatar';

type TAvatarBoxStyle = {
    dashBorder: boolean;
};

export const Container = styled.div`
    max-width: 795px;
    margin: 0 auto;
    padding: 35px 0;
    > form {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    @media ${theme.device.tabletL} {
        width: 80%;
    }
    @media ${theme.device.mobile} {
        width: 90%;
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    color: ${theme.med_gray_1};
    font-weight: ${theme.font_weight_regular};
    font-family: ${theme.font_family_montserrat};
    > span:first-child {
        font-weight: ${theme.font_weight_semiBold};
    }
    label {
        margin: 0 14px;
    }

    @media ${theme.device.tabletL} {
        font-size: ${theme.font_size_22};
    }

    @media ${theme.device.mobile} {
        & > span:last-child {
            display: none;
        }
    }
`;

export const Content = styled.div`
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${theme.font_size_15};
    color: ${theme.dark_gray_2};
    font-weight: ${theme.font_weight_regular};
    border-bottom: 1px solid ${theme.light_gray_3};
    section {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }
    @media ${theme.device.tabletL} {
        flex-direction: column;
        row-gap: 30px;
    }
`;

export const MainForm = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    width: 450px;
    padding-right: 40px;
    border-right: 1px solid ${theme.light_gray_3};
    @media ${theme.device.tabletL} {
        width: 100%;
        padding-right: 0;
        border-right: none;
        border-bottom: 1px solid ${theme.light_gray_3};
        padding-bottom: 30px;
    }
`;

export const Name = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > div {
        flex-grow: 1;
        &:first-child {
            margin-right: 30px;
        }
    }
    @media ${theme.device.mobile} {
        flex-direction: column;
        row-gap: 25px;
    }
`;

export const Country = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    & > div:first-child {
        flex-grow: 1;
        margin-right: 30px;
    }
    & > div:last-child {
        width: 120px;
    }
`;

export const Aside = styled.div`
    width: 264px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    .MuiLoadingButton-root {
        svg {
            font-size: ${theme.font_size_14};
        }
    }
    p {
        font-size: ${theme.font_size_13};
        margin-top: 0;
        margin-bottom: 0;
        > span:first-child {
            margin-right: 5px;
        }
    }
    @media ${theme.device.tabletL} {
        padding-left: 0;
        width: 100%;
    }
`;

export const AvatarBox = styled.div<TAvatarBoxStyle>`
    position: relative;
    width: fit-content;
    height: fit-content;
    > input {
        display: none;
    }
    label {
        width: 160px;
        height: 160px;
        border-width: 1px;
        border-style: ${(props) => (props.dashBorder ? 'solid' : 'dashed')};
        border-color: ${theme.border};
        display: block;
        border-radius: 50%;
        cursor: pointer;
    }
    @media ${theme.device.tabletL} {
        padding-left: 0;
        margin: 0 auto;
    }
`;

export const StyleAvatar = styled(Avatar)`
    width: 158px;
    height: 158px;
    background: ${theme.light_gray_3};
    svg {
        color: ${theme.med_gray_1};
        height: 65px;
    }
`;

export const ActionGroup = styled.div`
    position: absolute;
    width: 84px;
    display: flex;
    justify-content: center;
    bottom: -15px;
    margin-left: -40px;
    left: 50%;
    z-index: 50;
`;

export const IconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: 1px solid ${theme.border};
    border-radius: 50%;
    background: ${theme.white};
`;

export const ChangePassword = styled.div`
    width: 180px;
`;

export const ChangeMfa = styled.div`
    width: 82px;
`;

export const Footer = styled.div`
    width: 150px;
    @media ${theme.device.mobile} {
        width: 100%;
    }
`;