import { createGlobalStyle } from 'styled-components';
import * as theme from './variables';
export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family:Poppins,sans-serif;
    font-weight: ${theme.font_weight_regular};
    box-sizing: border-box;
  };

  legend {
    width: auto;
  }

  label {
    margin-bottom: 0;
    font-weight: inherit;
  }

  // fi-app styles overrides
  .content-wrapper {
    background-color: ${theme.white};
  }
`;
