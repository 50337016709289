import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

type TLinearProgress = {
    size: 'medium' | 'small';
};

type TTitle = {
    value: number;
    isDefault: boolean;
};

export const StyledLinearProgress = styled(LinearProgress)<TLinearProgress>((props: TLinearProgress) => ({
    height: props.size === 'medium' ? '10px' : '6px',
    borderRadius: props.size === 'medium' ? '10px' : '5px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.light_gray_2
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: props.size === 'medium' ? '10px' : '0px',
        backgroundColor: theme.secondary
    }
}));

export const Title = styled.div<TTitle>`
    text-align: right;
    color: ${(props) => (props.isDefault || props.value > 50 ? theme.med_gray_1 : theme.red)};
    font-size: ${theme.font_size_12};
    font-weight: ${theme.font_weight_regular};
    margin-bottom: 10px;
`;

export const Wrapper = styled.div`
    position: relative;
`;
