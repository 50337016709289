import styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { buttonPlatte } from '../styles/palette';
import * as theme from '../styles/variables';
import { TButtonStyle } from '../../type';

const StyledButton = styled(LoadingButton)<TButtonStyle>`
    position: relative;
    line-height: 1.2;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    background-color: ${(props) => buttonPlatte[props.mode].bgColor};
    border: 1px solid ${(props) => buttonPlatte[props.mode].borderColor};
    text-transform: capitalize;
    font-family: ${theme.font_family_poppins};
    font-size: ${(props) => buttonPlatte[props.mode].fontSize};
    font-weight: ${(props) => buttonPlatte[props.mode].fontWeight};
    height: ${(props) => (props.size == 'small' ? '32px' : '40px')};
    color: ${(props) => buttonPlatte[props.mode].textColor};
    min-height: 32px;
    svg {
        color: ${(props) => buttonPlatte[props.mode].textColor};
    }
    &:hover {
        transition: ease-in-out all 0.3s;
        background-color: ${(props) => buttonPlatte[props.mode].hoverBgColor};
        border: 1px solid ${(props) => buttonPlatte[props.mode].borderColor};
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    @media ${theme.device.mobile} {
        width: 100%;
    }
`;

export default StyledButton;
