import * as React from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { Popper } from '@mui/material';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as theme from '../../assets/styles/variables';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        fontSize: theme.font_size_13,
        color: theme.dark_gray_2,
        '& .MuiAutocomplete-option': {
            '&.Mui-focused': {
                backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
            }
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`,
            '&.Mui-focused': {
                backgroundColor: `rgba(${theme.light_gray_3_rgb}, ${theme.opacity_4})`
            }
        }
    },
    [`& .${autocompleteClasses.noOptions}`]: {
        color: theme.dark_gray_2,
        fontSize: theme.font_size_13
    }
});

export const StyledAutocomplete = styled(({ ...props }) => (
    <Autocomplete
        {...props}
        size="small"
        PopperComponent={StyledPopper}
        popupIcon={<FontAwesomeIcon icon={regular('search')} />}
        noOptionsText="There are no results"
    />
))(() => ({
    '& .MuiAutocomplete-popupIndicator': {
        transform: 'rotate(360deg) scale(0.8) translateY(3px)',
        fontSize: theme.font_size_20
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none'
    }
}));

export default StyledAutocomplete;
