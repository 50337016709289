import { useQuery, useMutation } from 'react-query';
import { keyBy, merge } from 'lodash';
import {
    getGettingStartItems,
    getGettingStartOption,
    updateGettingStartOption,
    updateGettingStartItems,
    signup,
    setupQuestion,
    login,
    verifyMfa
} from '../services/onboardingServices';
import { tiles } from '../assets/data/tile_content';

export const useGettingStartedItemsQuery = () => useQuery('gettingStartItems', async () => {
    const { data, progress } = await getGettingStartItems();
    const map2 = keyBy(tiles, 'slug');
    const mergeArray = data.map(item => merge({}, item, map2[item.slug]))
    const tilesTOP = []; const tilesBottom = [];
    mergeArray.forEach(item => {
        if (item.slug === 'send-an-invite' || item.slug === 'preview-your-client-portal') {
            tilesTOP.push(item)
        } else {
            tilesBottom.push(item)
        }
    })
    return { tilesTOP, tilesBottom, progress }
});

export const useGetGettingStartOptionQuery = () => useQuery('gettingStartOption', getGettingStartOption);

export const useGetGettingStartOptionMutation = () => useMutation(updateGettingStartOption);

export const useGettingStartedItemsMutation = () => useMutation(updateGettingStartItems);

export const useSignupMutation = () => useMutation(signup);

export const useSetupQuestionMutation = () => useMutation(setupQuestion);

export const useLoginMutation = () => useMutation(login);

export const useLoginMfaMutation = () => useMutation(verifyMfa);
