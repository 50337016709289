import React from 'react';
import { Container } from './Badge.styled';
import { TMode } from '../../type';

type TBadgeProps = TMode & {
    type?: 'outlined' | 'contained';
    children: React.ReactNode;
};

const Badge: React.FC<TBadgeProps> = ({ mode = 'Primary', type = 'contained', children }: TBadgeProps) => {
    return (
        <>
            <Container mode={mode} type={type}>
                {children}
            </Container>
        </>
    );
};

export default Badge;
