const email = {
    type: 'text',
    name: 'email',
    label: 'Email address',
    required: true,
    requiredError: 'Email is required',
    emailError: 'Email must be a valid email'
};

const password = {
    type: 'password',
    name: 'password',
    label: 'Password',
    required: true,
    combinationPattern: /^.*(?=.{7,99})((?=.*[!@#$%^&*']){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    requiredError: 'Password is required',
    patternError: 'Password need to match the following pattern'
};

const verificationCode = {
    type: 'text',
    name: 'code',
    label: 'Verification Code',
    required: true,
    requiredError: 'Verification code is required'
};

export { email, password, verificationCode };