import styled from 'styled-components';
import { Typography } from '@mui/material';
import * as theme from '../../assets/styles/variables';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.light_gray_4};
`;

export const Main = styled.div`
    text-align: center;
    transform: translateY(-200px);
`;

export const StyledImg = styled.img`
    width: 170px;
    height: 180px;
    text-align: center;
    margin-bottom: 45px;
`;

export const StyledTextTop = styled(Typography)`
    color: ${theme.med_gray_1};
    font-size: ${theme.font_size_24};
    font-family: ${theme.font_family_montserrat};
    margin-bottom: 10px;
    font-weight: ${theme.font_weight_semiBold};
`;

export const StyledTextBottom = styled(Typography)`
    color: ${theme.dark_gray_2};
    font-size: ${theme.font_size_15};
    font-weight: ${theme.font_weight_regular};
`;
