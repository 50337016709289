import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TTileStyleProps = {
    completion: boolean;
};

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
`;

export const Icon = styled.div<TTileStyleProps>`
    font-size: ${theme.font_size_30};
    margin-right: 20px;
    color: ${(props) => (props.completion ? theme.primary : theme.light_gray_2)};
`;

export const Content = styled.div`
    color: ${theme.dark_gray_2};
    font-size: ${theme.font_size_13};
    font-weight: ${theme.font_weight_regular};
`;

export const Title = styled.div`
    font-size: ${theme.font_size_18};
    font-weight: ${theme.font_weight_medium};
    margin-bottom: 2px;

    @media ${theme.device.mobile} {
        font-size: ${theme.font_size_16};
    }
`;

export const Desc = styled.div`
    cursor: pointer;
    > a {
        text-decoration: underline;
        color: ${theme.secondary};
    }
`;
