import React from 'react';
import { FC, useState, useEffect } from 'react';
import { Card } from './PasswordRuleHint.styled';
import { upperCasePattern, lowerCasePattern, numberPattern, specialCharPattern } from '../../assets/utils/passwordRegx';
import PasswordRule from '../../components/PasswordRule/PasswordRule';

type TPasswordRuleHintProps = {
    password: string;
    isArrowFixed?: boolean;
};

const PasswordRuleHint: FC<TPasswordRuleHintProps> = ({ password, isArrowFixed = true }) => {
    const [caseState, setCaseState] = useState({
        isValidLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSepcialChar: false
    });
    const { isValidLength, hasUpperCase, hasLowerCase, hasNumber, hasSepcialChar } = caseState;

    const rules = [
        {
            condition: isValidLength,
            text: 'At least 7 characters total'
        },
        {
            condition: hasUpperCase,
            text: 'At least 1 uppercase character'
        },
        {
            condition: hasLowerCase,
            text: 'At least 1 lowercase character'
        },
        {
            condition: hasNumber,
            text: 'At least 1 number'
        },
        {
            condition: hasSepcialChar,
            text: 'At least 1 of these characters: ! @ # $ % ^ & * ‘'
        }
    ];

    useEffect(() => {
        setCaseState({
            isValidLength: password.length >= 7,
            hasUpperCase: password.search(upperCasePattern) > -1,
            hasLowerCase: password.search(lowerCasePattern) > -1,
            hasNumber: password.search(numberPattern) > -1,
            hasSepcialChar: password.search(specialCharPattern) > -1
        });
    }, [password]);

    return (
        <Card isArrowFixed={isArrowFixed}>
            <h5>Password must contain:</h5>
            {rules.map((item, idx) => (
                <PasswordRule
                    key={idx}
                    condition={item.condition}
                    text={item.text}
                    data-cy={item.condition ? 'success-status' : 'fail-status'}
                />
            ))}
        </Card>
    );
};

export default PasswordRuleHint;
