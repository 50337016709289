import styled from 'styled-components';
import { PaperProps } from '@mui/material';
import AuthDeviceDesktop from '../../../../assets/images/auth-device@2x.png';
import * as theme from '../../../../assets/styles/variables';

export const paperProps: Partial<PaperProps> = {
    style: {
        maxWidth: 900,
        width: 900
    }
};

export const paperPropsaSm: Partial<PaperProps> = {
    style: {
        maxWidth: 350,
        width: 350
    }
};

export const Title = styled.div`
    @media ${theme.device.mobile} {
        font-size: ${theme.font_size_18};
    }

    & > svg {
        margin-right: 17px;

        @media ${theme.device.mobile} {
            margin-right: 10px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    overflow-y: auto;
    box-sizing: border-box;
    color: ${theme.dark_gray_2};
    font-size: ${theme.font_size_13};
`;

export const Steps = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media ${theme.device.tablet} {
        display: flex;
        flex-direction: column;
    }

    & > section {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    & > section:not(:last-child) {
        padding-right: 30px;
        border-right: 1px solid ${theme.light_gray_6};

        @media ${theme.device.tablet} {
            padding-right: 0;
            border-right: unset;
            padding-bottom: 30px;
            border-bottom: 1px solid ${theme.light_gray_6};
        }
    }
`;

export const StepText = styled.p`
    margin: 0;
`;

export const Code = styled.div`
    padding: 10px 40px;
    font-size: ${theme.font_size_16};
    font-weight: ${theme.font_weight_semiBold};
    background-color: ${theme.light_gray_3};
    color: ${theme.med_gray_1};
    text-align: center;
`;

export const ImageDevice = styled.div`
    width: 116px;
    height: 210px;
    background-image: url(${AuthDeviceDesktop});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
`;

export const ImageQRCode = styled.img`
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto;
`;