import React from 'react';
import { Outlet } from 'react-router-dom';
import { Content } from './EmptyLayout.styled';

const EmptyLayout: React.FC = () => {
     return (
        <Content>
            <Outlet />
        </Content>
    );
};

export default EmptyLayout;
