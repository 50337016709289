import { createColumnHelper } from '@tanstack/react-table';
import { MinimalPagination, Table } from '../../../components';
import { Container } from './Table.styled';
import React from 'react';

type TData = {
    firstName: string;
    lastName: string;
    age: number;
};

const data: TData[] = [
    {
        firstName: 'John',
        lastName: 'Doe',
        age: 10
    },
    {
        firstName: 'Mary',
        lastName: 'Poppins',
        age: 20
    },
    {
        firstName: 'Obi',
        lastName: 'Kenobi',
        age: 30
    }
];

const columnHelper = createColumnHelper<TData>();

const columns = [
    columnHelper.accessor('firstName', {
        header: () => 'First Name',
        cell: (info) => info.getValue()
    }),
    columnHelper.accessor('lastName', {
        enableSorting: false,
        header: () => 'Last Name',
        cell: (info) => info.getValue()
    }),
    columnHelper.accessor('age', {
        header: () => 'Age',
        cell: (info) => info.getValue()
    })
];

const TableSample = () => {
    const noop = () => {
        return;
    };

    return (
        <Container>
            <Table columns={columns} data={data} hiddenOnMobile={['age']} />
            <MinimalPagination pageSize={10} onFirst={noop} onPrev={noop} onNext={noop} onLast={noop} onPageSizeChange={noop} />
        </Container>
    );
};

export default TableSample;
