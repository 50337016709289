import React from 'react';
import { Wrapper, Img, Title, Desc, BtnWrapper, Link } from './TrialExpired.styled';
import { Button, Loading } from '../../components';
import { useAuthQuery } from '../../hooks/auth';

const TrialExpired: React.FC = () => {
    const { data: authData, isLoading, isError } = useAuthQuery();
    const settingPlanPermission = authData?.current_permissions?.settingPlan || false;

    const handleClick = () => {
        window.location.href = `//${window.location.host}/settings/plans/change`;
    };

    if (isLoading) {
        return <Loading />;
    }
    if (isError) {
        return <span>Error</span>;
    }

    return (
        <Wrapper>
            <Title>Oh no, your account has expired!</Title>
            <Desc>Upgrade your payment plan to restore access and start sending invites again in no time.</Desc>
            <BtnWrapper isShow={settingPlanPermission}>
                <Button type="button" onClick={handleClick}>
                    Reactivate Account
                </Button>
            </BtnWrapper>
            <Link href="/logout">Back to login</Link>
            <Img />
        </Wrapper>
    );
};
export default TrialExpired;