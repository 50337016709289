import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Login from './pages/Login/Login';
// import './i18n';

/**
 * This app support multiple entry pages. Default is APP.
 */
enum EntryPage {
    APP = 'APP',
    LOGIN = 'LOGIN'
}

const getEntryComponent = (entry: EntryPage) => {
    const entryMap = {
        [EntryPage.APP]: <App />,
        [EntryPage.LOGIN]: <Login />
    };

    return entryMap[entry] || <App />;
};

ReactDOM.render(getEntryComponent(EntryPage[process.env.REACT_APP_BUILD_TARGET]), document.getElementById('root'));
