import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledCloseButton, StyledDialogTitle } from './CustomDialogTitle.styled';

type TCustomDialogTitleProps = {
    children?: React.ReactNode;
    onClose?: () => void;
};

const CustomDialogTitle: React.FC<TCustomDialogTitleProps> = ({ children, onClose, ...other }: TCustomDialogTitleProps) => {
    return (
        <>
            {children && <StyledDialogTitle {...other}>{children}</StyledDialogTitle>}
            {onClose && (
                <StyledCloseButton onClick={onClose}>
                    <FontAwesomeIcon icon={regular('xmark')} />
                </StyledCloseButton>
            )}
        </>
    );
};

export default CustomDialogTitle;
