/* eslint-disable max-len */
import axios from 'axios';
import { IInviteResponse } from '../type';

const apiClient = axios.create({
    baseURL: 'http://localhost:8000'
});

const getInviteData = async ({ pageNumber, sort, pageSize, search, user }): Promise<IInviteResponse> => {
    const response = await apiClient.get<IInviteResponse>(
        `/internal-api/invites?page[number]=${pageNumber}&sort=-${sort}&page[size]=${pageSize}&include_closed=false&filter[search]=${search}&filter[user]=${user}`
    );
    return response.data;
};

export default getInviteData;
