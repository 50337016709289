import { 
  TContactResponse, 
  TContactFormPayload, 
  ICountry, 
  ICompany 
} from '../type';
import apiClient from './config';

export const getContact = async (id: string): Promise<TContactResponse> => {
    const { data } = await apiClient.get(`/contacts/${id}`);

    return data.data as TContactResponse;
};

export const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await apiClient.get(`/countries`);

    if (data.data instanceof Array) {
        return data.data as ICountry[]
    }

    return [];
};

export const getCompanies = async (search: string): Promise<ICompany[]> => {
  const { data } = await apiClient.get('/companies', { 
    params: {
      sort: 'created_at',
      ['filter[search]']: search,
      ['page[number]']: 1,
      ['page[size]']: 10
    }
  });

  return (data.data?.length ? data.data : []) as ICompany[];
}

export const createContact = async (contact: TContactFormPayload) => await apiClient.post(`/contacts`, contact);

export const editContact = async (contact: TContactFormPayload) => await apiClient.put(`/contacts/${contact.uuid}`, contact);
