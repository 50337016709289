import { IUser } from '../type';
import apiClient from './config';

export const getMe = async (): Promise<IUser> => {
    const { data } = await apiClient.get(`/users/me`);
    if ('uuid' in data.data) {
        return data.data as IUser;
    }
    return null;
};
