import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(${theme.dark_gray_2_rgb}, ${theme.opacity_5});
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    #loading-bm {
        margin: 25%;
        width: 300px;
        height: 300px;
    }
`;


