import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TResetAlertProps = {
    open?: boolean;
};

export const Container = styled.div`
    width: 100%;
`;

export const Main = styled.div`
    width: 350px;
    transform: translateY(160px);
    margin: 0 auto;
    font-size: ${theme.font_size_13};
    font-weight: ${theme.font_weight_regular};
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 20px;
    @media ${theme.device.mobile} {
        width: 300px;
        transform: translateY(0);
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    color: ${theme.med_gray_1};
`;

export const Subtitle = styled.div`
    color: ${theme.dark_gray_2};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 20px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    @media ${theme.device.mobile} {
        justify-content: space-between;
    }
`;

export const BtnWrapper = styled.div`
    :first-child {
        margin-right: 20px;
    }
    @media ${theme.device.mobile} {
        flex: 1;
    }
`;
export const AlertWrapper = styled.div<TResetAlertProps>`
    display: ${(props) => (props.open ? 'block' : 'none')};
    > a {
        display: block;
        margin-top: 25px;
        text-decoration: none;
        color: ${theme.secondary};
        @media ${theme.device.mobile} {
            display: block;
            text-align: center;
            margin: 0 auto;
        }
    }
`;
