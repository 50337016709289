import React, { useEffect, useState } from 'react';
import { StyledPagination, PageSizeContainer, StyledSelect, Container } from './TablePagination.styled';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import { pageSizeOptions } from '../../assets/data/pagesize';

type TPagination = {
    total: number;
    currentPage: number;
    onPageChange: ({ newPageSize, currPage }) => void;
};

const TablePagination: React.FC<TPagination> = ({ total, currentPage, onPageChange }) => {
    const [pageNumber, setPageNumber] = useState<number>(currentPage);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        if (pageNumber !== currentPage || pageSize !== 10) onPageChange({ newPageSize: pageSize, currPage: pageNumber });
    }, [currentPage, onPageChange, pageNumber, pageSize]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        setPageSize(parseInt(event.target.value as string));
    };
    //calcualte MenuItem
    const maxPageNumber = Math.ceil(total / pageSize);

    return (
        <Container>
            <PageSizeContainer>
                <span>show</span>
                <FormControl>
                    <StyledSelect size="small" value={pageSize} displayEmpty onChange={handlePageSizeChange}>
                        {pageSizeOptions.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.text}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>{' '}
                <span>rows</span>
            </PageSizeContainer>
            <Stack spacing={2}>
                <StyledPagination
                    count={maxPageNumber}
                    defaultPage={pageNumber}
                    onChange={handlePageChange}
                    shape="rounded"
                    variant="outlined"
                />
            </Stack>
        </Container>
    );
};

export default TablePagination;
