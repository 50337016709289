import { TBreadCrumbs } from '../../type';

const getStartedBreadcrumbs: TBreadCrumbs[] = [
    {
        title: 'FileInvite',
        path: '/'
    },
    {
        title: 'Getting Started',
        path: '#'
    }
];

const profileBreadcrumbs: TBreadCrumbs[] = [
    {
        title: 'FileInvite',
        path: '/'
    },
    {
        title: 'Profile',
        path: '#'
    }
];

const accountBreadcrumbs: TBreadCrumbs[] = [
    {
        title: 'FileInvite',
        path: '/'
    },
    {
        title: 'Account',
        path: '/settings/account'
    },
    {
        title: 'Company',
        path: '#'
    }
];

const contactBreadcrumbs: TBreadCrumbs[] = [
    {
        title: 'FileInvite',
        path: '/'
    },
    {
        title: 'Contacts',
        path: '/contacts'
    },
    {
        title: 'People',
        path: '/contacts'
    }
];

const contactCreateBreadcrumbs: TBreadCrumbs[] = contactBreadcrumbs.concat({
    title: 'Create Contact',
    path: '#'
});

const contactEditBreadcrumbs: TBreadCrumbs[] = contactBreadcrumbs.concat({
    title: 'Edit Contact',
    path: '#'
});

const companyBreadcrumbs: TBreadCrumbs[] = [
    {
        title: 'FileInvite',
        path: '/'
    },
    {
        title: 'Contacts',
        path: '/contacts'
    },
    {
        title: 'Companies',
        path: '/companies'
    }
];

const companyCreateBreadcrumbs: TBreadCrumbs[] = companyBreadcrumbs.concat({
    title: 'Create Company',
    path: '#'
});

const companyEditBreadcrumbs: TBreadCrumbs[] = companyBreadcrumbs.concat({
    title: 'Edit Company',
    path: '#'
});

export {
    getStartedBreadcrumbs,
    profileBreadcrumbs,
    accountBreadcrumbs,
    contactBreadcrumbs,
    contactCreateBreadcrumbs,
    contactEditBreadcrumbs,
    companyCreateBreadcrumbs,
    companyEditBreadcrumbs
};
