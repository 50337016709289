import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import { Select } from '@mui/material';
import { MenuProps } from '@mui/material';

export const LabelContainer = styled.span`
    font-size: ${theme.font_size_12};
    color: ${theme.dark_gray_2};
    margin-left: 4px;
`;

export const StyledImg = styled.img`
    width: 20px;
`;

export const StyledSelect = styled(Select)(() => ({
    width: '84px',
    minWidth: '84px',
    '& .MuiSelect-select': { paddingRight: '0px !important', paddingLeft: '0px !important' },
    '& .MuiSelect-icon': {
        right: 0
    }
}));

export const StyledBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
`;

export const StyledItemBox = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const menuProps: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: 300
        }
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    }
};

export const TooltipContainer = styled.div`
    position: absolute;
    top: 5px;
    right: -13px;
    z-index: 10;
`;

export const Mobile = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > div {
        &:nth-child(2) {
            flex-grow: 1;
        }
    }
    .MuiFormControl-root:first-child {
        width: 84px;
        min-width: 84px;
        .MuiInputBase-root {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .MuiFormControl-root:nth-child(2) {
        margin-left: -1px;
        .MuiInputBase-root {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`;
