import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledLink, StyledSpan, StyledBreadcrumbs } from './CustomBreadcrumbs.styled';

type TBreadcrumbs = {
    title: string;
    path: string;
};

const CustomBreadcrumbs: React.FC<{ breadcrumbs: TBreadcrumbs[] }> = ({ breadcrumbs }) => {
    return (
        <StyledBreadcrumbs separator={<FontAwesomeIcon icon={light('chevron-right')} />} aria-label="breadcrumb">
            {breadcrumbs.map((item: TBreadcrumbs, index) => {
                if (index === breadcrumbs.length - 1) {
                    return <StyledSpan key={index}> {item.title} </StyledSpan>;
                } else {
                    return (
                        <StyledLink href={item.path} target="_self" key={index}>
                            {item.title}
                        </StyledLink>
                    );
                }
            })}
        </StyledBreadcrumbs>
    );
};
export default CustomBreadcrumbs;
