import React from 'react';
import Lottie from 'lottie-react-web';
import loading from './data.json';
import { Container } from './Loading.styled';

const Loading = () => (
    <Container>
        <div id="loading-bm">
            <Lottie
                options={{
                    animationData: loading
                }}
            />
        </div>
    </Container>
);

export default Loading;
