import * as Yup from 'yup';
import {
  firstName, lastName, phone, email, password, verificationCode, city, addressLine1, addressLine2, state, zipcode
} from '../data/profile_journey';

// yup validation
const profileSchema = Yup.object().shape({
  firstname: Yup.string().required(firstName.requiredError).max(30, firstName.lengthError),
  lastname: Yup.string().required(lastName.requiredError).max(30, lastName.lengthError),
  email: Yup.object().shape({
    address: Yup.string().required(email.requiredError).email(email.emailError).max(50, email.lengthError)
  }),
  phone: Yup.object().shape({
    number: Yup.string()
      .test('mobile-validation', phone.patternError, function (this, value) {
        if (value.length > 0) {
          const val = `+${this.parent.national_number}${value}`;
          return val.match(phone.pattern) && val.length >= 10
        }
        return true;
      })
  }),
  address: Yup.object().shape({
    city: Yup.string().max(50, city.lengthError),
    firstline: Yup.string().max(50, addressLine1.lengthError),
    secondline: Yup.string().max(50, addressLine2.lengthError),
    state: Yup.string().max(50, state.lengthError),
    zipcode: Yup.string().max(10, zipcode.lengthError).
      test('zip-code', zipcode.patternError, function (value) {
        if (value) {
          const schema = Yup.string().matches(zipcode.pattern, zipcode.patternError);
          return schema.isValidSync(value);
        }
        return true;
      })
  }),
  password: Yup.string().required(password.requiredError
  ).max(99, password.lengthError),
  sms_verification_code: Yup.string().
    when('$isShowSms', (isShowSms) => isShowSms
      ? Yup.string().required(verificationCode.requiredError
      ).max(4, verificationCode.lengthError)
      : Yup
        .string()
        .notRequired()
    )
})

export {
  profileSchema
};