import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField } from '@mui/material';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import SignupLayout from '../../components/SignupLayout/SignupLayout';
import { AlertBox, Button, PasswordRuleHint } from '../../components';
import { Header, Title, LoginLink, Wrapper, BtnWrapper, Footer, Error, Term, TermContent } from './Signup.styled';
import { signupSchema } from '../../assets/utils/sign_up_schema';
import { email, password } from '../../assets/data/sign_up_journey';
import { useSignupMutation } from '../../hooks/onboarding';
import { ISignupForm, TErrorMessage } from '../../type';
import { useRecaptchaQuery } from '../../hooks/recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

type ListItemProps = {
    text: string;
};

// freemium or trial
const plan = document.getElementsByName('defaultPlan')[0]?.getAttribute('content');

const lists = [
    { text: 'No credit card required (for Free plan)' },
    { text: 'Get documents back up to 80% faster than email' },
    { text: 'SOC 2 Type II certified, and GDPR, HIPAA and FERPA compliant' }
];

const ListItem: React.FC<ListItemProps> = ({ text }) => {
    return (
        <p>
            <FontAwesomeIcon icon={regular('check')} size="lg" />
            <span> {text} </span>
        </p>
    );
};

const Signup: React.FC = () => {
    const [searchParams] = useSearchParams();

    const { data: recaptcha } = useRecaptchaQuery();

    // using the global function window._hsq that's initialized from HubSpot tracking code
    const { setIdentity, setPathPageView } = useTrackingCode();

    setPathPageView('/sign-up');

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<ISignupForm>({
        defaultValues: {
            email: '',
            password: '',
            isNotRobot: '',
            terms_and_conditions: false
        },
        resolver: yupResolver(signupSchema),
        context: { isShowRecaptcha: recaptcha?.recaptcha_enabled }
    });

    // Route will carry geoLocation query params and set to localstorage for pre-quil form to show default country code
    localStorage.setItem('origin', searchParams.get('origin') ?? 'AU');

    const isNotRobotValid = watch('isNotRobot');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [message, setMessage] = useState<any>('');
    const [isShow, setIsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { mutate } = useSignupMutation();

    const onSubmit = (form) => {
        // Prevent multiple button presses
        setIsLoading(true);
        // No matter if the url has market campaine query parameters
        setIdentity(form.email);

        mutate(form, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 422) {
                    setIsShow(true);
                    setMessage(data);
                }
                setIsLoading(false);
            },
            onSuccess: () => {
                window.location.href = `//${window.location.host}/onboarding/setup`;
            }
        });
    };

    const GenerateForm = (item) => {
        return (
            <Controller
                name={item.name}
                control={control}
                render={({ field, fieldState: { isDirty } }) => (
                    <>
                        <TextField
                            {...field}
                            id={field.name}
                            data-cy={`${field.name}-wrapper`}
                            inputProps={{
                                'data-cy': `${field.name}-input`
                            }}
                            size="small"
                            fullWidth
                            required
                            label={item.label}
                            type={item.type}
                            error={Boolean(errors[field.name])}
                            helperText={errors[field.name]?.message}
                        />
                        {field.name === 'password' && isDirty && <PasswordRuleHint password={field.value} />}
                    </>
                )}
            />
        );
    };

    const generateAlertMessage = (messages: TErrorMessage) => {
        let messageArray: string[] = [];
        const emailMessage = `, <a href="/login"> Login here </a> or <a href="/password/reset"> reset your password. </a>`;
        Object.entries(messages).forEach(([key, value]) => {
            messageArray = [...messageArray, ...[`${value.details.toString()} ${key === 'email' ? emailMessage : ''}`]];
        });
        return messageArray;
    };

    return (
        <>
            <SignupLayout>
                <Header>
                    <Title> {plan === 'trial' ? 'Start Your Free 14-Day Trial' : 'Create your free FileInvite account'}</Title>
                    {plan === 'trial' ? (
                        <p>
                            Request documents, signatures, files and data from your clients. Send your first Invite (a package of requests)
                            today, and get hours back each week.
                        </p>
                    ) : (
                        lists.map((item, idx) => {
                            return <ListItem key={idx} text={item.text} />;
                        })
                    )}
                    <LoginLink>
                        Already have an account?{' '}
                        <a data-cy="login-link" href="/login">
                            {' '}
                            Login here.
                        </a>
                    </LoginLink>
                </Header>
                {isShow && <AlertBox data-cy="signup-alert-box" mode="Error" messages={generateAlertMessage(message)}></AlertBox>}
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    {/* Disable browser autofill - fix browser compatibility */}
                    <input type="text" name="_prevent_auto_complete_name" autoComplete="off" readOnly hidden />
                    <input type="password" name="_prevent_auto_complete_password" autoComplete="new-password" readOnly hidden />
                    <Wrapper>
                        {GenerateForm(email)}
                        {GenerateForm(password)}
                        <Controller
                            name="isNotRobot"
                            control={control}
                            render={({ field }) => (
                                <div data-cy="recaptcha-wrapper">
                                    {recaptcha?.recaptcha_site_key && recaptcha?.recaptcha_enabled && (
                                        <ReCAPTCHA
                                            {...field}
                                            data-cy="recaptcha"
                                            sitekey={recaptcha.recaptcha_site_key}
                                            onChange={(val: string) => {
                                                setValue('isNotRobot', val);
                                            }}
                                        />
                                    )}
                                    {!isNotRobotValid && <Error>{errors[field.name]?.message}</Error>}
                                </div>
                            )}
                        />
                        <Term>
                            <Controller
                                name="terms_and_conditions"
                                control={control}
                                render={({ field }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    checked={Boolean(field.value)}
                                                    size={'small'}
                                                    inputProps={
                                                        {
                                                            'data-cy': 'terms-checkbox'
                                                        } as object
                                                    }
                                                />
                                            }
                                            label={
                                                <TermContent>
                                                    I accept the{' '}
                                                    <a target="_blank" href="https://www.fileinvite.com/terms" rel="noreferrer">
                                                        terms{' '}
                                                    </a>{' '}
                                                    and{' '}
                                                    <a target="_blank" href="https://www.fileinvite.com/privacy" rel="noreferrer">
                                                        privacy policy{' '}
                                                    </a>{' '}
                                                </TermContent>
                                            }
                                        />
                                        <FormHelperText data-cy="terms-helper-text" error={Boolean(errors[field.name])}>
                                            {errors[field.name]?.message}
                                        </FormHelperText>
                                    </FormGroup>
                                )}
                            />
                        </Term>
                    </Wrapper>
                    <BtnWrapper>
                        <Button data-cy="sign-up" mode={'Primary'} loading={isLoading}>
                            Sign up
                        </Button>
                    </BtnWrapper>
                </form>
                {plan === 'trial' && <Footer>No credit card required. All features included.</Footer>}
            </SignupLayout>
        </>
    );
};
export default Signup;
