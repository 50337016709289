import React from 'react';
import { MainButton, Container } from './ArrowButton.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ArrowPositionType, TBasicButton } from '../../type';

/**
 * fas for solid, far for regular, fal for light, or fab for brand.
 */

type TArrowButtonProps = TBasicButton & {
    text: string;
    direction?: 'left' | 'right';
};

const ArrowButton: React.FC<TArrowButtonProps> = ({
    mode = 'Primary',
    size = 'large',
    type = 'submit',
    text,
    direction,
    disabled = false,
    loading = false,
    onClick,
    ...rest
}: TArrowButtonProps) => {
    return (
        <MainButton {...rest} type={type} mode={mode} size={size} disabled={disabled} onClick={onClick} loading={loading}>
            <Container direction={direction}>
                <label>{text}</label>
                {direction === ArrowPositionType.LEFT && <FontAwesomeIcon icon={solid('arrow-left')} />}
                {direction === ArrowPositionType.RIGHT && <FontAwesomeIcon icon={solid('arrow-right')} />}
            </Container>
        </MainButton>
    );
};

export default ArrowButton;
