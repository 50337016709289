import React from 'react';
import { Container, LeftWrapper, ImgWrapper, LogoImg, Content, OnboardingImg, Main, FreeiumDesc } from './SignupLayout.styled';
import Logo from '../../assets/images/logo.svg';
import TrialImage from '../../assets/images/trial-image.png';
import FreeiumImage from '../../assets/images/freeium-bg-trimmy.png';

// freemium or trial
const plan = document.getElementsByName('defaultPlan')[0]?.getAttribute('content');

const SignupLayout: React.FC = ({ children }) => {
    return (
        <Container>
            <LeftWrapper>
                <ImgWrapper>
                    <LogoImg src={Logo} alt="logo" />
                    {plan === 'trial' ? (
                        <OnboardingImg src={TrialImage} alt="trial-image" />
                    ) : (
                        <>
                            <FreeiumDesc>
                                Experience document collection that’s <br /> simple, fast and secure
                            </FreeiumDesc>
                            <OnboardingImg src={FreeiumImage} alt="freeium-image" />
                        </>
                    )}
                </ImgWrapper>
            </LeftWrapper>
            <Content>
                <Main>{children}</Main>
            </Content>
        </Container>
    );
};

export default SignupLayout;
