import { useQuery, useMutation } from 'react-query';
import {
  getContact, 
  getCountries, 
  getCompanies,
  createContact,
  editContact
} from '../services/contactServices';
import useDebounce from '../hooks/useDebounce';

export const useContactQuery = (id: string) => useQuery(['getContact', id], () => getContact(id), { enabled: !!id });

export const useCompaniesQuery = (search: string) => useQuery(
  ['getCompanies', search],
  () => getCompanies(search)
);

export const useCompaniesQueryDebounced = (searchQuery: string) => {
  const debouncedSearchQuery = useDebounce(searchQuery, 600);
  const res = useCompaniesQuery(debouncedSearchQuery);

  return res;
}

export const useCountriesQuery = () => useQuery('getCountries', getCountries);

export const useCreateContactMutation = () => useMutation('createContact', createContact);

export const useEditContactMutation = () => useMutation('editContact', editContact);
