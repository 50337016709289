import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 15px;
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    color: ${theme.med_gray_1};
`;

export const Link = styled.div`
    color: ${theme.med_gray_1};
    > a {
        color: ${theme.secondary};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 25px;
    margin-bottom: 25px;
`;
export const BtnWrapper = styled.div`
    width: 100%;
`;

export const Footer = styled.div`
    color: ${theme.med_gray_1};
    font-size: ${theme.font_size_14};
    display: flex;
    flex-direction: column;
    row-gap: 35px;
`;