import { TFormField } from '../../type';

const name: TFormField = {
    type: 'input',
    id: 'name',
    name: 'name',
    label: 'Name',
    required: true,
    requiredError: 'Name is required',
    lengthError: 'The length may not be greater than 50.'
};

const website: TFormField = {
    name: 'website',
    label: 'Website',
    pattern: /(^$)|(^(https|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$)/,
    patternError: 'Please follow the format. e.g. http or https://',
    lengthError: 'The length may not be greater than 50.'
};

const email = (index: number): TFormField => ({
    type: 'input',
    id: `emails.${index}.address`,
    name: `emails.${index}.address`,
    label: 'Email',
    emailError: 'Email must be a valid email',
    lengthError: 'The length may not be greater than 50.'
});

const phone = (index: number): TFormField => ({
    label: 'Mobile Number',
    id: `phones.${index}`,
    name: `phones.${index}`,
    pattern: /(^$)|(^\+[1-9]\d{9,14}$)/,
    patternError: 'Invalid mobile number format',
    required: false
});

const addressLine1 = (index: number): TFormField => ({
    id: `addresses.${index}.firstline`,
    name: `addresses.${index}.firstline`,
    type: 'input',
    label: 'Address Line 1',
    lengthError: 'The length may not be greater than 50.'
});

const addressLine2 = (index: number): TFormField => ({
    id: `addresses.${index}.secondline`,
    name: `addresses.${index}.secondline`,
    type: 'input',
    label: 'Address Line 2',
    lengthError: 'The length may not be greater than 50.'
});

const addressCity = (index: number): TFormField => ({
    id: `addresses.${index}.city`,
    name: `addresses.${index}.city`,
    type: 'input',
    label: 'City',
    lengthError: 'The length may not be greater than 50.'
});

const addressState = (index: number): TFormField => ({
    id: `addresses.${index}.state`,
    name: `addresses.${index}.state`,
    type: 'input',
    label: 'State',
    lengthError: 'The length may not be greater than 50.'
});

const addressZipCode = (index: number): TFormField => ({
    id: `addresses.${index}.zipcode`,
    name: `addresses.${index}.zipcode`,
    type: 'input',
    label: 'Zip Code',
    pattern: /(^$)|(^[A-Za-z0-9]+$)/,
    patternError: 'Please use alphanumeric characters only.',
    lengthError: 'The length may not be greater than 10.'
});

export { name, website, email, phone, addressLine1, addressLine2, addressCity, addressState, addressZipCode };
