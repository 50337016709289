import React from 'react';
import { Outlet } from 'react-router-dom';
import { Content } from './DashboardLayout.styled';
import { Navigate } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { useAuthQuery } from '../../hooks/auth';
import { Loading } from '../../components';
import { useRecaptchaQuery as useIntercomQuery } from '../../hooks/recaptcha';

const BundleLayout: React.FC = () => {
    const { isLoading, isError, data: authUser } = useAuthQuery();
    const { data: intercomData, isLoading: isKeyLoading, isError: isKeyError } = useIntercomQuery();

    if (isLoading || isKeyLoading) {
        return <Loading />;
    }

    if (isError || isKeyError) {
        return <span>Error</span>;
    }

    if (!authUser) {
        return <Navigate to={'/login'} replace />;
    }

    return (
        <IntercomProvider appId={intercomData?.intercom_app_id} autoBoot>
            <Content>
                <Outlet />
            </Content>
        </IntercomProvider>
    );
};

export default BundleLayout;
