/* eslint-disable max-len */
import { IGettingStartedOption, IGettingStartOptionParams, IGettingStartItemsParams, ISignupForm, ISetupForm, IOnboadingGettingStatedResponse, ILoginForm, IVerifyMfaForm } from '../type';
import apiClient from './config';

export const getGettingStartItems = async (): Promise<IOnboadingGettingStatedResponse> => {
    const { data } = await apiClient.get(`/onboarding/getting-started-items`);
    return data;
};

export const getGettingStartOption = async (): Promise<IGettingStartedOption> => {
    const { data } = await apiClient.get(`/onboarding/options`);
    if (data.data instanceof Object) {
        return data.data as IGettingStartedOption
    }
    return {};
};

export const updateGettingStartOption = (option: IGettingStartOptionParams) => apiClient.put(`/onboarding/options`, option)

export const updateGettingStartItems = (tile: IGettingStartItemsParams) => apiClient.put(`/onboarding/getting-started-items/${tile.item}`, tile);

export const signup = async (form: ISignupForm) => {
    const response = apiClient.post(`/signup`, form);
    return response
}

export const setupQuestion = async (form: ISetupForm) => {
    const response = apiClient.post(`/onboarding`, form);
    return response
}

export const login = async (form: ILoginForm) => {
    const response = apiClient.post(`/login`, form);
    return response
}

export const verifyMfa = async (form: IVerifyMfaForm) => {
    const response = apiClient.post(`/mfa-auth`, form);
    return response
}