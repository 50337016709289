/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, TextField, DialogContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CustomDialogTitle, Button, Toast } from '../../../../components';
import { Title, Content } from './ConfirmPasswordModal.styled';
import { TConfirmPasswordFieldValues, TConfirmPasswordModalProps, TFormField, TToast } from '../../../../type';
import { useSmsGenerateMutation } from '../../../../hooks/profile';
import { Controller, useFormContext } from 'react-hook-form';
import { verificationCode, password } from '../../../../assets/data/profile_journey';
import { combineErrorMessage } from '../../../../assets/utils/utils';

const ConfirmPasswordModal: React.FC<TConfirmPasswordModalProps> = ({
    open,
    phoneNumber = '',
    title = 'Confirm Password',
    isShowSms = false,
    onClose,
    onConfirm
}) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    const { mutate } = useSmsGenerateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [toastState, setToast] = useState<TToast>({
        title: '',
        description: '',
        open: false,
        variant: 'success'
    });

    useEffect(() => {
        if (isShowSms) {
            mutate(
                { number: `+${phoneNumber}` },
                {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onError: (error: any) => {
                        const { status, data } = error.response;
                        if (status === 422) {
                            const message = combineErrorMessage(data);
                            setToast({ title: 'Error', variant: 'error', description: message, open: true });
                        } else {
                            setToast({ title: 'Error', variant: 'error', description: 'SMS generated went wrong', open: true });
                        }
                    },
                    onSuccess: (response: any) => {
                        const { message } = response.data;
                        setToast({ title: 'Success', variant: 'success', description: message, open: true });
                    }
                }
            );
        }
    }, [isShowSms, mutate, phoneNumber]);

    const handleConfirm = async () => {
        // error - still open
        // success - close modal
        setIsLoading(true);
        const res = await onConfirm();
        setIsLoading(false);
        if (res) onClose();
    };

    const GenerateField = (item: TFormField) => (
        <Controller
            defaultValue={item.name}
            name={item.name as keyof TConfirmPasswordFieldValues}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={field.name}
                    data-cy={`${field.name}-wrapper`}
                    inputProps={{ 'data-cy': `${field.name}-input` }}
                    size="small"
                    variant="outlined"
                    type={item.type}
                    fullWidth
                    required
                    label={item.label}
                    onChange={field.onChange}
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                />
            )}
        />
    );

    return (
        <>
            <Dialog open={open}>
                <CustomDialogTitle onClose={onClose}>
                    <Title>
                        <FontAwesomeIcon icon={regular('lock-alt')} /> {title}
                    </Title>
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Content data-cy="content">
                        {isShowSms && (
                            <section>
                                <span>We have sent a verification code to +{phoneNumber}</span>
                                {GenerateField(verificationCode)}
                            </section>
                        )}
                        <section>
                            <span>Confirm current password to update these settings.</span>
                            {GenerateField(password)}
                        </section>
                    </Content>
                </DialogContent>
                <DialogActions>
                    <Button mode={'Tertiary'} data-cy="cancel" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button mode={'Primary'} data-cy="confirm" loading={isLoading} onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Toast {...toastState} onClose={() => setToast((prev) => ({ ...prev, open: false }))} />
        </>
    );
};

export default ConfirmPasswordModal;