import { TCompanyFormPayload, ICountry, ICompany } from '../type';
import apiClient from './config';

export const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await apiClient.get(`/countries`);

    if (data.data instanceof Array) {
        return data.data as ICountry[];
    }

    return [];
};

export const getCompany = async (id: string): Promise<ICompany> => {
    const { data } = await apiClient.get(`/companies/${id}`);

    return data.data as ICompany;
};

export const createCompany = async (company: TCompanyFormPayload) =>
    await apiClient.post(`/companies`, company, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

export const editCompany = async (company: TCompanyFormPayload) => await apiClient.put(`/companies/${company.uuid}`, company);
