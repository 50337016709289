import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    smsGenerate,
    getQRCode,
    getCountries,
    updateProfile,
    uploadAvatar,
    deleteAvatar,
    changePassword,
    disableMfa,
    enableMfa
} from '../services/profileServices';

export const useSmsGenerateMutation = () => useMutation(smsGenerate);

export const useGetQRCodeQuery = (isMfa: boolean) => useQuery('getQRCode', getQRCode, { enabled: !isMfa });

export const useCountriesQuery = () => useQuery('getCountries', getCountries);

export const useProfileMatation = () => useMutation(updateProfile);

export const useAvatarMatation = () => useMutation(uploadAvatar);

export const useAvatarDeleteMatation = () => useMutation(deleteAvatar);

export const useChangePasswordMatation = () => useMutation(changePassword);

export const useDisableMfaMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(disableMfa, {
        onSuccess: () => {
            queryClient.invalidateQueries('authUser')
        }
    })
}

export const useEnableMfaMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(enableMfa, {
        onSuccess: () => {
            queryClient.invalidateQueries('authUser')
        }
    })
};