/* eslint-disable max-len */
import { ITile } from '../../type';

export const tiles: ITile[] = [
    {

        slug: 'send-an-invite',
        actual_url: '/invites/create'
    },
    {
        slug: 'preview-your-client-portal',
        actual_url: '/invites?order=created_at'
    },
    {
        slug: "create-your-own-invite",
        actual_url: '/invites/create'
    },
    {
        slug: "explore-your-customer-dashboard",
        actual_url: '/invites?order=created_at'
    },
    {
        slug: "add-your-team",
        actual_url: '/settings/team/users'
    },
    {
        slug: "add-your-branding",
        actual_url: '/settings/branding'
    }
];
