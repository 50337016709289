import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { AlertBox, Button } from '../../components';
import { Header, Title, Link, Wrapper, BtnWrapper } from './Login.styled';
import { loginSchema } from '../../assets/utils/sign_up_schema';
import { email, password } from '../../assets/data/sign_up_journey';
import { useLoginMutation } from '../../hooks/onboarding';
import { ILoginForm } from '../../type';
import { useSearchParams } from 'react-router-dom';

const Login: React.FC = () => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<ILoginForm>({
        defaultValues: {
            email: '',
            password: ''
        },
        resolver: yupResolver(loginSchema)
    });

    const [searchParams] = useSearchParams();
    const isExpired = Boolean(searchParams.get('expired'));
    const [isShow, setIsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string[]>([]);

    const { mutate } = useLoginMutation();

    useEffect(() => {
        if (isExpired) {
            setIsShow(true);
            setMessage(['We did not detect any activity, please try signing in again.']);
        }
    }, [isExpired]);

    const onSubmit = (form) => {
        setIsLoading(true);

        mutate(form, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 401 || status === 429) {
                    setMessage([data.message]);
                } else {
                    setMessage(['We’re unable to log you in. Please refresh the page and try logging in again.']);
                }
                setIsShow(true);
                setIsLoading(false);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess: (response: any) => {
                const { data, status } = response;
                if (status === 200) {
                    window.location.href = data.redirect;
                } else if (status === 202) {
                    // Credentials are valid but the MFA is enabled
                    window.location.href = '/login/mfa';
                }
            }
        });
    };

    const GenerateForm = (item) => {
        return (
            <Controller
                name={item.name}
                control={control}
                render={({ field }) => (
                    <>
                        <TextField
                            {...field}
                            id={field.name}
                            data-cy={`${field.name}-wrapper`}
                            inputProps={{
                                'data-cy': `${field.name}-input`
                            }}
                            size="small"
                            fullWidth
                            required
                            label={item.label}
                            type={item.type}
                            error={Boolean(errors[field.name])}
                            helperText={errors[field.name]?.message}
                        />
                    </>
                )}
            />
        );
    };

    return (
        <>
            <LoginLayout>
                <Header>
                    <Title>Login</Title>
                    <Link data-cy="signup-link">
                        Don’t have an account?{' '}
                        <a href="/sign-up" target="_self">
                            Sign up here.
                        </a>
                    </Link>
                </Header>
                {isShow && <AlertBox data-cy="signup-alert-box" mode="Error" messages={message}></AlertBox>}
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        {GenerateForm(email)}
                        {GenerateForm(password)}
                        <BtnWrapper>
                            <Button data-cy="login" mode={'Primary'} loading={isLoading}>
                                Login
                            </Button>
                        </BtnWrapper>
                    </Wrapper>
                    <Link data-cy="forgot-passwork-link">
                        <a href="/password/reset">Forgot your password?</a>
                    </Link>
                </form>
                {/* <Footer>
                    <Divider>or</Divider>
                    <Button mode={'Secondary'} fullWidth>
                        Login with single sign on
                    </Button>
                </Footer> */}
            </LoginLayout>
        </>
    );
};
export default Login;