import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 20px;
    > p {
        display: flex;
        margin: 0;
        color: ${theme.med_gray_1};
        > svg {
            color: ${theme.primary};
            padding-right: 5px;
        }
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_24};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    color: ${theme.med_gray_1};
`;

export const LoginLink = styled.p`
    > a {
        margin-left: 5px;
        color: ${theme.secondary};
        cursor: pointer;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 20px;
`;

export const BtnWrapper = styled.div`
    margin-top: 25px;
`;

export const Footer = styled.div`
    color: ${theme.dark_gray_2};
`;

export const Error = styled.span`
    display: block;
    color: ${theme.red};
    margin-top: 4px;
`;

export const TermContent = styled.span`
    font-size: ${theme.font_size_13};

    a {
        text-decoration: none;
        color: ${theme.secondary};
    }
`;

export const Term = styled.div`
    display: flex;
    flex-direction: column;

    p {
        font-size: ${theme.font_size_12};
        color: ${theme.red};
        margin: 0;
    }

    svg {
        width: 18px;
        color: ${theme.secondary};
    }
`;
