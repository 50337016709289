import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TIconColorConditionProps = {
    condition?: boolean;
};

export const IconBox = styled.div<TIconColorConditionProps>`
    color: ${(props) => (props.condition ? theme.green : theme.dark_gray_2)};
    font-weight: ${theme.font_weight_regular};
    line-height: 18px;
    > span {
        font-size: ${theme.font_size_12};
        margin-left: 5px;
        vertical-align: middle;
    }
    > svg {
        vertical-align: middle;
        width: 13px;
        font-size: ${theme.font_size_13};
    }
`;
