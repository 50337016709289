import { has, isEmpty, omit } from 'lodash';
import { IAccount, IUser, TContactFormValues, TContactFormPayload, TPhone, TAddress, TCompanyFormValues } from '../../type';
import { getCodeByCountryIso2 } from '../../assets/data/country';
import { pollyfillNullWithEmptyStrings } from './utils';

const formatProfileData = (data: IUser) => {
    if (!has(data, 'email') || !has(data, 'phone') || !has(data, 'company') || !has(data, 'address')) return;
    const { address, phone, company: { country_iso2 }, email, firstname, lastname, avatar, is_mfa_enabled } = data;
    const computed_national_number = getCodeByCountryIso2(phone.country_iso2 || country_iso2) || '61';
    return {
        email: { address: email?.address || "" },
        phone: {
            // If the country_iso2 is set, it will use the default value
            // otherwise it still use country_iso2 from company object
            ...phone,
            country_iso2: phone.country_iso2 ? phone.country_iso2 : country_iso2 ? country_iso2 : 'AU',
            // if there is no country_iso2, number will user number filed otherwise it uses national_number field
            number: !isEmpty(phone.country_iso2) ? phone.national_number : phone.number,
            national_number: computed_national_number
        },
        // handle response address data value is null, textfield component will throw error
        address: {
            city: address?.city || "",
            country_id: address?.country_id,
            firstline: address?.firstline || "",
            secondline: address?.secondline || "",
            state: address?.state || "",
            zipcode: address?.zipcode || ""
        },
        firstname: firstname || "",
        lastname: lastname || "",
        avatar: avatar,
        is_mfa_enabled: is_mfa_enabled,
        password: '',
        sms_verification_code: ''
    }
}

const formatAccountData = (data: IAccount) => {
    if (!data || !has(data, 'address')) return null;

    const { address, ...account } = data;

    return {
        ...data,
        name: account.name || '',
        timezone_id: account.timezone_id || '',
        website: account.website || '',
        address: {
            city: address.city || '',
            country_id: address.country_id || '',
            firstline: address.firstline || '',
            secondline: address.secondline || '',
            state: address.state || '',
            zipcode: address.zipcode || ''
        }
    };
};

const formatContactResponse = (data: TContactFormValues, defaultValues: TContactFormValues) => {
    const c = pollyfillNullWithEmptyStrings(omit(data, ['created_at', 'updated_at', 'user', 'permissions']), ['firstname', 'lastname']);

    return {
        ...c,
        company: c.company.uuid ? c.company : defaultValues.company,
        phones: c.phones.length
            ? c.phones.map((item: TPhone) => ({
                ...pollyfillNullWithEmptyStrings(item, ['country_iso2', 'number', 'national_number']),
                national_number: item.country_iso2 ? getCodeByCountryIso2(item.country_iso2) : '61',
                number: !item.country_iso2 ? item.number : item.national_number
            }))
            : defaultValues.phones,
        addresses: c.addresses.length
            ? c.addresses.map((item: TAddress) =>
                pollyfillNullWithEmptyStrings(item, ['city', 'firstline', 'secondline', 'state', 'zipcode'])
            )
            : defaultValues.addresses
    };
};

const formatContactRequest = (data: TContactFormValues) => {
    const { emails, company, phones, addresses, ...rest } = data;

    const phoneExists = phones.filter((phone) => phone.number);
    const addressExists = addresses.filter((address) => address.firstline);

    const payload: TContactFormPayload = {
        ...rest,
        company_uuid: company?.uuid,
        emails: emails.filter((email) => email.address),
        phones: phoneExists.length
            ? phoneExists.map((item) => ({
                ...item,
                national_number: item.number,
                number: `+${getCodeByCountryIso2(item.country_iso2)}${item.number}`
            }))
            : [],
        addresses: addressExists.length ? addressExists : []
    };

    return payload;
};

const formatCompanyResponse = (data: TCompanyFormValues, defaultValues: TCompanyFormValues) => {
    const c = pollyfillNullWithEmptyStrings(
        omit(data, ['created_at', 'updated_at', 'company_user', 'permissions', 'invite_count', 'people_count']),
        ['website']
    );

    return {
        ...c,
        phones: c.phones?.length
            ? c.phones.map((item: TPhone) => ({
                ...pollyfillNullWithEmptyStrings(item, ['country_iso2', 'number', 'national_number']),
                national_number: item.country_iso2 ? getCodeByCountryIso2(item.country_iso2) : '61',
                number: item.country_iso2 ? item.number : item.national_number
            }))
            : defaultValues.phones,
        addresses: c.addresses?.length
            ? c.addresses.map((item: TAddress) =>
                pollyfillNullWithEmptyStrings(item, ['city', 'firstline', 'secondline', 'state', 'zipcode'])
            )
            : defaultValues.addresses
    };
};

const formatCompanyRequest = (data: TCompanyFormValues) => {
    const { emails, phones, addresses, ...rest } = data;

    const phoneExists = phones.filter((phone) => phone.number);
    const addressExists = addresses.filter((address) => address.firstline);
    const payload: TCompanyFormValues = {
        ...rest,
        emails: emails.filter((email) => email.address),
        phones: phoneExists.length
            ? phoneExists.map((item) => ({
                ...item,
                national_number: item.number,
                number: `+${getCodeByCountryIso2(item.country_iso2)}${item.number}`
            }))
            : [],
        addresses: addressExists.length ? addressExists : []
    };

    return payload;
};

export { formatProfileData, formatAccountData, formatContactResponse, formatContactRequest, formatCompanyRequest, formatCompanyResponse };
