import { useQuery, useMutation } from 'react-query';
import { getCompany, getCountries, createCompany, editCompany } from '../services/companyServices';

export const useCompanyQuery = (id: string) => useQuery(['getCompany', id], () => getCompany(id), { enabled: !!id });

export const useCountriesQuery = () => useQuery('getCountries', getCountries);

export const useCreateCompanyMutation = () => useMutation('createCompany', createCompany);

export const useEditCompanyMutation = () => useMutation('editCompany', editCompany);
