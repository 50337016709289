import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import Stars from '../../assets/images/bg-pattern.svg';

export const Container = styled.div`
    width: 100%;
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: ${theme.dark};

    @media ${theme.device.tablet} {
        flex-direction: column;
    }
`;

export const Star = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    max-width: 1359px;
    height: 535px;
    background-color: transparent;
    background-image: url(${Stars});
    background-repeat: no-repeat;
    @media ${theme.device.tabletL} {
        left: 20%;
    }
    @media ${theme.device.tablet} {
        display: none;
    }
`;

export const LeftWrapper = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    @media ${theme.device.tablet} {
        width: 100%;
        height: 150px;
    }

    @media ${theme.device.tablet} {
        height: 120px;
    }
`;

export const ImgWrapper = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    z-index: 1;
`;
export const LogoImg = styled.img`
    width: 243px;
`;

export const OnboardingImg = styled.img`
    width: 578px;
    @media ${theme.device.desktop} {
        width: 450px;
    }
    @media ${theme.device.tabletL} {
        width: 394px;
    }
    @media ${theme.device.tablet} {
        display: none;
    }
`;

export const FreeiumDesc = styled.h1`
    font-size: 24px;
    color: ${theme.white};
    text-align: center;
    font-weight: 500;
    @media ${theme.device.tablet} {
        display: none;
    }
`;

export const Content = styled.div`
    width: 695px;
    background-color: ${theme.white};
    z-index: 1;

    @media ${theme.device.tabletL} {
        width: 512px;
    }
    @media ${theme.device.tablet} {
        width: 100%;
        height: 100%;
    }
`;

export const Main = styled.div`
    width: 350px;
    transform: translateY(160px);
    margin: 0 auto;
    font-weight: ${theme.font_weight_regular};
    font-size: ${theme.font_size_13};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 35px;

    @media ${theme.device.tablet} {
        transform: translateY(50px);
    }
    @media ${theme.device.mobile} {
        width: 300px;
    }
`;
