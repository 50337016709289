import React, { useEffect } from 'react';
import { TTabItem } from '../../type';
import { StyledTab, StyledTabs } from './Tabs.styled';

type TTabsProps = {
  tabList: TTabItem[];
  activeTab?: string;
  onClick: (id: string) => void;
};

const Tabs: React.FC<TTabsProps> = ({ tabList, activeTab = tabList[0].id, onClick }) => {
  useEffect(() => {
    if (!activeTab || !tabList?.length) return;

    const isActiveTabExisting = tabList.some(tab => tab.id === activeTab);

    if (!isActiveTabExisting) throw new Error('Invalid parameter: activeTab');
  }, [activeTab, tabList]);

  return (
    <StyledTabs>
      {tabList.map(({ id, title }: TTabItem) => (
        <StyledTab
          key={id}
          active={id === activeTab}
          onClick={() => onClick(id)}
          data-cy={`TAB-${title.toLowerCase()}`}
        >
          {title}
        </StyledTab>
      ))}
    </StyledTabs>
  )
};

export default Tabs;
