import { getCountry } from '../../assets/data/country';
import React, { useMemo } from 'react';
import { LabelContainer, StyledBox, StyledImg } from './FormPhone.styled';
import { isNil } from 'lodash';

const DisplayValue = (countryCode: string) => {
  const country = useMemo(() => {
    let newCountry = getCountry(countryCode);

    if (isNil(newCountry)) {
      newCountry = {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
        order: 0
      };
    }

    return newCountry;
  }, [countryCode]);

  return (
    <StyledBox>
      <StyledImg
        loading="lazy"
        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
        alt={`Flag of ${country.label}`}
      />
      <LabelContainer>+{country.phone}</LabelContainer>
    </StyledBox>
  );
};

export default DisplayValue;
