import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, TextField, DialogContent } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGetQRCodeQuery, useEnableMfaMutation, useDisableMfaMutation } from '../../../../hooks/profile';
import { CustomDialogTitle, Button, Toast } from '../../../../components';
import { IEnableAuthConfignModal, TAuthConfigModalProps, TFormField, TToast } from '../../../../type';
import {
    Title,
    paperProps,
    Code,
    Steps,
    StepText,
    ImageDevice,
    ImageQRCode,
    Content,
    paperPropsaSm
} from './AuthConfigurationModal.styled';
import { authConfigSchema } from '../../../../assets/utils/change_password_schema';
import { authCode } from '../../../../assets/data/change_password';

const defaultValues: IEnableAuthConfignModal = {
    code: ''
};

const AuthConfigurationModal: React.FC<TAuthConfigModalProps> = ({
    open,
    onClose,
    title = 'Configure Authenticator',
    bthText = 'Submit',
    isMfa = false
}) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(authConfigSchema),
        context: { isMfa: isMfa }
    });

    const { data: qrCode, isError } = useGetQRCodeQuery(isMfa);
    const { qr_code_image, qr_code_text } = qrCode || { qr_code_image: '', qr_code_text: '' };
    const { mutate: enableMfa, isLoading: isSubmitting } = useEnableMfaMutation();
    const { mutate: disableMfa, isLoading: isDisableMfaSubmitting } = useDisableMfaMutation();

    const [toastState, setToast] = useState<TToast>({
        title: '',
        description: '',
        open: false,
        variant: 'success'
    });

    const handleEnableMfa = (form: IEnableAuthConfignModal) => {
        enableMfa(form, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 422) {
                    setToast({ title: 'Error', variant: 'error', description: data.message, open: true });
                } else {
                    setToast({ title: 'Error', variant: 'error', description: 'Two-step authentication went wrong.', open: true });
                }
            },
            onSuccess: () => {
                setToast({ title: 'Success', variant: 'success', description: 'Two-step authentication has been enabled', open: true });
                onClose();
            }
        });
    };

    const handleDisableMfa = () => {
        disableMfa(null, {
            onError: () => {
                setToast({ title: 'Error', variant: 'error', description: 'Two-step authentication disabled went wrong.', open: true });
            },
            onSuccess: () => {
                setToast({ title: 'Success', variant: 'success', description: 'Two-step authentication has been disabled', open: true });
                onClose();
            }
        });
    };

    const handleClose = () => {
        reset(defaultValues);
        onClose();
    };

    const onSubmit: SubmitHandler<IEnableAuthConfignModal> = (form) => {
        if (!isMfa) {
            handleEnableMfa(form);
        } else {
            handleDisableMfa();
        }
    };

    const GenerateField = (item: TFormField) => (
        <>
            <Controller
                name={item.name as keyof IEnableAuthConfignModal}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={field.name}
                        data-cy={`${field.name}-wrapper`}
                        inputProps={{ 'data-cy': `${field.name}-input` }}
                        size="small"
                        variant="outlined"
                        type={item.type}
                        fullWidth
                        required
                        label={item.label}
                        onChange={field.onChange}
                        error={Boolean(errors[field.name])}
                        helperText={errors[field.name]?.message}
                    />
                )}
            />
        </>
    );

    if (isError) {
        return <span>QRCode Error</span>;
    }

    return (
        <>
            <Dialog open={open} PaperProps={isMfa ? paperPropsaSm : paperProps}>
                <CustomDialogTitle onClose={handleClose}>
                    <Title>
                        <FontAwesomeIcon icon={light('wrench')} />
                        {title}
                    </Title>
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Content data-cy="content">
                        {!isMfa ? (
                            <Steps>
                                <section>
                                    <StepText>1. Download a two-factor authenticator app on your mobile device.</StepText>
                                    <ImageDevice />
                                </section>
                                <section>
                                    <StepText>2. Open the app, then scan the QR code or manually enter the code below.</StepText>
                                    <Code title="QR Code Number">{qr_code_text}</Code>
                                    <ImageQRCode title="QR Code" src={qr_code_image} />
                                </section>
                                <section>
                                    <StepText>
                                        3. Enter the current six-digit numerical passcode from the two-factor authenticator app to verify
                                        your device is properly configured.
                                    </StepText>
                                    {GenerateField(authCode)}
                                </section>
                            </Steps>
                        ) : (
                            <div>Are you sure you want to disable this authentication?</div>
                        )}
                    </Content>
                </DialogContent>
                <DialogActions>
                    <Button mode={'Tertiary'} data-cy="cancel" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        mode={'Primary'}
                        data-cy="submit-button"
                        loading={isSubmitting || isDisableMfaSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        {bthText}
                    </Button>
                </DialogActions>
            </Dialog>
            <Toast {...toastState} onClose={() => setToast((prev) => ({ ...prev, open: false }))} />
        </>
    );
};

export default AuthConfigurationModal;