import * as Yup from 'yup';
import { email, password, verificationCode } from '../data/sign_up_journey';

// yup validation
const validationMap = {
    email: Yup.string().email(email.emailError).required(email.requiredError),
    password: Yup.string().required(password.requiredError).matches(password.combinationPattern, password.patternError),
    isNotRobot: Yup.string().required('Please click the checkbox above'),
    // verificationCode here refers to onboarding MFA setting
    verificationCode: Yup.string().required(verificationCode.requiredError)
};

const signupSchema = Yup.object().shape({
    email: validationMap.email,
    password: validationMap.password,
    isNotRobot: Yup.string().when('$isShowRecaptcha', (isShowRecaptcha) =>
        isShowRecaptcha ? validationMap.isNotRobot : Yup.string().notRequired()
    ),
    terms_and_conditions: Yup.boolean()
        .oneOf([true], 'Please accept the terms and privacy policy')
        .required('Please accept the terms and privacy policy')
});

const resetSchema = Yup.object().shape({
    email: validationMap.email
});

const loginSchema = Yup.object().shape({
    email: validationMap.email,
    password: Yup.string().required(password.requiredError)
});

const verificationCodeSchema = Yup.object().shape({
    code: validationMap.verificationCode
});

export { signupSchema, resetSchema, loginSchema, verificationCodeSchema };
