import { IRecaptchaKeys } from '../type';
import apiClient from './config';

export const getRecaptchaKeys = async (): Promise<IRecaptchaKeys> => {
    const { data } = await apiClient.get(`/keys`);
    if ('recaptcha_site_key' in data.data) {
        return data.data as IRecaptchaKeys;
    }

    return null;
};
