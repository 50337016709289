/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
// import { Dialog, DialogActions, DialogContent, DialogContentText, Autocomplete } from '@mui/material';
// import {
//     CustomDialogTitle,
//     Button,
//     ArrowButton,
//     ProgressBar,
//     FormPhone,
//     Tile,
//     CreateInviteOption,
//     CreateInviteDialog,
//     TablePagination,
//     Badge,
//     AlertBox,
//     ProfileInfo,
//     InfoCard,
//     Tooltip,
//     TableOperation,
//     AutocompleteSearch,
//     SearchBar,
//     Toast
// } from '../components';
// import { changeEmail } from '../redux/slices/profile';
// import { TStore } from '../redux/store';

// import { Divider, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
// import { String } from 'lodash';
// import { infoCards } from '../assets/data/info_card';
// import { tableOperation } from '../assets/mockData/table_operation';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { top100Films } from '../assets/mockData/autocomplete';
// import { Wrapper } from './Sample.styled';
// import { ChangePasswordModal, ConfirmPasswordModal, AuthConfigurationModal } from './Profile/components';
// import { tabList } from '../assets/mockData/tabs';

const Sample: React.FC = () => {
    // const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    // const [open2, setOpen2] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('account');

    // const dispatch = useDispatch();
    // const { email } = useSelector((state: TStore) => state.profileReducer);

    // const handleEmailChange = () => {
    //     dispatch(changeEmail({ email: 'newEmail@gmail.com' }));
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleChange = (code) => {
    //     // eslint-disable-next-line
    //     console.log('code====', code);
    // };

    // const handleRedirect = (url) => {
    //     window.location.href = `${url}`;
    // };

    const generateCookie = () => {
        // eslint-disable-next-line max-len
        document.cookie = `_ga=GA1.3.1401777263.1643927586; _fbp=fb.1.1643927652256.1098172543; mf_user=03835beacef381cf1b1df03cdf19d3e7|; _clck=1ctat0d|1|eyp|0; __hstc=82446217.b00b9dc731e43787ed5de12641457d39.1643935367250.1643935367250.1643935367250.1; hubspotutk=b00b9dc731e43787ed5de12641457d39; __hssrc=1; _gcl_au=1.1.1117843580.1643935369; intercom-id-mdjrcrra=07d78615-c0d3-42dc-a898-b2eae8fc892f; _uetvid=68d53a40854111ecb3d4a313f21c0ab2; _hp2_id.3202370161=%7B%22userId%22%3A%225227870847289852%22%2C%22pageviewId%22%3A%223686949696031213%22%2C%22sessionId%22%3A%226985998873170103%22%2C%22identity%22%3Anull%2C%22trackerVersion%22%3A%224.0%22%7D; mf_63f9b0fd-a626-4d0a-b87f-e75a2a7993bb=6a9ebc595ef88b017060004079a739c2|02044468f860819494dec02e267e644aff40523d.-3183364025.1643935364174$0204183338f0d86c7942218734ada546ce153a97.-4382006712.1643935398338|1643935695090|1311612643_-13100463362|2|||0|17.58|61.55899; _hjSessionUser_2845388=eyJpZCI6ImQ5NWM5ZmVkLTRlNWQtNTRjOS05M2RmLWY3NDQ0ZGM5MGExNSIsImNyZWF0ZWQiOjE2NDc0MTg0MDYxMTksImV4aXN0aW5nIjp0cnVlfQ==; _gid=GA1.2.1651359229.1648624195; _ga=GA1.1.1401777263.1643927586; _ga_HZLJ81K79M=GS1.1.1648624195.31.0.1648624204.0; _ga_1JV4W590XM=GS1.1.1648624195.31.0.1648624204.0; XSRF-TOKEN=eyJpdiI6IkE0amZHazhWay84RVp5QXNybXpZVGc9PSIsInZhbHVlIjoiZTlzVVhQS2ptNzJabWFRMnEyb0hITkRiVVNJQld3YkpaRTNhclF1cmFvUWVtbVBRVXpuQVdBWkFPR3FxYVQwK2ZraEZDVVh3YTBrd3Q5K0pXT3RuNncwTDdPbFFIRDZJRWZFQnhDUEEzN2xOaHRROVpVd3BLWk9QeStBQU9sL3kiLCJtYWMiOiJhNDlkYTY5MGZjOGIyODUwMmViMTZkOTc0MDc1NTlhNjkxOTU4MDg3ZTc2MDVlZjgyOTc1NmEzMzdiYmExZmEyIiwidGFnIjoiIn0%3D; fileinvite_session=eyJpdiI6IlZmTWxKZTFNMkE0U1JEZ2NhUWJ0TWc9PSIsInZhbHVlIjoiYmsrejBJTTlnZElNSzRTVi9wdkt2M21idExZZnlyRFBvLzJVYjVPVzJ0Z1VVRlRiR0NhbXRVUUMrSkxWNmladEpsNGJLckIyM28zdlNpVU8vOVRIS0NvTzhPbitQMCtIMG4wR2k0T2ZybkR5RTBvYllPbncreTh3dS9Oc3ZwQ0IiLCJtYWMiOiI4MWY5NzQ1MWU1NzdkZjQ5N2RiMTg3NGRmM2Y1OWM3OThhNWJlOWIzYzdiY2Y3MGU5ZjNlYTFkMTcwODRmNTQxIiwidGFnIjoiIn0%3D`;
    };

    // const sendSampleRequest = async () => {
    //     const url = `http://localhost:5000/api/health`;
    //     // const axiosConfig = {
    //     //     headers: {
    //     //         'content-Type': 'application/json',
    //     //         Accept: '/',
    //     //         'Cache-Control': 'no-cache',
    //     //         Cookie: document.cookie
    //     //     },
    //     //     credentials: 'same-origin'
    //     // };
    //     // axios.defaults.withCredentials = true;
    //     const res = await axios.get(url, {
    //         withCredentials: true
    //     });
    //     console.log(`Response: `, res);
    // };

    const { handleSubmit, control } = useForm({
        defaultValues: {
            cookies: ''
        }
    });

    // const setCookies = (cookieStr: string) => {
    //     const arr = cookieStr.split(';');
    //     arr.forEach((i) => (document.cookie = i));
    // };

    // const onchange = ({ newPageSize, currPage }) => {
    //     console.log(`pageSize is `, newPageSize), console.log(`currPage is `, currPage);
    // };

    // const handleClick = (key) => {
    //     console.log(`you click ${key} `);
    // };

    // const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    //     // eslint-disable-next-line
    //     console.log('search value ==>', event.target.value);
    //     setSearchValue(event.target.value);
    // };

    // const [inputValue, setInputValue] = React.useState('234');
    // const [value, setValue] = React.useState<string | null>(null);
    // const [searchValue, setSearchValue] = React.useState<string>('');

    return (
        <>
            {/* <Tile
                title={'Send an invite'}
                description={'Send an invite using templates'}
                slug={'team'}
                action_url="#"
                completion={{ actioned_at: null }}
            />

            <hr />

            <Badge mode={'Primary'}>Secondary</Badge>
            <hr />

            <br />
            <Badge mode={'Secondary'} type={'outlined'}>
                Secondary
            </Badge>

            <hr /> */}
            {/* <TablePagination total={34} currentPage={2} onPageChange={onchange} /> */}
            {/* <FormPhone onChange={handleChange} /> */}
            {/* <Button onClick={generateCookie}>Generate sample cookie</Button>
            <Button onClick={sendSampleRequest}>Test API Call</Button>
            <AlertBox
                messages={[`Hello.<a href="/login"> Login here</a> or <a href="/resetpassword">reset your password</Link>`, 'I am here']}
            /> */}
            {/* <ArrowButton mode={'Secondary'} onClick={sendSampleRequest} text={'hello'} direction={'left'} loading></ArrowButton>
            <ArrowButton onClick={sendSampleRequest} text={'hello'} direction={'left'}></ArrowButton>
            <Button onClick={sendSampleRequest}>Test API Call</Button>
            <Button onClick={sendSampleRequest} loading>
                I am a long button , testing loading
            </Button>
            <br />
            <Button onClick={sendSampleRequest} loading>
                Test API Call
            </Button> */}
            {/* <ProfileInfo image={'https://robohash.org/honey?set=set1'} name={'jane wang'} email={'wangjing27@outlook.com'} />
            <ArrowButton mode={'Secondary'} onClick={sendSampleRequest} text={'hello'} direction={'left'}></ArrowButton>
            {infoCards.map((item, index) => (
                <InfoCard key={index} color={item.color} number={item.number} label={item.label} icon={item.icon}></InfoCard>
            ))} */}
            {/* <br />
            <Tooltip message={'hello<br/>how are you '} component={<FontAwesomeIcon icon={solid('question-circle')} />} />
            <br /> */}
            {/* <Tooltip disabled message={'hello<br/>how are you '} component={<FontAwesomeIcon icon={solid('question-circle')} />} /> */}

            {/* <TableOperation menus={tableOperation} onIconClick={handleClick} /> */}
            <br />
            {/* <AutocompleteSearch
                id="demo"
                value={value}
                options={top100Films}
                onChange={(event: any, newValue: string | null) => {
                    setValue(newValue);
                }}
                getOptionLabel={(option: any) => option.label}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                placeholder="Search..."
            /> */}
            {/* non-fixed search bar */}
            {/* <p> non-fixed search bar</p>
            <Wrapper>
                <SearchBar
                    id="search-bar"
                    onChange={handleSearch}
                    placeholder="Search contacts"
                    value={searchValue}
                    loading
                    isMobileFixed={false}
                    inputProps={{
                        'data-cy': 'search-input'
                    }}
                />
            </Wrapper> */}
            {/* fixed search bar */}
            {/* <p> fixed search bar</p>
            <Wrapper>
                <SearchBar
                    id="search-bar"
                    onChange={handleSearch}
                    placeholder="Search contacts"
                    value={searchValue}
                    loading
                    isMobileFixed={true}
                    inputProps={{
                        'data-cy': 'search-input'
                    }}
                />
            </Wrapper> */}
            {/* <ConfirmPasswordModal
                open={true}
                isShowSms={true}
                phoneNumber={'1232'}
                onClose={() => console.log(456)}
                onConfirm={() => console.log(456)}
                onChange={({ currentPassword, verificationCode }) => {
                    console.log(currentPassword, verificationCode, 'outside');
                }}></ConfirmPasswordModal> */}
            {/* <AuthConfigurationModal open={isOpen} onClose={() => setIsOpen(false)} /> */}
            {/* <Toast
                title="Info"
                description="You file has been queued for upload"
                open={open}
                onClose={() => setOpen(false)}
            /> */}
            {/* <Tabs tabList={tabList} activeTab={activeTab} onClick={id => setActiveTab(id)} /> */}
        </>
    );
};

export default Sample;
