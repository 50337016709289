import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sample from './pages/samples/Sample';
import Table from './pages/samples/table/Table';
import Signup from './pages/Signup/Signup';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import { EmptyLayout, DashboardLayout, BundleLayout } from './assets/layouts';
import SetupQuestions from './pages/SetupQuestion/SetupQuestions';
import OnboardingLoading from './pages/OnboardingLoading/OnboardingLoading';
import GetStart from './pages/GetStart/GetStart';
import Login from './pages/Login/Login';
import Invite from './pages/Invite/Invite';
import VerifyMfa from './pages/VerifyMfa/VerifyMfa';
import Profile from './pages/Profile/Profile';
import Account from './pages/Account/Account';
import TrialExpired from './pages/TrialExpired/TrialExpired';
import { Error500 } from './pages/Error';
import ContactForm from './pages/Contacts/Form/Form';
import CompanyForm from './pages/Company/CompanyForm/CompanyForm';

const Routers = () => {
    // DashboardLayout only used in local for testing. In production all pages will use EmptyLayout instead
    const MainLayout = process.env.NODE_ENV === 'production' ? BundleLayout : DashboardLayout;

    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Sample />} />
                <Route path="/loading" element={<OnboardingLoading />} />
                <Route path="/onboarding/getting-started" element={<GetStart />} />
                <Route path="/settings/accounts" element={<Account />} />
                <Route path="/contacts/add" element={<ContactForm />} />
                <Route path="/contacts/edit/:id" element={<ContactForm />} />
                <Route path="/companies/add" element={<CompanyForm />} />
                <Route path="/companies/edit/:id" element={<CompanyForm />} />
                <Route path="/settings/profile" element={<Profile />} />
            </Route>

            <Route path="/" element={<EmptyLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/sign-up" element={<Signup />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/login/mfa" element={<VerifyMfa />} />
                <Route path="/onboarding/setup" element={<SetupQuestions />} />
                <Route path="/trial-expired" element={<TrialExpired />} />
                <Route path="/error" element={<Error500 />} />
                <Route path="/api" element={<Invite />} />
                {process.env.NODE_ENV !== 'production' && (
                    <Route path="/sample">
                        <Route index element={<Sample />} />
                        <Route path="table" element={<Table />} />
                    </Route>
                )}
            </Route>
        </Routes>
    );
};

export default Routers;
