import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import DialogTitle from '@mui/material/DialogTitle';

export const StyledCloseButton = styled.button`
    position: absolute;
    right: -11px;
    top: -6px;
    border: 0px;
    background-color: ${theme.white};
    border: 1px solid ${theme.light_gray_1};
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
        color: ${theme.med_gray_1};
    }

    &:hover {
        background-color: ${theme.primary};
        border: 1px solid ${theme.primary};
        svg {
            color: ${theme.white};
        }
    }
`;

export const StyledDialogTitle = styled(DialogTitle)`
    padding: 16px 30px;
    font-size: ${theme.font_size_20};
    font-weight: ${theme.font_weight_semiBold};
    font-family: ${theme.font_family_montserrat};
    color: ${theme.med_gray_1};
`;
