import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isNil, result } from 'lodash';
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading, CustomBreadcrumbs, Tabs, Button, Toast } from '../../components';
import { Container, Title, TitleExt, Form, FieldSet, Legend, StyledTwoColumns, StyledFormFooter } from './Account.styled';
import { accountBreadcrumbs } from '../../assets/data/breadcrumbs';
import { useCountriesQuery, useTimezonesQuery, useAccountsQuery, useAccountMutation } from '../../hooks/account';
import { useAuthQuery } from '../../hooks/auth';
import { accountSchema } from '../../assets/utils/account_schema';
import {
    companyName,
    website,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressZipCode,
    timezone,
    tabs
} from '../../assets/data/account';
import { TFormField, IAccount, TTabItem, TToast } from '../../type';
import { formatAccountData } from '../../assets/utils/formDataFormat';

const defaultValues = {
    name: '',
    timezone_id: '',
    website: '',
    address: {
        city: '',
        country_id: '',
        firstline: '',
        secondline: '',
        state: '',
        zipcode: ''
    }
};

const Account: React.FC = () => {
    const { data: accounts, isLoading: isAccountsLoading, isError: isAccountsError } = useAccountsQuery();
    const { data: countries, isLoading: isCountriesLoading, isError: isCountriesError } = useCountriesQuery();
    const { data: timezones, isLoading: isTimezonesLoading, isError: isTimezonesError } = useTimezonesQuery();
    const { mutate: saveAccount, isLoading: isSubmitting } = useAccountMutation();
    const { data: auth } = useAuthQuery();
    const loc = useLocation();
    const [toast, setToast] = useState<TToast>({
        open: false,
        variant: 'success',
        description: '',
        title: ''
    });

    const allowedTabs = useMemo(() => {
        if (!auth) return [];

        return tabs.reduce((acc, cur) => {
            if (auth.current_permissions[cur.accessKey]) {
                return [
                    ...acc,
                    {
                        id: cur.id,
                        title: cur.title
                    }
                ];
            }

            return acc as TTabItem[];
        }, []);
    }, [auth]);

    const {
        control,
        reset,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm<IAccount>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(accountSchema)
    });

    useEffect(() => {
        if (!isNil(accounts)) {
            reset(formatAccountData(accounts));
        }
    }, [accounts, reset]);

    const onSuccess = () =>
        setToast({
            title: 'Success',
            variant: 'success',
            description: 'Company settings has been updated',
            open: true
        });

    const handleToastClose = () => setToast((prev) => ({ ...prev, open: false }));

    const handleTabClick = (path: string) => {
        // NOTE: This is temporary
        // It is necessary in this case to force a page reload from react to load
        // the page from php
        window.location.href = window.location.origin + path;
    };

    const onSubmit = () => {
        const values = getValues();

        saveAccount(values, {
            onSuccess,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (err: any) => {
                // eslint-disable-line
                // display all the errors
                const errorKeys = Object.keys(err.response.data);
                const errors = errorKeys.map((key) => err.response.data[key].details.join('\n')).join('\n');

                setToast({
                    title: 'Error',
                    variant: 'error',
                    description: errors,
                    open: true
                });
            }
        });
    };

    const InputField = (item: TFormField) => (
        <Controller
            name={item.name as keyof IAccount}
            control={control}
            rules={{ required: item.required }}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={field.name}
                    data-cy={`${field.name}-wrapper`}
                    inputProps={{ 'data-cy': `${field.name}-input` }}
                    size="small"
                    variant="outlined"
                    type={item.type}
                    fullWidth
                    label={item.label}
                    required={item.required}
                    onChange={field.onChange}
                    error={!!result(errors, field.name)}
                    helperText={result(errors, `${field.name}.message`)}
                />
            )}
        />
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const SelectField = (item: TFormField, type: 'timezones' | 'countries', options: any) => {
        return (
            <Controller
                name={item.name as keyof IAccount}
                control={control}
                render={({ field }) => (
                    <FormControl required={item.required} fullWidth>
                        <InputLabel id={field.name}>{item.label}</InputLabel>
                        <Select
                            {...field}
                            inputProps={{ 'data-cy': `${field.name}-select` }}
                            onChange={field.onChange}
                            label={item.label}
                            id={field.name}
                            name={field.name}
                            size="small">
                            {options.map((o) => (
                                <MenuItem key={o.id} value={o.id}>
                                    {type === 'timezones' ? `${o.offset} ${o.timezone}` : type === 'countries' ? o.name : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
        );
    };

    if (isCountriesLoading || isTimezonesLoading || isAccountsLoading) {
        return <Loading />;
    }

    if (isCountriesError || isTimezonesError || isAccountsError) {
        return <span>Error</span>;
    }

    return (
        <Container>
            <CustomBreadcrumbs breadcrumbs={accountBreadcrumbs} />
            <Title>
                Company<TitleExt>Update your company details</TitleExt>
            </Title>
            <Tabs tabList={allowedTabs} activeTab={loc.pathname} onClick={handleTabClick} />
            <Form>
                <FieldSet>
                    <Legend>Company Details</Legend>
                    {InputField(companyName)}
                    {InputField(website)}
                </FieldSet>
                <FieldSet>
                    <Legend>Address</Legend>
                    {InputField(addressLine1)}
                    {InputField(addressLine2)}
                    {InputField(addressCity)}
                    {InputField(addressState)}
                    <StyledTwoColumns>
                        {!isCountriesLoading && countries?.length && SelectField(addressCountry, 'countries', countries)}
                        {InputField(addressZipCode)}
                    </StyledTwoColumns>
                </FieldSet>
                <FieldSet>
                    <Legend>Date Settings</Legend>
                    {!isTimezonesLoading && timezones?.length && SelectField(timezone, 'timezones', timezones)}
                </FieldSet>
                <StyledFormFooter>
                    <Button loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                </StyledFormFooter>
            </Form>
            <Toast
                variant={toast.variant}
                title={toast.title}
                description={toast.description}
                open={toast.open}
                onClose={handleToastClose}
            />
        </Container>
    );
};

export default Account;
