import { ICountry, ITimezone, IAccount } from '../type';
import apiClient from './config';

export const getAccounts = async (): Promise<IAccount> => {
    const { data } = await apiClient.get(`/accounts`);

    return data.data;
};

export const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await apiClient.get(`/countries`);

    if (data.data instanceof Array) {
        return data.data as ICountry[]
    }

    return [];
};

export const getTimezones = async (): Promise<ITimezone[]> => {
    const { data } = await apiClient.get(`/timezones`);

    if (data.data instanceof Array) {
        return data.data as ITimezone[]
    }

    return null;
};


export const saveAccount = async (account: IAccount) => await apiClient.put(`/accounts`, account);
