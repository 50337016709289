import React, { useCallback, useState } from 'react';
import { useReactTable, getCoreRowModel, TableOptions, flexRender, SortingState, getSortedRowModel } from '@tanstack/react-table';
import { StyledTable, TableHead, TR, TD, TH, TableSortIconWrapper, TableSort } from './TableStyles';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResizeObserver } from '../../hooks/useResizeObserver';

export type TableProps<T> = Omit<TableOptions<T>, 'getCoreRowModel'> & {
    data: Array<T>;
    hiddenOnMobile?: Array<keyof T>;
};

function Table<T>({ data, columns, hiddenOnMobile }: TableProps<T>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const breakpoint = useResizeObserver();

    const filterHiddenColumnsOnMobile = useCallback(() => {
        if (!hiddenOnMobile || breakpoint !== 'mobile') return columns;

        return columns.filter((x: { accessorKey: keyof T }) => !hiddenOnMobile.includes(x.accessorKey));
    }, [columns, hiddenOnMobile, breakpoint]);

    const table = useReactTable({
        state: {
            sorting
        },
        data,
        columns: filterHiddenColumnsOnMobile(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting
    });

    return (
        <div>
            <StyledTable>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const headerCell = header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext());

                                return (
                                    <TH key={header.id}>
                                        {header.column.getCanSort() ? (
                                            <TableSort onClick={header.column.getToggleSortingHandler()}>
                                                {headerCell}
                                                {header.column.getCanSort() && (
                                                    <TableSortIconWrapper sortOrder={header.column.getIsSorted()}>
                                                        <FontAwesomeIcon icon={solid('caret-up')} size="lg" />
                                                        <FontAwesomeIcon icon={solid('caret-down')} size="lg" />
                                                    </TableSortIconWrapper>
                                                )}
                                            </TableSort>
                                        ) : (
                                            headerCell
                                        )}
                                    </TH>
                                );
                            })}
                        </tr>
                    ))}
                </TableHead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <TR key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TD key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TD>
                            ))}
                        </TR>
                    ))}
                </tbody>
            </StyledTable>
        </div>
    );
}

export default Table;
