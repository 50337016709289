import * as Yup from 'yup';
import {
  currentPassword,
  newPassword, confirmPassword, authCode
} from '../data/change_password';


const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required(currentPassword.requiredError
  ).max(99, currentPassword.lengthError),
  password: Yup.string()
    .required(newPassword.requiredError)
    .matches(newPassword.combinationPattern, newPassword.patternError).max(99, newPassword.lengthError),
  password_confirmation: Yup.string()
    .required(confirmPassword.requiredError)
    .oneOf([Yup.ref('password')], confirmPassword.patternError).max(99, confirmPassword.lengthError)
});

const authConfigSchema = Yup.object().shape({
  code: Yup.number().
    when('$isMfa', (isMfa) => !isMfa
      ? Yup.number().typeError(authCode.patternError).
        test('len', authCode.lengthError, val => val && val.toString().length === 6)
      : Yup
        .string().nullable()
        .notRequired()
    )
});

export {
  changePasswordSchema, authConfigSchema
};