import * as Yup from 'yup';
import {
    firstName,
    lastName,
    email,
    phone,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode
} from '../data/contact_form';
import { IValidateOptionsExtended } from '../../type';

const contactSchema = Yup.object().shape({
    firstname: Yup.string().required(firstName.requiredError).max(30, firstName.lengthError),
    lastname: Yup.string().required(lastName.requiredError).max(30, lastName.lengthError),
    emails: Yup.array().of(
        Yup.object().shape({
            address: Yup.string()
                .email(email(0).emailError)
                .max(50, email(0).lengthError)
                .test({
                    name: 'first-email-required',
                    message: email(0).requiredError,
                    test: function (item) {
                        const { options } = this as Yup.TestContext & IValidateOptionsExtended;

                        if (options.index > 0) return true;

                        return !!item;
                    }
                })
        })
    ),
    phones: Yup.array().of(
        Yup.object().shape({
            number: Yup.string().test({
                name: 'mobile-validation',
                message: phone(0).patternError,
                test: function (this, value) {
                    const val = `+${this.parent.national_number}${value}`;

                    if (!value) return true; // not required

                    return !!val.match(phone(0).pattern) && val.length >= 10;
                }
            })
        })
    ),
    addresses: Yup.array().of(
        Yup.object().shape({
            city: Yup.string().max(50, addressCity(0).lengthError),
            firstline: Yup.string().max(50, addressLine1(0).lengthError),
            secondline: Yup.string().max(50, addressLine2(0).lengthError),
            state: Yup.string().max(50, addressState(0).lengthError),
            zipcode: Yup.string().matches(addressZipCode(0).pattern, addressZipCode(0).patternError).max(10, addressZipCode(0).lengthError)
        })
    )
});

export { contactSchema };
