import React from 'react';
import { Container, Main, StyledImg, StyledTextTop, StyledTextBottom } from './OnboardingLoading.styled';
import file from '../../assets/images/file.svg';

const OnboardingLoading: React.FC = () => {
    return (
        <Container>
            <Main>
                <StyledImg src={file} alt="file"></StyledImg>
                <StyledTextTop>Almost there!</StyledTextTop>
                <StyledTextBottom>We’re busy setting up your dashboard and templates…</StyledTextBottom>
            </Main>
        </Container>
    );
};
export default OnboardingLoading;
