import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import { Select, Box } from '@mui/material';
import { MenuProps } from '@mui/material';

export const LabelContainer = styled.span`
    font-size: ${theme.font_size_12};
    color: ${theme.dark_gray_2};
    margin-left: 4px;
`;

export const StyledImg = styled.img`
    width: 20px;
`;

export const StyledSelect = styled(Select)(() => ({
    width: 84,
    height: 36,
    '& .MuiSelect-select': { paddingRight: '0px !important', paddingLeft: '0px !important' },
    '& .MuiSelect-icon': {
        right: 0
    }
}));

export const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    padding-right: 12px;
`;

export const StyledItemBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const menuProps: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: 300
        }
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    }
};