import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export type TTableSortIconWrapper = {
    sortOrder: 'asc' | 'desc' | false;
};

export const TableSort = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-family: ${theme.font_family_poppins};
    color: ${theme.dark_gray_2};
    font-size: ${theme.font_size_13};
    font-weight: ${theme.font_weight_medium};
    line-height: 1.538em;
    cursor: pointer;
`;

export const TableSortIconWrapper = styled.div<TTableSortIconWrapper>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    svg {
        color: ${theme.med_gray_1};
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
    }

    svg:first-child {
        top: calc(50% - 3px);
        opacity: ${(props) => Number(props.sortOrder !== 'asc')};
    }

    svg:last-child {
        top: calc(50% + 3px);
        opacity: ${(props) => Number(props.sortOrder !== 'desc')};
    }
`;
