import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import { infoCardPlatte } from '../../assets/styles/palette';
import { TInfoCardStyle } from '../../type';

export const Wrapper = styled.div<TInfoCardStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
    width: 340px;
    height: 68px;
    border-radius: 5px;
    text-align: left;
    font-size: ${theme.font_size_13};
    background-color: ${(props) => infoCardPlatte[props.color].bgColor};
    color: ${(props) => infoCardPlatte[props.color].textColor};
    svg {
        font-size: 48px;
        color: ${(props) => infoCardPlatte[props.color].textColor};
    }
`;

export const Number = styled.div`
    font-size: 34px;
    font-weight: ${theme.font_weight_light};
`;
export const Label = styled.div`
    font-weight: ${theme.font_weight_semiBold};
    text-transform: uppercase;
`;
