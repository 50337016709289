import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${theme.font_size_13};
`;

export const StyledPagination = styled(Pagination)`
    ul {
        li:first-of-type,
        li:last-of-type {
            button {
                border-color: transparent;
            }
        }
        li {
            button {
                font-size: ${theme.font_size_13};
                min-width: auto;
                height: auto;
                color: ${theme.med_gray_1};
                font-weight: ${theme.font_weight_light};
            }
        }
    }
    .MuiPaginationItem-root {
        &:hover:not(.MuiPaginationItem-ellipsis) {
            background-color: ${theme.med_gray_4};
            color: ${theme.white};
        }
        &.Mui-selected {
            font-weight: ${theme.font_weight_semiBold};
            background-color: ${theme.white};
            &:hover {
                background-color: transparent;
                color: ${theme.med_gray_1};
            }
        }
    }
    .MuiPaginationItem-outlined {
        padding: 3px 7px;
    }
`;

export const PageSizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    span {
        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
`;

export const StyledSelect = styled(Select)`
    .MuiInputBase-input {
        border-radius: 4;
        border: 1px solid ${theme.light_gray_1};
        font-size: ${theme.font_size_13};
        padding: 3px 20px 3px 10px;
        &:focus {
            border-radius: 4;
            border-color: ${theme.med_gray_1};
        }
    }
    .notranslate {
        &:last-child {
            margin-left: 0;
        }
        &:first-child {
            margin-right: 0;
        }
    }
`;
