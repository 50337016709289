const firstName = {
    type: 'input',
    id: 'first_name',
    label: 'First Name',
    required: true,
    requiredError: 'First name is required'
};

const lastName = {
    type: 'input',
    id: 'last_name',
    label: 'Last Name',
    required: true,
    requiredError: 'Last name is required'
};

const countryCode = {
    type: 'component',
    id: 'countryCode',
    required: true
};

const phone = {
    type: 'component',
    id: 'phone',
    label: 'Mobile Number',
    required: true,
    requiredError: 'Mobile number is required',
    pattern: /^\+[1-9]\d{9,14}$/,
    patternError: 'Invalid mobile number format'
};

const companyName = {
    type: 'input',
    id: 'company_name',
    label: 'Company Name',
    required: true,
    requiredError: 'Company name is required'
};

const industry = {
    type: 'select',
    id: 'industry',
    label: 'Industry',
    required: true,
    requiredError: 'Industry is required',
    options: [
        { id: 'Accounting', value: 'Accounting' },
        { id: 'Banking & Lending', value: 'Banking & Lending' },
        { id: 'Education', value: 'Education' },
        { id: 'Financial Services, Advice & Insurance', value: 'Financial Services, Advice & Insurance' },
        { id: 'Health', value: 'Health' },
        { id: 'Legal/Law', value: 'Legal/Law' },
        { id: 'Professional Services', value: 'Professional Services' },
        { id: 'Real Estate & Construction', value: 'Real Estate & Construction' },
        { id: 'Logistics', value: 'Logistics' },
        { id: 'Other', value: 'Other' }
    ]
};

const otherIndustry = {
    type: 'input',
    id: 'other_industry',
    label: 'Other Industry',
    required: false,
    requiredError: 'Please complete the required field'
};

const role = {
    type: 'select',
    id: 'role',
    label: 'Role',
    required: true,
    requiredError: 'Role is required',
    options: [
        { id: 'Accounting', value: 'Accounting' },
        { id: 'Administration', value: 'Administration' },
        { id: 'Executive', value: 'Executive' },
        { id: 'Finance', value: 'Finance' },
        { id: 'Human Resources', value: 'Human Resources' },
        { id: 'Information Technology', value: 'Information Technology' },
        { id: 'Legal', value: 'Legal' },
        { id: 'Marketing', value: 'Marketing' },
        { id: 'Operations', value: 'Operations' },
        { id: 'Product', value: 'Product' },
        { id: 'Purchasing', value: 'Purchasing' },
        { id: 'Sales', value: 'Sales' },
        { id: 'Other', value: 'Other' }
    ]
};

const otherRole = {
    type: 'input',
    id: 'other_role',
    label: 'Other Role',
    required: false,
    requiredError: 'Please complete the required field'
};

const companySize = {
    type: 'select',
    id: 'company_size',
    label: 'Company Size',
    required: false,
    options: [
        { id: '1-10', value: '1-10 employees' },
        { id: '11-20', value: '11-20 employees' },
        { id: '21-50', value: '21-50 employees ' },
        { id: '51-100', value: '51-100 employees' },
        { id: '101-200', value: '101-200 employees' },
        { id: '201-500', value: '201-500 employees' },
        { id: '501-1000', value: '501-1000 employees' },
        { id: '1000+', value: '1000+ employees' }
    ]
};

const howToKnowUs = {
    type: 'input',
    id: 'how_did_you_hear_about_us',
    label: 'How did you hear about us',
    required: true,
    requiredError: 'Please complete the required field'
};

export {
    firstName, lastName, countryCode, phone, companyName,
    industry, otherIndustry, role, otherRole, companySize, howToKnowUs
};
