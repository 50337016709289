import React from 'react';
import { Title, Desc, Content, Wrapper, Icon } from './Tile.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ITile } from '../../type';

type TTileComponentProps = ITile & {
    onClick?: () => void;
};

const Tile: React.FC<TTileComponentProps> = ({ title, description, completion, onClick, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Icon completion={!!completion.actioned_at}>
                {completion.actioned_at ? (
                    <FontAwesomeIcon icon={solid('check-circle')} />
                ) : (
                    <FontAwesomeIcon icon={light('check-circle')} />
                )}
            </Icon>

            <Content>
                {<Title>{title}</Title>}
                {<Desc onClick={onClick} dangerouslySetInnerHTML={{ __html: description }}></Desc>}
            </Content>
        </Wrapper>
    );
};

export default Tile;
