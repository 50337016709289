import { MenuItem } from '@mui/material';
import React from 'react';
import { TCountryCodeProps } from '../../type';
import { LabelContainer, StyledImg, StyledItemBox } from './FormPhone.styled';

const DisplayMenuItem = (country: TCountryCodeProps) => {
    return (
        <MenuItem value={country.code}>
            <StyledItemBox>
                <StyledImg
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                    alt={`Flag of ${country.label}`}
                />
                <LabelContainer>{country.label}</LabelContainer>
                <LabelContainer> +{country.phone}</LabelContainer>
            </StyledItemBox>
        </MenuItem>
    );
};

export default DisplayMenuItem;
