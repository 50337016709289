import styled from 'styled-components';
import * as theme from '../../../../assets/styles/variables';

export const Title = styled.div`
    & > svg {
        margin-right: 10px;
    }
`;

export const Content = styled.div`
    width: 343px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 30px;

    @media ${theme.device.mobile} {
        width: 275px;
    }

    > section {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: ${theme.font_size_13};
        color: ${theme.dark_gray_2};
        font-weight: ${theme.font_weight_regular};
        row-gap: 25px;
    }
`;