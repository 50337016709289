import React from 'react';
import { Content, anchorOrigin, Text, Wrapper, Action, Icon } from './Toast.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Snackbar } from '@mui/material';
import { TToast } from '../../type';

const Toast: React.FC<TToast> = ({ title, description, variant = 'success', open, onClose, ...rest }) => {
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
    };

    return (
        <Snackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={4000} onClose={handleClose} {...rest}>
            <Wrapper variant={variant}>
                <Content>
                    <Icon>
                        {variant === 'success' ? (
                            <FontAwesomeIcon icon={regular('check-circle')} />
                        ) : (
                            <FontAwesomeIcon icon={regular('exclamation-circle')} />
                        )}
                    </Icon>
                    <Text data-cy="toast-body-content">
                        <span>{title}</span>
                        <span>{description}</span>
                    </Text>
                </Content>
                <Action>
                    <FontAwesomeIcon icon={regular('times')} onClick={handleClose} />
                </Action>
            </Wrapper>
        </Snackbar>
    );
};

export default Toast;
