import { SnackbarOrigin } from '@mui/material';
import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';
import { toastPlatte } from '../../assets/styles/palette';
import { TToastStyle } from '../../type';

export const anchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
};

export const Wrapper = styled.div<TToastStyle>`
    max-width: 300px;
    width: 300px;
    padding: 15px;
    color: ${theme.white};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 5px;
    background-color: ${(props) => toastPlatte[props.variant].bgColor};
`;

export const Content = styled.div`
    min-height: 55px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: flex-start;
`;

export const Icon = styled.div`
    font-size: ${theme.font_size_18};
`;

export const Text = styled.div`
    font-size: ${theme.font_size_15};
    font-weight: ${theme.font_weight_medium};
    & > span {
        display: block;
    }
    & > span:last-child {
        font-size: ${theme.font_size_13};
        font-weight: ${theme.font_weight_regular};
    }
`;

export const Action = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    margin-right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: auto;
`;
