import React from 'react';
import { StyledTooltip, Wrapper } from './Tooltip.styled';
import { TooltipProps } from '../../type';

const Tooltip: React.FC<TooltipProps> = ({ message, component, disabled = false, placement = 'top' }: TooltipProps) => {
    return (
        <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: message }} />} arrow placement={placement}>
            <Wrapper type="button" disabled={disabled}>{component}</Wrapper>
        </StyledTooltip>
    );
};

export default Tooltip;
