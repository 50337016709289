/**
 * Created by Daisy on 13/08/2020
 * Override bootstrap variables by the style guide.
 * Style guide: https://xd.adobe.com/view/87655a33-c4f9-4375-8471-c943b12dbaf2-d236/specs/
 */

//for development

// theme colors
export const dark_gray_1 = '#2B2C34';
export const dark_gray_2 = '#4D4E56';
export const dark_gray_3 = '#404254';
export const med_gray_1 = '#71737A';
export const med_gray_2 = '#A5A5A5';
export const med_gray_3 = '#F3F3F3';
export const med_gray_4 = '#787F87';
export const light_gray_1 = '#C7C9CE';
export const light_gray_2 = '#DEE0E5';
export const light_gray_3 = '#EBEDF3';
export const light_gray_4 = '#F7F8FA';
export const light_gray_5 = '#FBFBFB';
export const light_gray_6 = '#DBDCE0';

export const white = '#fff';
export const black = '#000';
export const dark = '#425B76'

export const blue = '#3492D6';
export const light_blue_1 = '#3492D6CC';
export const light_blue_2 = '#3492D626';

export const mustard = '#EFA707';
export const dark_mustard = '#EFA707';

export const yellow = '#FCCF19';
export const orange = '#F79119';
export const dark_orange = '#DE6A22';

export const red = '#FA5353';
export const light_red_1 = '#F96868CC';
export const light_red_2 = '#F9686826';

export const green = '#86AF58';
export const dark_green = '#72954B';

export const primary = '#EFA707';
export const dark_primary = '#CC8E06';

export const secondary = '#6895C8';
export const dark_secondary = '#597FAA';

export const tertiary = light_gray_2;
export const dark_tertiary = light_gray_1;
export const tertiary_outlined = '#71737A14';

export const box_shadow = '#D2D6DE66';
export const border = '#A8AAB0';

export const warning = red;
export const dark_warning = '#D14746';

export const primary_rgb = '239,167,7';
export const light_gray_3_rgb = '235,237,243';
export const dark_gray_2_rgb = '77,78,86';
export const blue_rgb = '52,146,214';
export const green_rgb = '134, 175, 88';
export const orange_rgb = '247, 145, 25';
export const med_grey_1_rgb = '113, 115, 122';
export const mustard_rgb = '239,167,7';
export const red_rgb = '250,83,83';

export const opacity_1 = '0.1';
export const opacity_2 = '0.2';
export const opacity_4 = '0.4';
export const opacity_5 = '0.5';

/* Font/text values */
export const font_family_poppins = 'Poppins';
export const font_family_montserrat = 'Montserrat';
export const font_weight_light = '300';
export const font_weight_regular = '400';
export const font_weight_medium = '500';
export const font_weight_semiBold = '600';
export const font_weight_normal = 'normal';
export const font_size_11 = '11px';
export const font_size_12 = '12px';
export const font_size_13 = '13px';
export const font_size_14 = '14px';
export const font_size_15 = '15px';
export const font_size_16 = '16px';
export const font_size_18 = '18px';
export const font_size_20 = '20px';
export const font_size_22 = '22px';
export const font_size_24 = '24px';
export const font_size_26 = '26px';
export const font_size_30 = '30px';
export const character_spacing_0 = '0px';
export const line_spacing_18 = '18px';
export const line_spacing_20 = '20px';
export const line_spacing_21 = '21px';
export const line_spacing_30 = '30px';
export const line_spacing_35 = '35px';
export const line_spacing_46 = '46px';
export const text_transform_uppercase = 'uppercase';

const size = {
    mobile: '575px',
    tablet: '767px',
    tabletL: '991px',
    desktop: '1200px'
};

export const device = {
    //On screens that are 576px or less
    mobile: `(max-width: ${size.mobile})`,
    //On screens that are 768px or less
    tablet: `(max-width: ${size.tablet})`,
    //On screens that are 992px or less
    tabletL: `(max-width: ${size.tabletL})`,
    //On screens that are 1200px or less
    desktop: `(max-width: ${size.desktop})`
};
