import { Breadcrumbs } from '@mui/material';
import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const StyledLink = styled.a`
    text-decoration: none;
    color: ${theme.med_gray_1};
    :hover {
        color: ${theme.secondary};
    }
`;

export const StyledSpan = styled.span`
    color: ${theme.med_gray_2};
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    &.MuiBreadcrumbs-root {
        font-size: ${theme.font_size_13};
        color: ${theme.med_gray_1};
        margin-bottom: 10px;
    }
    .MuiBreadcrumbs-separator {
        font-size: ${theme.font_size_12};
    }
`;
