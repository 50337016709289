import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider, Controller, useFieldArray } from 'react-hook-form';
import { TextField, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { result } from 'lodash';
import {
    Container,
    Header,
    FormGroup,
    Name,
    AddNewField,
    BackButton,
    Title,
    EmailGroup,
    RemoveButton,
    PhoneGroup,
    Phone,
    AddressGroup,
    AddressHeading,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Phones,
    AddNewPhone,
    SMSSwitch,
    FormFooter
} from './Form.styled';
import { CustomBreadcrumbs, StatelessFormPhone, Button, Loading, AutocompleteSearch, Toast, IOSSwitch } from '../../../components';
import { contactCreateBreadcrumbs, contactEditBreadcrumbs } from '../../../assets/data/breadcrumbs';
import {
    firstName,
    lastName,
    company,
    email,
    phone,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode
} from '../../../assets/data/contact_form';
import { getCodeByCountryIso2 } from '../../../assets/data/country';
import {
    useCountriesQuery,
    useCreateContactMutation,
    useEditContactMutation,
    useCompaniesQueryDebounced,
    useContactQuery
} from '../../../hooks/contact';
import { contactSchema } from '../../../assets/utils/contact_schema';
import { formatContactResponse, formatContactRequest } from '../../../assets/utils/formDataFormat';
import { combineErrorMessage } from '../../../assets/utils/utils';
import { TFormField, TContactFormValues, TToast, ICompany } from '../../../type';

const initialFormValues = {
    firstname: '',
    lastname: '',
    company: null,
    emails: [
        {
            address: ''
        }
    ],
    phones: [
        {
            country_iso2: 'au',
            is_sms: false,
            national_number: '61',
            number: ''
        }
    ],
    addresses: [
        {
            city: '',
            country_id: 8,
            firstline: '',
            secondline: '',
            state: '',
            zipcode: ''
        }
    ]
};

const Form = () => {
    const [toast, setToast] = useState<TToast>({
        open: false,
        variant: 'success',
        description: '',
        title: ''
    });
    const [companySearch, setCompanySearch] = useState('');
    const params = useParams();
    const getFormType = useCallback((): 'create' | 'edit' => {
        if (!params.id) return 'create';

        return 'edit';
    }, [params]);

    const formType = getFormType();

    const { data: contact, isLoading: isContactLoading, isError: isContactError } = useContactQuery(params.id);
    const { data: countries, isLoading: isCountriesLoading, isError: isCountriesError } = useCountriesQuery();
    const { data: companies, isError: isCompaniesError } = useCompaniesQueryDebounced(companySearch);
    const { mutate: createContact, isLoading: isCreateContactLoading } = useCreateContactMutation();
    const { mutate: editContact, isLoading: isEditContactLoading } = useEditContactMutation();

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(contactSchema),
        defaultValues: initialFormValues
    });

    const {
        control,
        setValue,
        handleSubmit,
        watch,
        reset,
        trigger,
        formState: { errors }
    } = methods;

    const [watchedCompany, watchedPhones] = watch(['company', 'phones']);

    const { fields: emails, append: appendEmail, remove: removeEmail } = useFieldArray({ control, name: 'emails' });
    const { fields: phones, append: appendPhone, remove: removePhone } = useFieldArray({ control, name: 'phones' });
    const { fields: addresses, append: appendAddress, remove: removeAddress } = useFieldArray({ control, name: 'addresses' });

    const triggerFieldValidation = useCallback(
        async (fieldName) => {
            if (!fieldName) return;

            return trigger(fieldName);
        },
        [trigger]
    );

    const onAddField = (type: string) => (e: React.MouseEvent) => {
        e.preventDefault();

        switch (type) {
            case 'email':
                return appendEmail({ address: '' });
            case 'phone':
                return appendPhone({
                    country_iso2: 'au',
                    is_sms: false,
                    national_number: '61',
                    number: ''
                });
            case 'address':
                return appendAddress({
                    city: '',
                    country_id: 0,
                    firstline: '',
                    secondline: '',
                    state: '',
                    zipcode: ''
                });
            default:
                throw new Error('Invalid type parameter');
        }
    };

    const onRemoveField = (type: string, index: number) => (e: React.MouseEvent) => {
        e.preventDefault();

        switch (type) {
            case 'email':
                return removeEmail(index);
            case 'phone':
                return removePhone(index);
            case 'address':
                return removeAddress(index);
            default:
                throw new Error('Invalid type parameter');
        }
    };

    const goBack = () => {
        // NOTE: TEMP-REDIRECT
        // Redirect to contacts here...
        window.location.href = window.location.origin + '/contacts';
    };

    const handleCompanyChange = (e: React.ChangeEvent, selectedOption: ICompany) => {
        setValue('company', selectedOption);
    };

    const handleCompanySearch = (e: React.ChangeEvent, newVal: string) => {
        setCompanySearch(newVal);
    };

    const onSuccess = (msg: string) => () => {
        setToast({
            title: 'Success',
            variant: 'success',
            description: msg,
            open: true
        });

        goBack();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (err: any) => {
        const errors = combineErrorMessage(err.response.data);

        setToast({
            title: 'Error',
            variant: 'error',
            description: errors,
            open: true
        });
    };

    const onSubmit = (data: TContactFormValues) => {
        const payload = formatContactRequest(data);

        if (formType === 'create') {
            return createContact(payload, {
                onSuccess: onSuccess(`The contact ${payload.firstname} ${payload.lastname} has been created.`),
                onError
            });
        } else {
            return editContact(payload, {
                onSuccess: onSuccess(`The contact ${payload.firstname} ${payload.lastname} has been updated.`),
                onError
            });
        }
    };

    const handleToastClose = () => setToast((prev) => ({ ...prev, open: false }));

    const handlePhoneChange = (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(fieldName as keyof TContactFormValues, e.target.value);
        triggerFieldValidation(fieldName);
    };

    const handlePhoneCodeChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value;

        setValue(`phones.${index}.country_iso2`, code);
        setValue(`phones.${index}.national_number`, getCodeByCountryIso2(code));

        triggerFieldValidation(`phones.${index}`);
    };

    const handleSMSSwitch = (index: number) => () => {
        setValue(
            'phones',
            watchedPhones.map((wPhone, wPhoneIndex) => ({
                ...wPhone,
                is_sms: wPhoneIndex === index ? !wPhone.is_sms : false
            }))
        );
    };

    const GenerateForm = (item: TFormField) => {
        return (
            <Controller
                name={item.name as keyof TContactFormValues}
                control={control}
                rules={{ required: item.required }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        data-cy={`${item.id}-wrapper`}
                        inputProps={{
                            'data-cy': `${item.id}-input`
                        }}
                        required={item.required}
                        id={item.id}
                        label={item.label}
                        size="small"
                        fullWidth
                        error={!!result(errors, field.name)}
                        helperText={result(errors, `${field.name}.message`)}
                        onChange={field.onChange}
                    />
                )}
            />
        );
    };

    useEffect(() => {
        if (formType === 'create' || !contact) return; // do nothing

        reset(formatContactResponse(contact, initialFormValues));
    }, [contact, formType, reset]);

    if (isCountriesLoading || isContactLoading) {
        return <Loading />;
    }

    if (isCountriesError || isCompaniesError || isContactError) {
        return <span>Error</span>;
    }

    return (
        <FormProvider {...methods}>
            <Container>
                <CustomBreadcrumbs
                    breadcrumbs={formType === 'edit' ? contactEditBreadcrumbs : contactCreateBreadcrumbs}></CustomBreadcrumbs>
                <Header>
                    <Tooltip title="Back" placement="top" arrow>
                        <BackButton onClick={goBack}>
                            <FontAwesomeIcon icon={light('angle-left')} size="lg" />
                        </BackButton>
                    </Tooltip>
                    <Title data-cy="h1">{formType} Contact</Title>
                </Header>
                <form noValidate>
                    <FormGroup>
                        <Name>
                            {GenerateForm(firstName)}
                            {GenerateForm(lastName)}
                        </Name>
                        <AutocompleteSearch
                            inputProps={{ 'data-cy': 'company-input' }}
                            id={company.name}
                            name={company.name}
                            label={company.label}
                            value={watchedCompany}
                            inputValue={companySearch}
                            onChange={handleCompanyChange}
                            onInputChange={handleCompanySearch}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option: ICompany, cur: ICompany) => option.uuid === cur?.uuid}
                            options={companies || []}
                        />
                        {emails.map((item, index) => (
                            <EmailGroup key={item.id}>
                                {GenerateForm({ ...email(index), required: index === 0 })}
                                {index !== 0 && (
                                    <Tooltip title="Delete" placement="right" arrow>
                                        <RemoveButton onClick={onRemoveField('email', index)} data-cy={`email-remove-${index}-btn`}>
                                            <FontAwesomeIcon icon={solid('trash')} size="sm" />
                                        </RemoveButton>
                                    </Tooltip>
                                )}
                            </EmailGroup>
                        ))}
                        <AddNewField onClick={onAddField('email')} data-cy="add-email-btn">
                            + add another email
                        </AddNewField>
                    </FormGroup>
                    <Phones>
                        {phones.map((item, index) => {
                            const field = phone(index);
                            const currentPhone = watchedPhones[index];

                            return (
                                <PhoneGroup key={item.id}>
                                    <Phone>
                                        <StatelessFormPhone
                                            cypressId={`form-phone-${index}`}
                                            onCodeChange={handlePhoneCodeChange(index)}
                                            onPhoneChange={handlePhoneChange(`phones.${index}.number`)}
                                            code={currentPhone.country_iso2}
                                            mob={currentPhone.number}
                                            required={field.required}
                                            error={Boolean(result(errors, `phones.${index}.number`))}
                                            errorMessage={result(errors, `phones.${index}.number.message`)}
                                            showTooltip={false}
                                        />
                                        {index !== 0 && (
                                            <Tooltip title="Delete" placement="right" arrow>
                                                <RemoveButton onClick={onRemoveField('phone', index)} data-cy={`phone-remove-${index}-btn`}>
                                                    <FontAwesomeIcon icon={solid('trash')} size="sm" />
                                                </RemoveButton>
                                            </Tooltip>
                                        )}
                                    </Phone>
                                    <SMSSwitch
                                        data-cy={`phones-${index}-sms-label`}
                                        name={`phones.${index}.is_sms`}
                                        onChange={handleSMSSwitch(index)}
                                        checked={currentPhone.is_sms}
                                        control={<IOSSwitch color="primary" />}
                                        label="SMS"
                                        labelPlacement="start"
                                    />
                                </PhoneGroup>
                            );
                        })}
                        <AddNewField onClick={onAddField('phone')} data-cy="add-phone-btn">
                            + add another number
                        </AddNewField>
                    </Phones>
                    {addresses.map((item, index) => (
                        <Accordion key={item.id} disableGutters defaultExpanded>
                            <AccordionSummary expandIcon={<FontAwesomeIcon icon={solid('angle-right')} />}>
                                <AddressHeading>Address {index !== 0 && index + 1}</AddressHeading>
                                {index !== 0 && (
                                    <Tooltip title="Delete" placement="right" arrow>
                                        <RemoveButton onClick={onRemoveField('address', index)} data-cy={`phone-remove-${index}-btn`}>
                                            <FontAwesomeIcon icon={solid('trash')} size="sm" />
                                        </RemoveButton>
                                    </Tooltip>
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                {GenerateForm(addressLine1(index))}
                                {GenerateForm(addressLine2(index))}
                                {GenerateForm(addressCity(index))}
                                {GenerateForm(addressState(index))}
                                <AddressGroup>
                                    <Controller
                                        name={`addresses.${index}.country_id`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id={field.name}>Country</InputLabel>
                                                <Select
                                                    {...field}
                                                    value={field.value === 0 ? '' : field.value}
                                                    inputProps={{ 'data-cy': `${field.name}-select` }}
                                                    onChange={field.onChange}
                                                    label="Country"
                                                    id={field.name}
                                                    name={field.name}
                                                    size="small">
                                                    {countries.map((o) => (
                                                        <MenuItem key={o.id} value={o.id}>
                                                            {o.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    {GenerateForm(addressZipCode(index))}
                                </AddressGroup>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <AddNewPhone onClick={onAddField('address')} data-cy="add-address-btn">
                        + add another address
                    </AddNewPhone>
                    <FormFooter>
                        <Button
                            data-cy="submit-btn"
                            onClick={handleSubmit(onSubmit)}
                            loading={isCreateContactLoading || isEditContactLoading}
                            disabled={isCreateContactLoading || isEditContactLoading}>
                            {formType === 'create' ? 'Create' : 'Update'} Contact
                        </Button>
                    </FormFooter>
                </form>
                <Toast
                    variant={toast.variant}
                    title={toast.title}
                    description={toast.description}
                    open={toast.open}
                    onClose={handleToastClose}
                />
            </Container>
        </FormProvider>
    );
};

export default Form;
