import styled from 'styled-components';
import { TAlertBoxStyle } from '../../type';
import * as theme from '../../assets/styles/variables';
import { alertBoxPlatte } from '../../assets/styles/palette';

export const Wrapper = styled.div<TAlertBoxStyle>`
    font-family: inherit;
    font-weight: ${theme.font_weight_regular};
    font-size: ${theme.font_size_13};
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: ${(props) => (props.fullWidth ? '100%' : '400px')};
    background-color: ${(props) => alertBoxPlatte[props.mode].bgColor};
    color: ${(props) => alertBoxPlatte[props.mode].textColor};
    box-sizing: border-box;

    a {
        text-decoration: underline;
        color: ${(props) => alertBoxPlatte[props.mode].textColor};
    }
`;
