import React, { useState } from 'react';
import { Dialog, DialogActions, TextField, DialogContent } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CustomDialogTitle, Button, PasswordRuleHint, Toast } from '../../../../components';
import { currentPassword, newPassword, confirmPassword } from '../../../../assets/data/change_password';
import { Title, PasswordHintContainer, Content, paperProps, MainForm } from './ChangePasswordModal.styled';
import { TBasicModalProps, TFormField, IChangePassword, TToast } from '../../../../type';
import { changePasswordSchema } from '../../../../assets/utils/change_password_schema';
import { useChangePasswordMatation } from '../../../../hooks/profile';
import { combineErrorMessage } from '../../../../assets/utils/utils';

const defaultValues: IChangePassword = {
    current_password: '',
    password: '',
    password_confirmation: ''
};

const ChangePasswordModal: React.FC<TBasicModalProps> = ({ open, onClose }) => {
    const {
        control,
        watch,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IChangePassword>({
        defaultValues,
        resolver: yupResolver(changePasswordSchema)
    });

    const { mutate, isLoading: isSubmitting } = useChangePasswordMatation();

    const [toastState, setToast] = useState<TToast>({
        title: '',
        description: '',
        open: false,
        variant: 'success'
    });

    const handleClose = () => {
        reset(defaultValues);
        onClose();
    };

    const onChangePassword: SubmitHandler<IChangePassword> = (form) => {
        mutate(form, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 422) {
                    const message = combineErrorMessage(data);
                    setToast({ title: 'Error', variant: 'error', description: message, open: true });
                } else {
                    setToast({ title: 'Error', variant: 'error', description: 'Update password went wrong.', open: true });
                }
            },
            onSuccess: () => {
                setToast({ title: 'Success', variant: 'success', description: 'Your password has been updated.', open: true });
                handleClose();
            }
        });
    };

    const PasswordField = (item: TFormField) => (
        <Controller
            name={item.name as keyof IChangePassword}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={field.name}
                    data-cy={`${field.name}-wrapper`}
                    inputProps={{ 'data-cy': `${field.name}-input` }}
                    size="small"
                    variant="outlined"
                    type={item.type}
                    fullWidth
                    required
                    label={item.label}
                    onChange={field.onChange}
                    error={Boolean(errors[field.name])}
                    helperText={errors[field.name]?.message}
                />
            )}
        />
    );

    return (
        <>
            <Dialog open={open} PaperProps={paperProps}>
                <CustomDialogTitle onClose={handleClose}>
                    <Title>
                        <FontAwesomeIcon icon={light('lock-keyhole')} /> Change Password
                    </Title>
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Content data-cy="content">
                        <MainForm>
                            {PasswordField(currentPassword)}
                            {PasswordField(newPassword)}
                            {PasswordField(confirmPassword)}
                        </MainForm>
                        <PasswordHintContainer>
                            <PasswordRuleHint password={watch('password')} isArrowFixed={false} />
                        </PasswordHintContainer>
                    </Content>
                </DialogContent>
                <DialogActions>
                    <Button mode={'Tertiary'}  data-cy="cancel" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button mode={'Primary'}  data-cy="update-password" loading={isSubmitting} onClick={handleSubmit(onChangePassword)}>
                        Update Password
                    </Button>
                </DialogActions>
            </Dialog>
            <Toast {...toastState} onClose={() => setToast((prev) => ({ ...prev, open: false }))} />
        </>
    );
};

export default ChangePasswordModal;