/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ButtonType {
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    TERTIARY = 'Tertiary'
}

export enum ArrowPositionType {
    LEFT = 'left',
    RIGHT = 'right'
}

export type TCountryCodeProps = {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
    order?: number;
};

export interface ITile {
    title?: string;
    description?: string;
    action_url?: string;
    slug:
        | 'create-your-own-invite'
        | 'send-an-invite'
        | 'preview-your-client-portal'
        | 'explore-your-customer-dashboard'
        | 'add-your-branding'
        | 'add-your-team';
    completion?: {
        actioned_at: string | null;
    };
    actual_url?: string;
    [key: string]: unknown;
}
export interface IGettingStartItemsParams {
    item: string;
    actioned: boolean;
}

export type IGettingStartedOption = {
    dont_show_getting_started_at?: boolean;
    [key: string]: unknown;
};

export interface IGettingStartOptionParams {
    dont_show_getting_started: boolean;
}

export interface ISignupForm {
    email: string;
    password: string;
    isNotRobot: string;
    terms_and_conditions: boolean;
}

export type TCreateOptionProps = {
    image: 'Fold' | 'Spreadsheet';
    title: string;
    subtitle: string;
    url?: string;
};

// TERTIARY OUTLINED = Toutlined
export type TMode = {
    mode?: 'Primary' | 'Secondary' | 'Tertiary' | 'Warning' | 'Green' | 'Toutlined';
};

export type TButtonStyle = TMode & {
    size?: 'small' | 'large';
    fullWidth?: boolean;
    loading?: boolean;
};

export type TBasicButton = TButtonStyle & {
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    onClick?: () => void;
    [x: string]: unknown;
};

export type TOption = {
    id: number;
    text: string;
};

type TContacts = {
    firstname: string;
    lastname: string;
};
type TPermission = {
    view: boolean;
    copy: boolean;
    delete: boolean;
    edit: boolean;
};
export type TInviteData = {
    complete_percentage: number;
    contacts: TContacts[];
    created_at: string;
    display_user: string;
    due_at: string;
    is_overdue: boolean;
    last_activity_at: string;
    notification_user: string;
    permissions: TPermission;
    reference_id: string;
    status: 'sent' | 'returned' | 'draft';
    subject: string;
    user: string;
    uuid: string;
};

export type TInviteMeta = {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
};
export interface IInviteResponse {
    data: TInviteData[];
    meta: TInviteMeta;
}

export interface IInviteParams {
    pageNumber: number;
    sort: 'created_at';
    pageSize: 10 | 15 | 20 | 25;
    search: string | '';
    user: string | '';
}

export interface IUserResponse {
    data: IUser;
    message: string;
}

export interface IUser {
    uuid: string;
    firstname: string;
    lastname: string;
    avatar?: string | null;
    email?: { address: string };
    company?: {
        country_iso2: string;
    };
    address?: {
        city: string | null;
        country_id: number;
        firstline: string | null;
        secondline: string | null;
        state: string | null;
        zipcode: string | null;
    };
    phone?: {
        number: string;
        country_iso2: string;
        national_number: string;
        [key: string]: unknown;
    };
    is_mfa_enabled?: boolean;
    current_permissions?: IUserPermissions;
    [key: string]: unknown;
}

export interface IUserPermissions {
    acceptRejectRequest: boolean;
    bankStatementsIntegration: boolean;
    boxIntegration: boolean;
    cashDeckIntegration: boolean;
    closeInvite: boolean;
    copyInvite: boolean;
    copyRequest: boolean;
    copyTemplate: boolean;
    createBulkSend: boolean;
    createCompany: boolean;
    createContact: boolean;
    createGroup: boolean;
    createInvite: boolean;
    createRequest: boolean;
    createTemplate: boolean;
    createUsers: boolean;
    creditSenseIntegration: boolean;
    deleteBulkSend: boolean;
    deleteCompany: boolean;
    deleteContact: boolean;
    deleteDraftInvite: boolean;
    deleteFilesRequest: boolean;
    deleteGroup: boolean;
    deleteInvite: boolean;
    deleteRequest: boolean;
    deleteTemplate: boolean;
    deleteUsers: boolean;
    displayUser: boolean;
    downloadCompletedLiveForms: boolean;
    downloadReturnedFiles: boolean;
    dropboxIntegration: boolean;
    editBulkSend: boolean;
    editCompany: boolean;
    editContact: boolean;
    editDraftInvite: boolean;
    editGroup: boolean;
    editInvite: boolean;
    editRequest: boolean;
    editTemplate: boolean;
    editUsers: boolean;
    exportContact: boolean;
    exportInvite: boolean;
    fortiroProtectIntegration: boolean;
    googleDriveIntegration: boolean;
    importContact: boolean;
    mergeTemplate: boolean;
    notificationUser: boolean;
    nscIntegration: boolean;
    oneDriveBusinessIntegration: boolean;
    oneDriveIntegration: boolean;
    parchmentIntegration: boolean;
    previewFilesRequest: boolean;
    reopenInvite: boolean;
    replaceInviteTemplate: boolean;
    resendInvite: boolean;
    sendInvite: boolean;
    sendMessagesInvite: boolean;
    settingAccount: boolean;
    settingApiWebhook: boolean;
    settingAuthControls: boolean;
    settingBranding: boolean;
    settingChat: boolean;
    settingCloud: boolean;
    settingFileOptions: boolean;
    settingMessages: boolean;
    settingNotifications: boolean;
    settingPlan: boolean;
    settingProfile: boolean;
    settingReports: boolean;
    settingReview: boolean;
    settingRoles: boolean;
    settingSAML: boolean;
    settingSMTP: boolean;
    settingTemplates: boolean;
    settingWidget: boolean;
    shareTemplate: boolean;
    transferContact: boolean;
    transferInvite: boolean;
    transferToInvite: boolean;
    uploadClientFilesRequest: boolean;
    viewBulkSend: boolean;
    viewCompany: boolean;
    viewContact: boolean;
    viewGroup: boolean;
    viewInvite: boolean;
    viewInviteLevel: string;
    viewMessagesInvite: boolean;
    viewReview: boolean;
    viewSharedTemplate: boolean;
    viewTemplate: boolean;
    viewUsers: boolean;
    workflowMaxIntegration: boolean;
    zapierIntegration: boolean;
}

export type TInfoCardStyle = {
    color: 'Blue' | 'Green' | 'Orange' | 'Red';
};

export type TInfoCardProps = TInfoCardStyle & {
    number: number;
    label: string;
    icon: 'plane' | 'comments' | 'reply' | 'envelope' | 'lock';
};

export type TAlertBoxStyle = {
    mode?: 'Error' | 'Info';
    fullWidth?: boolean;
};

export type TooltipProps = {
    message: string;
    component: React.ReactNode;
    disabled?: boolean;
    placement?: 'top' | 'left' | 'right' | 'bottom';
};

export type TableOpProps = {
    id: 'edit' | 'view' | 'copy' | 'delete';
    title: 'Edit' | 'View' | 'Copy' | 'Delete';
    enabled?: boolean;
};

export interface ISetupForm {
    first_name: string;
    last_name: string;
    phone: {
        mobile_number: string;
        country_iso2: string;
        national_number: string;
    };
    industry: string;
    other_industry: string;
    role: string;
    other_role: string;
    company_name: string;
    how_did_you_hear_about_us: string;
    company_size: string;
    timezone: string;
    country: string;
    hubspotutk: string | null;
}

export type TAutocompleteProps = {
    id: string;
    options: any[];
    onChange?: (event: React.SyntheticEvent, value: any | Array<any>) => void;
    placeholder?: string;
    label?: string;
    inputValue?: string;
    onInputChange?: (event: React.SyntheticEvent, value: string) => void;
    getOptionLabel?: (option: any) => string;
    value?: any;
    [key: string]: unknown;
};

export type TSearchBarProps = {
    id: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    placeholder: string;
    value: string;
    loading?: boolean;
    isMobileFixed: boolean;
    [key: string]: unknown;
};
export interface IRecaptchaKeys {
    recaptcha_site_key: string;
    recaptcha_enabled: boolean;
    intercom_app_id: string;
    [key: string]: unknown;
}

export type IEnableAuthConfignModal = {
    code: string;
};

export interface IGeneratedQRCode {
    qr_code_image: string;
    qr_code_text: string;
}
export interface IOnboadingGettingStatedResponse {
    data: ITile[];
    progress: number;
}
export interface ISmsGenerateRequest {
    number: string;
}

export type TBreadCrumbs = {
    title: string;
    path: string;
};

export type TFormField = {
    id?: string;
    name: string;
    label: string;
    type?: string;
    required?: boolean;
    requiredError?: string;
    emailError?: string;
    pattern?: RegExp;
    patternError?: string;
    combinationPattern?: RegExp;
    lengthError?: string;
    options?: any[];
};

export type TConfirmPasswordFieldValues = {
    password: string;
    sms_verification_code: string;
};

export interface IChangePassword {
    current_password: string;
    password: string;
    password_confirmation: string;
}

export type TBasicModalProps = {
    open: boolean;
    onClose?: () => void;
};
export interface ICountry {
    id: number;
    name: string;
    iso: string;
}
export interface IProfile {
    firstname: string;
    lastname: string;
    password: string;
    sms_verification_code: string;
    email: { address: string };
    address: {
        city: string;
        country_id: number;
        firstline: string;
        secondline: string;
        state: string;
        zipcode: string;
    };
    phone: {
        number: string;
        country_iso2: string;
        national_number: string;
        [key: string]: unknown;
    };
    avatar?: string;
    is_mfa_enabled?: boolean;
    [key: string]: unknown;
}

export type TProfileFieldValues = {
    firstname: string;
    lastname: string;
    email: object;
    address: object;
    phone: object;
    password: string;
    sms_verification_code: string;
};

export type TConfirmPasswordModalProps = TBasicModalProps & {
    phoneNumber?: string;
    title: string;
    isShowSms: boolean;
    onConfirm?: () => Promise<boolean | void>;
};

export interface IDeleteAvatarResponse {
    data: [];
    message: string;
}

export type TAuthConfigModalProps = TBasicModalProps & {
    title: string;
    bthText: string;
    isMfa: boolean;
};

export type TToastStyle = {
    variant?: 'error' | 'success' | 'info' | 'warning';
};

export type TToast = TToastStyle & {
    title: string;
    description: string;
    open: boolean;
    onClose?: () => void;
};

export type TTabItem = {
    title: string;
    id: string;
};

export interface ITimezone {
    id: string;
    name: string;
    offset: string;
    timezone: string;
}

export interface IAccount {
    name: string;
    timezone_id: string;
    uuid: string;
    website: string;
    address: {
        city: string;
        country_id: string;
        firstline: string;
        secondline: string;
        state: string;
        zipcode: string;
    };
}

export type TAccountTab = {
    id: string;
    title: string;
    accessKey: keyof IUserPermissions;
};

export interface ILoginForm {
    email: string;
    password: string;
}

export interface IVerifyMfaForm {
    code: string;
}

export type TErrorMessage = {
    [key: string]: {
        details: string[];
    };
};

export type TParseUrl = {
    product_tour_id: string;
    [key: string]: unknown;
};

export type TEmail = {
    address: string;
};

export type TPhone = {
    country_iso2: string;
    is_sms?: boolean;
    national_number: string;
    number: string;
};

export type TAddress = {
    city: string;
    country_id: number;
    firstline: string;
    secondline: string;
    state: string;
    zipcode: string;
};

export type TContactFormValues = {
    firstname: string;
    lastname: string;
    company: ICompany;
    emails: TEmail[];
    phones: TPhone[];
    addresses: TAddress[];
};

export type TContactFormPayload = {
    uuid?: string;
    firstname: string;
    lastname: string;
    company_uuid?: string;
    emails: TEmail[];
    phones?: TPhone[];
    addresses?: TAddress[];
};

export type TContactResponse = TContactFormValues & {
    uuid: string;
    updated_at: string;
    created_at: string;
    permissions: TPermission;
};

export interface ICompany {
    addresses: TAddress[];
    company_user: {
        person: {
            fullname: string;
        };
    };
    created_at: string;
    emails: TEmail[];
    invite_count: number;
    name: string;
    people_count: number;
    permissions: TPermission;
    phones: TPhone[];
    updated_at: string;
    uuid: string;
    website: string;
}

export interface IValidateOptionsExtended {
    options: {
        index: number;
    };
}

export type TCompanyFormValues = {
    name: string;
    website: string;
    emails: TEmail[];
    phones: TPhone[];
    addresses: TAddress[];
};

export type TCompanyFormPayload = TCompanyFormValues & {
    uuid?: string;
};
