import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { AlertBox, Button } from '../../components';
import { Title, Subtitle, Wrapper, Footer, Header } from './VerifyMfa.styled';
import { verificationCodeSchema } from '../../assets/utils/sign_up_schema';
import { verificationCode } from '../../assets/data/sign_up_journey';
import { IVerifyMfaForm } from '../../type';
import { useLoginMfaMutation } from '../../hooks/onboarding';
import { combineErrorMessage } from '../../assets/utils/utils';

const VerifyMfa: React.FC = () => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<IVerifyMfaForm>({
        defaultValues: {
            code: ''
        },
        resolver: yupResolver(verificationCodeSchema)
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState<string[]>([]);

    const { mutate } = useLoginMfaMutation();

    const onSubmit = (form) => {
        setIsLoading(true);
        mutate(form, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const { status, data } = error.response;
                if (status === 401) {
                    setIsShow(true);
                    setMessage([data.message]);
                } else if (status === 422) {
                    const message = combineErrorMessage(data);
                    setMessage([message]);
                    setIsShow(true);
                } else if (status === 408) {
                    window.location.href = data.redirect;
                } else {
                    setIsShow(true);
                    setMessage(['Verify Mfa went wrong']);
                }
                setIsLoading(false);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess: (response: any) => {
                const { data } = response;
                window.location.href = data.redirect;
            }
        });
    };

    const GenerateForm = (item) => {
        return (
            <Controller
                name={item.name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={field.name}
                        size="small"
                        fullWidth
                        required
                        label={item.label}
                        type={item.type}
                        error={Boolean(errors[field.name])}
                        helperText={errors[field.name]?.message}
                    />
                )}
            />
        );
    };

    return (
        <>
            <LoginLayout>
                <Header>
                    <Title>Login</Title>
                    <Subtitle>Enter your two-factor authentication code</Subtitle>
                </Header>
                {isShow && <AlertBox data-cy="mfa-alert-box" mode="Error" messages={message}></AlertBox>}
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        {GenerateForm(verificationCode)}
                        <Button data-cy="verify" mode={'Primary'} loading={isLoading}>
                            Verify
                        </Button>
                    </Wrapper>
                </form>
                <Footer>
                    <a href="/login">Back</a>
                </Footer>
            </LoginLayout>
        </>
    );
};
export default VerifyMfa;
