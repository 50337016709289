import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

type TPasswordRuleHintStyleProps = {
    // The logic here is that arrow alway point to top if set prop to true = fixed, default value is true here
    // so it will be applied to signup page
    // if set false means larger screen of change password modal points to left,
    // the table size of screen and less will point to top
    isArrowFixed?: boolean;
};

export const Card = styled.div<TPasswordRuleHintStyleProps>`
    position: relative;
    background-color: ${theme.light_gray_5};
    border: 1px solid ${theme.light_gray_3};
    padding: 20px 25px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-direction: column;

    ::before,
    ::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 1px solid transparent;
        bottom: 100%;
        left: 50%;
        ${({ isArrowFixed }) =>
            !isArrowFixed &&
            `
                right: 100%;
                top: 50%;
                bottom: unset;
                left: unset;
                @media ${theme.device.tablet}{
                  bottom: 100%;
                  left: 50%;
                  right: unset;
                  top:unset;
                }
            `}
    }

    ::before {
        border-width: 14px;
        border-bottom-color: ${theme.light_gray_3};
        margin-left: -14px;
        ${({ isArrowFixed }) =>
            !isArrowFixed &&
            `
                border-right-color:  ${theme.light_gray_3};
                margin-top: -14px;
                margin-left: 0;
                border-bottom-color: transparent;
                @media ${theme.device.tablet}{
                  border-bottom-color: ${theme.light_gray_3};
                  border-right-color: transparent;
                  margin-left: -14px;
                  margin-top: 0;
                }
            `}
    }

    ::after {
        border-width: 13px;
        border-bottom-color: ${theme.light_gray_5};
        margin-left: -13px;
        ${({ isArrowFixed }) =>
            !isArrowFixed &&
            `
                border-right-color: ${theme.light_gray_5};
                margin-top: -13px;
                margin-left: 0;
                border-bottom-color: transparent;
                @media ${theme.device.tablet}{
                  border-bottom-color: ${theme.light_gray_5};
                  border-right-color: transparent;
                  margin-left: -13px;
                  margin-top: 0;
                }
            `}
    }

    h5 {
        font-size: ${theme.font_size_13};
        font-weight: ${theme.font_weight_medium};
        color: ${theme.dark_gray_2};
        margin-bottom: 10px;
        margin-top: 0;
    }
`;
