import { TFormField } from "../../type";

const currentPassword: TFormField = {
  name: 'current_password',
  label: 'Current Password',
  type: 'password',
  requiredError: 'Current Password is required',
  lengthError: 'The length may not be greater than 99'
};

const newPassword: TFormField = {
  name: 'password',
  label: 'New Password',
  type: 'password',
  combinationPattern: /^.*(?=.{7,99})((?=.*[!@#$%^&*']){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  requiredError: 'New Password is required',
  patternError: 'New Password need to match the following pattern',
  lengthError: 'The length may not be greater than 99'
};

const confirmPassword: TFormField = {
  name: 'password_confirmation',
  label: 'Confirm Password',
  type: 'password',
  requiredError: 'Confirm Password is required',
  patternError: 'Your new password and confirmation password do not match',
  lengthError: 'The length may not be greater than 99'
};

const authCode: TFormField = {
  name: 'code',
  label: 'Authentication Code',
  type: 'text',
  requiredError: 'Authentication Code is required',
  patternError: 'Authentication code is invalid.',
  lengthError: 'The length must be exactly 6 characters'
};

export {
  currentPassword, newPassword, confirmPassword, authCode
};