import styled from 'styled-components';
import { PaperProps } from '@mui/material';
import * as theme from '../../../../assets/styles/variables';

export const Title = styled.div`
    & > svg {
        margin-right: 17px;
    }
`;

export const paperProps: Partial<PaperProps> = {
    style: {
        width: 610,
        maxWidth: 610
    }
};

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media ${theme.device.tablet} {
        flex-direction: column;
        row-gap: 25px;
    }
`;

export const MainForm = styled.div`
    width: 258px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    @media ${theme.device.tablet} {
      width: 100%;
    }
`;

export const PasswordHintContainer = styled.div`
    width: 253px;
    @media ${theme.device.tablet} {
      width: 100%;
    }
`;