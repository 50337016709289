import styled from 'styled-components';
import { buttonPlatte } from '../../assets/styles/palette';
import * as theme from '../../assets/styles/variables';

interface IBadge {
    mode: string;
    type: string;
}

export const Container = styled.span<IBadge>`
    padding: 3px 10px;
    background-color: ${(props) => (props.type === 'contained' ? buttonPlatte[props.mode].bgColor : buttonPlatte[props.mode].textColor)};
    color: ${(props) => (props.type === 'contained' ? buttonPlatte[props.mode].textColor : buttonPlatte[props.mode].bgColor)};
    border: 1px solid ${(props) => buttonPlatte[props.mode].borderColor};
    text-transform: uppercase;
    font-family: ${theme.font_family_poppins};
    font-size: ${theme.font_size_11};
    font-weight: ${theme.font_weight_medium};
    border-radius: 3px;
`;
