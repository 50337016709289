import { TFormField } from '../../type';

const firstName: TFormField = {
    type: 'input',
    id: 'firstname',
    name: 'firstname',
    label: 'First Name',
    required: true,
    requiredError: 'First name is required',
    lengthError: 'The length may not be greater than 30'
};

const lastName: TFormField = {
    type: 'input',
    id: 'lastname',
    name: 'lastname',
    label: 'Last Name',
    required: true,
    requiredError: 'Last name is required',
    lengthError: 'The length may not be greater than 30'
};

const email: TFormField = {
    type: 'input',
    id: 'email',
    name: 'email.address',
    label: 'Email',
    required: true,
    requiredError: 'Email is required',
    emailError: 'Email must be a valid email',
    lengthError: 'The length may not be greater than 50'
};

const phone: TFormField = {
    type: 'component',
    id: 'phone',
    name: 'phone',
    label: 'Mobile Number',
    required: false,
    requiredError: 'Mobile number is required',
    pattern: /^\+[1-9]\d{9,14}$/,
    patternError: 'Invalid mobile number format'
};

const addressLine1: TFormField = {
    type: 'input',
    id: 'firstline',
    name: 'address.firstline',
    label: 'Address Line 1',
    required: false,
    lengthError: 'The length may not be greater than 50'
};

const addressLine2: TFormField = {
    type: 'input',
    id: 'secondline',
    name: 'address.secondline',
    label: 'Address Line 2',
    required: false,
    lengthError: 'The length may not be greater than 50'
};

const city: TFormField = {
    type: 'input',
    id: 'city',
    name: 'address.city',
    label: 'City',
    required: false,
    lengthError: 'The length may not be greater than 50'
};

const state: TFormField = {
    type: 'input',
    id: 'state',
    name: 'address.state',
    label: 'State',
    required: false,
    lengthError: 'The length may not be greater than 50'
};

const country: TFormField = {
    type: 'select',
    id: 'country',
    name: 'address.country_id',
    label: 'Country',
    required: false,
    options: [
        { id: 1, name: "Afghanistan", iso: "AF" }
    ]
};

const zipcode: TFormField = {
    type: 'input',
    id: 'zipcode',
    name: 'address.zipcode',
    label: 'Zip Code',
    required: false,
    lengthError: 'The length may not be greater than 10',
    pattern: /(^[A-Za-z0-9]+$)/,
    patternError: 'Please use alphanumeric characters only.'
};

const password: TFormField = {
    name: 'password',
    label: 'Current Password',
    type: 'password',
    requiredError: 'Current Password is required',
    lengthError: 'The length may not be greater than 99'
};

const verificationCode: TFormField = {
    name: 'sms_verification_code',
    label: 'Verification Code',
    type: 'text',
    requiredError: 'Verification Code is required',
    lengthError: 'The length may not be greater than 4'
};

export {
    firstName, lastName, email, phone, zipcode,
    country, state, city, addressLine2, addressLine1,
    password, verificationCode
};