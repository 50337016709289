import * as theme from './variables';

export const buttonPlatte = {
    Primary: {
        bgColor: theme.primary,
        textColor: theme.white,
        borderColor: theme.primary,
        hoverBgColor: theme.dark_primary,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    },
    Secondary: {
        bgColor: theme.secondary,
        textColor: theme.white,
        borderColor: theme.secondary,
        hoverBgColor: theme.dark_secondary,
        disabledBgColor: theme.dark_primary,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    },
    Tertiary: {
        bgColor: theme.tertiary,
        textColor: theme.med_gray_1,
        borderColor: theme.tertiary,
        hoverBgColor: theme.dark_tertiary,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    },
    Warning: {
        bgColor: theme.warning,
        textColor: theme.white,
        borderColor: theme.warning,
        hoverBgColor: theme.dark_warning,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    },
    Green: {
        bgColor: theme.green,
        textColor: theme.white,
        borderColor: theme.green,
        hoverBgColor: theme.dark_green,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    },
    Toutlined: {
        bgColor: theme.white,
        textColor: theme.med_gray_1,
        borderColor: theme.med_gray_1,
        hoverBgColor: theme.tertiary_outlined,
        fontSize: theme.font_size_14,
        fontWeight: theme.font_weight_medium
    }
};

export const infoCardPlatte = {
    Blue: {
        bgColor: `rgba(${theme.blue_rgb}, ${theme.opacity_1})`,
        textColor: theme.secondary
    },
    Green: {
        bgColor: `rgba(${theme.green_rgb}, ${theme.opacity_1})`,
        textColor: theme.green
    },
    Orange: {
        bgColor: `rgba(${theme.orange_rgb}, ${theme.opacity_1})`,
        textColor: theme.orange
    },
    Red: {
        bgColor: `rgba(${theme.red_rgb}, ${theme.opacity_1})`,
        textColor: theme.red
    }
};

export const alertBoxPlatte = {
    Error: {
        bgColor: theme.light_red_2,
        textColor: theme.red
    },
    Info: {
        bgColor: theme.light_blue_2,
        textColor: theme.blue
    }
};

export const toastPlatte = {
    error: {
        bgColor: theme.red
    },
    success: {
        bgColor: theme.green
    },
    info: {
        bgColor: theme.blue
    },
    warning: {
        bgColor: theme.orange
    }
};
