import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const PaginationBtnGroup = styled.div`
    display: flex;
    column-gap: 10px;
`;

export const PaginationBtn = styled.button`
    height: 32px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid ${theme.med_gray_1};
    border-radius: 5px;
    cursor: pointer;
    transition: 250ms ease-in;
    transition-property: background-color, border-color;

    svg {
        margin: 0 7px;
    }

    &:hover {
        background-color: ${theme.primary};
        border-color: ${theme.primary};
    }
`;
