import * as Yup from 'yup';
import { name, website, email, phone, addressLine1, addressLine2, addressCity, addressState, addressZipCode } from '../data/company_form';

export const companySchema = Yup.object().shape({
    name: Yup.string().required(name.requiredError).max(50, name.lengthError),
    website: Yup.string().matches(website.pattern, website.patternError).max(50, website.lengthError),
    emails: Yup.array().of(
        Yup.object().shape({
            address: Yup.string().max(50, email(0).lengthError).email(email(0).emailError)
        })
    ),
    phones: Yup.array().of(
        Yup.object().shape({
            number: Yup.string().test({
                name: 'mobile-validation',
                message: phone(0).patternError,
                test: function (this, value) {
                    const val = `+${this.parent.national_number}${value}`;

                    if (!value) return true; // not required

                    return !!val.match(phone(0).pattern) && val.length >= 10;
                }
            })
        })
    ),
    addresses: Yup.array().of(
        Yup.object().shape({
            city: Yup.string().max(50, addressCity(0).lengthError),
            firstline: Yup.string().max(50, addressLine1(0).lengthError),
            secondline: Yup.string().max(50, addressLine2(0).lengthError),
            state: Yup.string().max(50, addressState(0).lengthError),
            zipcode: Yup.string().matches(addressZipCode(0).pattern, addressZipCode(0).patternError).max(10, addressZipCode(0).lengthError)
        })
    )
});
