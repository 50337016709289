import styled from 'styled-components';
import * as theme from '../../assets/styles/variables';

export const Container = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    font-size: ${theme.font_size_13};
    color: ${theme.med_gray_1};

    .MuiAvatar-circular {
        font-size: ${theme.font_size_13};
        border: 1px solid ${theme.med_gray_1};
        background-color: transparent;
        color: ${theme.med_gray_1};
        text-transform: uppercase;

        @media ${theme.device.mobile} {
            display: none;
        }
    }

    @media ${theme.device.tabletL} {
        column-gap: 10px;
    }
`;
export const InfoWrapper = styled.span`
    display: flex;
    flex-direction: column;
`;

export const Name = styled.span`
    display: flex;
    flex-direction: row;
    font-weight: ${theme.font_weight_medium};
    text-transform: capitalize;
`;

export const Email = styled.span`
    display: none;

    @media ${theme.device.tabletL} {
        display: flex;
        flex-direction: row;
        font-weight: ${theme.font_weight_normal};
    }
`;
