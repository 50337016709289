import styled from 'styled-components';
import * as theme from '../../../assets/styles/variables';
import Img500 from '../../../assets/images/img-500.svg';
import Img500Mobile from '../../../assets/images/img-500-mobile.svg';

type TErrorIDStyleProps = {
    isShowErrorId: boolean;
};

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin: 0 auto;
    padding-top: 100px;
    width: 476px;
    text-align: center;
    font-size: ${theme.font_size_15};
    font-weight: ${theme.font_weight_regular};
    color: ${theme.med_gray_1};
    & > span {
        color: ${theme.secondary};
        text-decoration: underline;
        cursor: pointer;
    }
    @media ${theme.device.tablet} {
        width: 464px;
        font-size: ${theme.font_size_14};
    }
    @media ${theme.device.mobile} {
        width: 272px;
    }
`;

export const Title = styled.div`
    font-size: ${theme.font_size_26};
    font-weight: ${theme.font_weight_light};
    @media ${theme.device.tablet} {
        font-size: ${theme.font_size_24};
    }
`;

export const Desc = styled.div`
    color: ${theme.dark_gray_2};
`;

export const ErrorID = styled.div<TErrorIDStyleProps>`
    font-size: ${theme.font_size_13};
    display: ${(props) => (props.isShowErrorId ? 'block' : 'none')};
`;

export const Img = styled.div`
    width: 100%;
    height: 305px;
    margin: 0 auto;
    background-image: url(${Img500});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @media ${theme.device.tablet} {
        width: 343px;
        height: 357px;
        background-image: url(${Img500Mobile});
    }
    @media ${theme.device.mobile} {
        width: 253px;
        height: 264px;
    }
`;