import { Dialog } from '@mui/material';
import styled from 'styled-components';

import * as theme from '../../assets/styles/variables';

export const Container = styled.div`
    width: 560px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const Title = styled.div`
    font-size: ${theme.font_size_16};
    color: ${theme.med_gray_1};
    font-weight: ${theme.font_weight_regular};
    text-align: center;
    margin-bottom: 20px;
`;

export const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        padding: '30px'
    }
}));
