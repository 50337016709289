/* eslint-disable max-len */
import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import SignupLayout from '../../components/SignupLayout/SignupLayout';
import { AlertBox, Button } from '../../components';
import { Title, Subtitle, Wrapper, Footer, BtnWrapper, AlertWrapper } from './ResetPassword.styled';
import { resetSchema } from '../../assets/utils/sign_up_schema';
import { email } from '../../assets/data/sign_up_journey';

type TResetPasswordForm = {
    email: string;
};

const ResetPassword: React.FC = () => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<TResetPasswordForm>({
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(resetSchema)
    });

    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        reset();
        //TODO go back to login page
    };

    const onSubmit = (data) => {
        alert(JSON.stringify(data));
        // TODO send request sucessfully and then show the alert box
        setTimeout(() => {
            setOpen(true);
        }, 1000);
    };

    const GenerateForm = (item) => {
        return (
            <Controller
                name={item.name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={field.name}
                        size="small"
                        fullWidth
                        required
                        label={item.label}
                        type={item.type}
                        error={Boolean(errors[field.name])}
                        helperText={errors[field.name]?.message}
                    />
                )}
            />
        );
    };

    return (
        <>
            <SignupLayout>
                <Title>Reset your password</Title>
                <Subtitle>Enter the email you signed up with</Subtitle>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        {GenerateForm(email)}
                        <Footer>
                            <BtnWrapper>
                                <Button type="button" mode={'Tertiary'} onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </BtnWrapper>
                            <BtnWrapper>
                                <Button mode={'Primary'}>Reset</Button>
                            </BtnWrapper>
                        </Footer>
                    </Wrapper>
                </form>
                <AlertWrapper open={open}>
                    <AlertBox
                        mode="Info"
                        messages={[
                            'If a user with that email address exists within our system, an email containing a reset link has been sent to them.'
                        ]}></AlertBox>
                    {/* TODO add login link */}
                    <a href="">Back to Login</a>
                </AlertWrapper>
            </SignupLayout>
        </>
    );
};
export default ResetPassword;
