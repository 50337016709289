import React from 'react';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconBox } from './PasswordRule.styled';

type TPasswordRuleProps = {
  condition: boolean;
  text?: string;
  [x: string]: unknown;
};


const PasswordRule: FC<TPasswordRuleProps> = ({ condition, text, ...rest }) => {
    return (
        <IconBox condition={condition} {...rest}>
            {condition ? <FontAwesomeIcon icon={solid('check-circle')} /> : <FontAwesomeIcon icon={regular('times')} />}
            <span>{text}</span>
        </IconBox>
    );
};

export default PasswordRule;
