import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import styledTheme from './assets/styles/theme';
import GlobalCSS from './assets/styles/global.css';
import Routers from './Routers';
import customHistory from './services/history';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000 * 3
        }
    }
});

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <ThemeProvider theme={styledTheme}>
                    <GlobalCSS />
                    <HistoryRouter history={customHistory}>
                        <Routers />
                    </HistoryRouter>
                </ThemeProvider>
            </Provider>
        </QueryClientProvider>
    );
};

export default App;
