import {
    Accordion as MUIAccordion,
    AccordionDetails as MUIAccordionDetails,
    AccordionSummary as MUIAccordionSummary,
    FormControlLabel
} from '@mui/material';
import styled from 'styled-components';
import * as theme from '../../../assets/styles/variables';

export const RemoveButton = styled.button`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    border: none;
    background: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${theme.light_gray_2};
    cursor: pointer;
    transition: transform 250ms linear;
    transform-origin: center;

    &:hover {
        transform: translateY(-50%) translateX(50%) scale(1.2);

        svg {
            color: ${theme.light_red_1};
        }
    }

    svg {
        color: ${theme.med_gray_1};
    }
`;

export const Container = styled.div`
    max-width: 549px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 0;

    @media ${theme.device.tabletL} {
        width: 80%;
    }

    @media ${theme.device.mobile} {
        width: 90%;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 47px;
`;

export const BackButton = styled.button`
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
`;

export const Title = styled.h1`
    margin: 0;
    font-family: ${theme.font_family_montserrat};
    font-size: ${theme.font_size_24};
    font-weight: ${theme.font_weight_medium};
    color: ${theme.med_gray_1};
    text-transform: capitalize;
`;

export const FormGroup = styled.div`
    display: grid;
    row-gap: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${theme.light_gray_3};
`;

export const Name = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;

    @media ${theme.device.mobile} {
        grid-template-columns: unset;
        grid-row-gap: 30px;
    }
`;

export const AddNewField = styled.button`
    font-size: ${theme.font_size_12};
    font-family: ${theme.font_family_poppins};
    color: ${theme.blue};
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
`;

export const EmailGroup = styled.div`
    position: relative;

    ${RemoveButton} {
        top: 17.5px;
    }
`;

export const Phones = styled(FormGroup)`
    padding-top: 30px;
    margin-bottom: 0;
`;

export const PhoneGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 30px;

    ${RemoveButton} {
        top: 17.5px;
    }
`;

export const Phone = styled.div`
    position: relative;
`;

export const SMSSwitch = styled(FormControlLabel)`
    position: relative;
    margin: 0;
    align-self: start;
    top: 17.5px;
    transform: translateY(-50%);

    .MuiFormControlLabel-label {
        font-size: 12px;
        margin-right: 10px;
    }
`;

export const AddressGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 115px;
    grid-column-gap: 30px;
`;

export const AddressHeading = styled.div`
    margin-left: 10px;
    font-family: ${theme.font_family_poppins};
    font-size: ${theme.font_size_13};
    line-height: 20px;
`;

export const Accordion = styled(MUIAccordion)`
    background: transparent;
    border: none;
    padding: 0;
    box-shadow: unset;
    border-bottom: 1px solid ${theme.light_gray_3};

    &::before {
        display: none;
    }
`;

export const AccordionSummary = styled(MUIAccordionSummary)`
    padding: 0;
    flex-direction: row-reverse;

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(90deg);
    }

    ${RemoveButton} {
        transform: translateX(0) translateY(-50%);

        &:hover {
            transform: translateX(0) translateY(-50%) scale(1.2);
        }
    }
`;

export const AccordionDetails = styled(MUIAccordionDetails)`
    display: grid;
    row-gap: 25px;
    padding: 0;
    padding-bottom: 30px;
`;

export const AddNewPhone = styled(AddNewField)`
    margin-top: 30px;
`;

export const FormFooter = styled.div`
    margin-top: 25px;
`;
